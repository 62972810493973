import request from "@/axios/index";

// 已发起
export function peddingInitiated(params) {
  return request({
    url: '/activiti/my_tasks',
    method: "get",
    params
  });
}

// 附件列表
export function associationList(params) {
  return request({
    url: '/common/association/page',
    method: "get",
    params
  });
}

// 附件列表下载
export function downloadassociationList(params) {
  return request({
    url: '/common/download/' + params,
    method: "get",
    responseType: 'blob'
  });
}

// 附件列表删除
export function deleteassociationList(params) {
  return request({
    url: '/common/association/' + params,
    method: "delete",
  });
}

// 已审批列表
export function successApproval(params) {
  return request({
    url: '/activiti/finish_tasks',
    method: "get",
    params
  });
}


// 抄送记录
export function successApprovalCopy(params) {
  return request({
    url: '/activiti/copy_to_me',
    method: "get",
    params
  });
}

// 提交申请
export function submitApproval(data) {
  return request({
    url: '/activiti/submit',
    method: "post",
    data
  })
}

// 审批历史列表
export function approvalHistory(params) {
  return request({
    url: '/activiti/list_history',
    method: "get",
    params
  })
}

// 未选择审批节点的审批驳回
export function approvalReject(data) {
  return request({
    url: '/activiti/reject',
    method: "post",
    data
  })
}

// 审批同意
export function approvalAgree(data) {
  return request({
    url: '/activiti/agree',
    method: "post",
    data
  })
}

//查询撤回按钮权限
export function hasRevocation(params) {
  return request({
    url: '/activiti/hasRevocation',
    method: "get",
    params
  })
}

// 进度查看流程图
export function definitionRead(params) {
  return request({
    url: `/definition/read_resource_id`,
    method: "get",
    params,
    responseType: 'blob'
  })
}

// 撤销
export function defineReal(processInstanceId) {
  return request({
    url: `/activiti/revocation?processInstanceId=${processInstanceId}`,
    method: "post",
  })
}

// 待审批
export function awaitApproval(params) {
  let count = Math.ceil(Math.random()*10)
  return request({
    url: '/activiti/todo_tasks?' + count,
    method: "get",
    params
  });
}

// 待审批节点
export function selectNode(params) {
  return request({
    url: '/activiti/select_node',
    method: "get",
    params
  });
}

// 审批保存
export function approvalSave(data) {
  return request({
    url: '/activiti/save_node',
    method: "post",
    data
  })
}

export function peddingApproval(params) {
  return request({
    url: '/activiti/todo_tasks',
    method: "get",
    params
  });
}

// 判断最终审批人是否是当前审批人
export function findLastAssignee(id) {
  return request({
    url: '/activiti/findLastAssignee?processInstanceId=' + id,
    method: "get",
  });
}

// 退回指定审批人
export function selectBackNode(params) {
  return request({
    url: '/activiti/select_back_node',
    method: "get",
    params
  });
}

// 导出
export function exportData(type, data) {
  return request({
    url: `/activiti/activityExport?type=${type}`,
    method: "post",
    responseType: 'blob',
    data,
  })
  // type
  // 1 == 待审批
  // 2 == 已审批
  // 3 == 已发起
  // 4 == 抄送记录
}
// 查询待审批及已发起-驳回数据
export function newApproval() {
  return request({
    url: '/activiti/tasks_info',
    method: "get"
  });
}
