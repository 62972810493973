<template>
  <div class="html" v-html="htmls"></div>
</template>

<script>
export default {
  name: "EmailContent",
  props: {
    content: {
      type: String,
      default: ""
    }
  },
  data() {
    return { 
      htmls: ""
    }
  },
  mounted() {},
  watch: {
    content: {
      handler(str) {
        this.htmls = str;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// 解决发件箱表格不显示问题
.html >>> table {
  border-collapse: collapse;
}
.html >>> th {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
.html >>> td {
  border: 1px solid #ccc;
  min-width: 50px;
  height: 20px;
}
</style>