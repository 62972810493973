
import en from '../../lang/en'
import zh from '../../lang/zh'
import th from '../../lang/th'
import id from '../../lang/id'
import ru from '../../lang/ru'
import vie from '../../lang/vie'
import { getSession } from "@/utils/methods"
let language = null
if(getSession('LanguageName') == 'en') {
	language = en
}else if(getSession('LanguageName') == 'th') {
	language = th
}else if(getSession('LanguageName') == 'id') {
	language = id
}else if(getSession('LanguageName') == 'ru') {
	language = ru
}else if(getSession('LanguageName') == 'vie') {
	language = vie
}else if(getSession('LanguageName') == 'zh-cn') {
	language = zh
}else {
	let lang = navigator.language; //浏览器语言判断
  lang = lang.substr(0, 2);
	switch (lang) {
		case 'zh': //中文
		language = zh;
				break;
		case 'en': //英文
		language = en;
				break;
    case 'th': 
		language = th;
				break;
  case 'id': 
  language = id;
      break;
  case 'ru': 
  language = ru;
      break;
    case 'vie': 
    language = vie;
        break;
		default:
			language = en;
			break;
	}
}
export default {
  tags: [
    {
      fullPath: "/home",
      hash: "",
      meta: { title: language.Menu.home , icon: null },
      name: "home",
      params: {},
      path: "/home",
      query: {},
      title:language.Menu.home ,
    },
  ],
  routes: [],
  userInfo: {},
  tagCloseRouteList:[], //标签关闭的路由(列表页)
  tagCloseDetailRouteList:[], //标签关闭的路由(详情页)
  moduleMainFormData:null, //模块之间共享主模块表单数据、用于子表调取界面实时获取当前所处主表单上的最新值作为参数传递
  isClickTag: 1  // 1代表是点击Y-tag有需要就缓存，0是代表点击得table得操作列不缓存
};
