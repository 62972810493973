<template>
  <div class="vuequilleditorone">
    <editor-bar :editorData="editorDataList" @input="inputchange"></editor-bar>
  </div>
</template>

<script>
import EditorBar from './div'
export default {
  name: "Y-vuequilleditorone",
  components: { EditorBar },
  data() {
    return {
      isClear: false,
      editorDataList: ""
    };
  },
  props: {
    editorData: {
      type: String,
      default: ''
    },
  },
  created() {
    this.editorDataList = this.editorData
  },
  mounted() {},
  methods: {
    inputchange(val) {
      console.log(val)
    },
  },
  
};
</script>

<style lang="scss" scoped>
.vuequilleditor {
  height: 100%;
}
</style>