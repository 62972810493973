var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          attrs: {
            value: _vm.value,
            placeholder:
              _vm.$t("placeHolder.pleaseSelect") + " " + _vm.placeholder,
            size: "mini"
          },
          on: {
            change: function(val) {
              return _vm.$emit("update:value", val)
            }
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value ? item.value : item,
            attrs: {
              label: item.label ? item.label : item,
              value: item.value ? item.value : item
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }