import {
  addResizeListener,
  removeResizeListener,
} from "element-ui/src/utils/resize-event";
const doResize = (el, binding, vnode) => {
  const { componentInstance: $table } = vnode;
  const { value } = binding;
  //   if (!$table.height) {
  //     throw new Error(`el-$table must set the height. Such as height='100px'`)
  //   }
  const bottomOffset = (value && value.bottomOffset) || 30;
  if (!$table) return;
  const height = window.innerHeight - el.getBoundingClientRect().top - bottomOffset;
  $table.layout.setHeight(height);
  $table.doLayout();
};
export default {
  bind(el, binding, vnode) {
    el.resizeListener = () => {
      doResize(el, binding, vnode);
    };
    addResizeListener(window.document.body, el.resizeListener);
  },
  inserted(el, binding, vnode) {
    doResize(el, binding, vnode);
  },
  unbind(el) {
    removeResizeListener(window.document.body, el.resizeListener);
  },
};
