<template>
  <div>
    <el-dialog :title="$t('Marketing.templateSelection')" :visible.sync="templateDialog" width="50%">
      <el-form
        :inline="true"
        :model="searchInfo"
        @submit.native.prevent="triggerClick"
        @before-close="handleClose"
      >
        <div style="display: flex; align-items: center">
          <el-input
            :placeholder="$t('Emails.templateSearch')"
            v-model="searchInfo.title"
            size="small"
            style="width: 190px;margin-right: 15px;"
          >
          </el-input>
          <el-button size="small" type="primary" @click="handleSearch">{{
            $t("listOfSet.search")
          }}</el-button>
        </div>
      </el-form>
      <el-table :data="templateList" border style="margin: 15px 0 0 0">
        <el-table-column align="center" width="55" :label="$t('Marketing.select')">
          <template slot-scope="scope">
            <el-radio v-model="radio" :label="scope.row.id">{{ '' }}</el-radio>
          </template>
        </el-table-column>
        <el-table-column :label="$t('Marketing.templateName')" prop="title"></el-table-column>
        <el-table-column :label="$t('Marketing.remark')" prop="remark"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer" style="margin: 15px 0 0 0;">
        <el-button @click="handleClose" size="mini">{{ $t('Marketing.cancel') }}</el-button>
        <el-button type="primary" size="mini" @click="handleOk">{{ $t('Marketing.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { emailTemplatePage } from "@/api/sysTem/emailMarketing";

export default {
  name: "EmailTemplate",
  data() {
    return {
      templateDialog: false,
      templateList: [],
      radio: null,
      searchInfo: {},
      pageSize: 10,
      pageNum: 1,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    triggerClick() {},
    handleSearch() {
      this.getList();
    },
    /* 获取列表 */
    getList() {
      const data = {
        title: this.searchInfo.title,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      emailTemplatePage(data).then((res) => {
        this.pageNum = res.pageNum;
        this.pageSize = res.pageSize;
        this.templateList = res.result;
      });
    },
    handleClose() {
      this.templateDialog = false;
    },
    handleOk() {
      const info = this.templateList.find((item) => item.id === this.radio);
      this.$emit("getTemplate", info);
      this.templateDialog = false;
    },
  },
};
</script>
