import request from "@/axios/index";

export function emailVariableList(params) {
	return request({
		url: '/email-variable/list',
		method: 'get',
		params
	})
}

export function emailVariableSave(data) {
    return request({
        url: '/email-variable',
        method: 'post',
        data
    })
}

export function emailVariableDel(data) {
    return request({
        url: '/email-variable',
        method: "delete",
        data
    })
}


export function emailVariablePage(params) {
    return request({
        url: '/email-variable/page',
        method: 'get',
        params
    })
}

export function emailTemplatePage(params) {
    return request({
		url: '/email-template/page',
		method: 'get',
		params
	})
}

export function emailTemplateRoll(params) {
    return request({
        url: '/email-template/roll',
        method: 'get',
        params
    })
}

export function emailTemplateSave(data) {
    return request({
        url: '/email-template',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data'},
        data
    })
}

export function emailTemplateInfo(id) {
    return request({
        url: '/email-template/' + id,
        method: 'get',
    })
}

export function emailTemplateCommon(id) {
    return request({
        url: '/email-template/' + id,
        method: 'post',
    })
}

export function emailTemplateDel(data) {
    return request({
        url: '/email-template/'+ data,
        method: "delete",
    })
}

export function emailTaskPage(params) {
    return request({
		url: '/email-task/page',
		method: 'get',
		params
	})
}

export function emailTaskSave(data) {
    return request({
        url: '/email-task',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data'},
        data
    })
}

export function emailTaskDel(data) {
    return request({
        url: '/email-task/' + data,
        method: "delete",
    })
}

export function emailTaskMarketing(params) {
    return request({
		url: '/email-task',
		method: 'get',
		params
	})
}


export function emailTaskInfo(params) {
    return request({
        url: '/email-task/'+params,
        method: 'get',
        
    })
}

export function emailTaskReceiving(params) {
    return request({
        url: '/email-task/to-email',
        method: 'get',
        params
    })
}

export function emailMarketingQuick(taskId) {
    return request({
        url: '/email-marketing/quick/' + taskId,
        method: 'post'
    })
}

export function emailMarketingPage(params) {
    return request({
        url: '/email-marketing/page',
        method: 'get',
        params
    })
}

export function emailMarketingRecord(jobId, id) {
    return request({
        url: `email-marketing/page/record/${jobId}/${id}`,
        method: 'get',
    })
}

export function emailMarketingStop(params) {
    return request({
        url: 'email-marketing/stopped/'+ params,
        method: 'get',
    })
}

export function emailMarketingSave(data) {
    return request({
        url: '/email-marketing/save',
        method:'post',
        data
    })
}

export function emailMarketingInfo(params) {
    return request({
        url: '/email-marketing/'+params,
        method: 'get'
    })
}

export function emailMarketingSwitch(params) {
    return request({
        url: '/email-marketing/' + params,
        method: 'put'
    })
}

export function emailMarketingDel(params) {
    return request({
        url: '/email-marketing/' + params,
        method: 'delete'
    })
}

export function emailMarketingStatistics(params) {
    return request({
        url: '/email-statistics',
        method: 'get',
        params
    })
}

/* 托管方案 */
export const emailSchemeSave = data => request({ url: '/email-scheme', method: 'post', data })

export const emailSchemeRemove = id => request({ url: '/email-scheme/' + id, method: 'delete' })

export const emailSchemeList = params => request({ url: '/email-scheme', method: 'get', params })

/* 托管营销 */
export const emailEntrustSave = data => request({ url: '/email-entrust-job', method: 'post', data })

export const emailEntrust = id => request({ url: '/email-entrust-job/' + id, method: 'get' })

export const emailEntrustList = params => request({ url: '/email-entrust-job/page', method: 'get', params })

export const emailEntrustDelete = data => request({ url: '/email-entrust-job', data, method: "delete" })

/* 用户分组 */
export const emailUserGroupSave = data => request({ url: '/email-user-group', method: 'post', data })

export const emailUserGroupRemove = data => request({ url: '/email-user-group', data, method: "delete" })

export const emailUserGroupList = params => request({ url: '/email-user-group', method: 'get', params })