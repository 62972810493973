<template>
  <div>
    <el-tree
      :data="data"
      show-checkbox
      :node-key="path"
      :default-checked-keys="checkKeys"
      :expand-on-click-node="false"
      :render-content="renderContent"
      ref="tree"
    >
      <span
        class="custom-tree-node"
        slot-scope="{ node, data }"
        v-if="type && type === 'roleAdmin'"
      >
        <span>{{ node.label }}</span>
        <div v-if="data.hasChildren && node.label != '邮件系统' &&  node.label != '邮件营销'" style="width: 80%;text-align: right">
          <span>
            查看范围
          </span>
          <el-select
            v-model="data.scope"
            size="mini"
            style="width: 30%;"
            :style="data.parentId !== '0' ? 'margin-right: 15px;' : ''"
            @change="(e) => handleChooseCheck(e, data)"
          >
            <el-option :value="0" label="所有数据"></el-option>
            <el-option :value="1" label="自有数据"></el-option>
            <el-option :value="2" label="部门数据"></el-option>
          </el-select>
          <span v-if="data.parentId !== '0'" style="margin: 0 5px 0 0"
            >拓展范围</span
          >
          <el-input
            v-if="data.parentId !== '0'"
            v-model="data.expend"
            size="mini"
            style="width:30%;"
            :disabled="true"
            placeholder="请选择范围"
          >
            <template slot="append"
              ><div @click="handleSetExpand(data)">...</div></template
            >
          </el-input>
        </div>
      </span>
    </el-tree>
    <el-dialog
      :visible.sync="dialog"
      title="扩展范围设置"
      width="55%"
      :destoryOnClone="false"
    >
      <div>
        <el-button type="primary" size="mini" @click="handleAddExpand"
          >新增</el-button
        >
        <div
          style="margin: 10px 0 0 0"
          v-for="(item, index) in expandList"
          :key="index"
        >
          <el-select
            size="mini"
            placeholder="请选择前缀"
            style="margin: 0 8px 0 0;width: 100px"
            v-model="item.prefix"
          >
            <el-option value="(" label="("></el-option>
            <el-option value="((" label="(("></el-option>
            <el-option value="(((" label="((("></el-option>
          </el-select>
          <el-select
            size="mini"
            placeholder="请选择类型"
            style="margin: 0 8px 0 0;width: 120px"
            v-model="item.type"
            @change="(e) => handleChooseType(e, item)"
          >
            <el-option :value="0" label="人员"></el-option>
            <el-option :value="1" label="部门"></el-option>
            <el-option :value="2" label="单据字段"></el-option>
          </el-select>
          <el-select
            size="mini"
            style="margin: 0 8px 0 0;width: 120px"
            v-model="item.fieldName"
            placeholder="请选择字段"
            v-if="item.type == 2"
          >
            <template v-for="item in fieldList">
              <el-option
                :key="item.id"
                :value="item.fieldName"
                :label="item.showName"
              ></el-option>
            </template>
          </el-select>
          <el-select
            size="mini"
            style="margin: 0 8px 0 0;width: 120px"
            v-model="item.symbol"
          >
            <el-option :value="0" label="等于"></el-option>
            <el-option :value="1" label="包含"></el-option>
            <el-option :value="2" label="比较"></el-option>
            <el-option :value="3" label="大于"></el-option>
            <el-option :value="4" label="大于等于"></el-option>
            <el-option :value="5" label="小于"></el-option>
            <el-option :value="6" label="小于等于"></el-option>
          </el-select>
          <el-select
            v-if="item.type == 0"
            v-model="item.value"
            size="mini"
            multiple
            collapse-tags
            style="margin: 0 8px 0 0;width: 180px"
            placeholder="请选择人员"
          >
            <template v-for="item in userOptions">
              <el-option
                :key="item.id"
                :value="item.username"
                :label="item.nickName"
              ></el-option>
            </template>
          </el-select>
          <el-select
            v-if="item.type == 1"
            v-model="item.value"
            size="mini"
            placeholder="请选择部门"
            style="margin: 0 8px 0 0;width: 150px"
          >
            <template v-for="item in deptOptions">
              <el-option
                :key="item.id"
                :value="item.id"
                :label="item.deptName"
              ></el-option>
            </template>
          </el-select>
          <el-input
            v-if="item.type == 2"
            v-model="item.value"
            placeholder="请填写字段"
            size="mini"
            style="margin: 0 8px 0 0;width: 100px"
          ></el-input>
          <el-select
            size="mini"
            style="margin: 0 8px 0 0;width: 100px"
            v-model="item.suffix"
          >
            <el-option value=")" label=")"></el-option>
            <el-option value="))" label="))"></el-option>
            <el-option value=")))" label=")))"></el-option>
          </el-select>
          <el-select
            size="mini"
            placeholder="请选择条件"
            style="margin: 0 8px 0 0;width: 120px"
            v-model="item.condition"
          >
            <el-option :value="0" label="且"></el-option>
            <el-option :value="1" label="或"></el-option>
          </el-select>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelExpand(item, index)"
            >删除</el-button
          >
        </div>
        <span slot="footer" class="dialog-footer" style="margin: 15px 0 0 0;">
          <el-button @click="handleClose" size="mini">取 消</el-button>
          <el-button
            type="primary"
            :loading="saveLoading"
            size="mini"
            @click="handleOk"
            >确 定</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { saveExtand, getExtandList } from "@/api/sysTem/roleAdmin";
import { selectSysUser } from "@/api/order/node";
import { selectDept } from "@/api/sysTem/deptAdmin";
import { formConfig, fieldsConfig } from "@/api/sysTem/index";
export default {
  name: "Y-tree",
  props: {
    tree: {
      type: Array,
      required: true,
    },
    renderContent: Function,
    path: {
      type: String,
      default: () => {
        return "path";
      },
    },
    checkKeys: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: {
      type: String,
    },
    row: {
      type: [String, Object],
    },
  },
  data() {
    return {
      userOptions: [],
      deptOptions: [],
      data: JSON.parse(JSON.stringify(this.tree)),
      dialog: false,
      expandList: [
        {
          prefix: null,
          type: null,
          symbol: null,
          value: null,
          suffix: null,
          condition: null,
        },
      ],
      chooseRoleData: {},
      fieldList: [],
      saveLoading: false,
    };
  },
  computed: {},
  created() {},
  watch: {
    tree: {
      handler(newVal) {
        this.data = this._.cloneDeep(newVal);
      },
      deep: true,
    },
  },
  methods: {
    // 查询扩展范围数据
    getExtandList() {
      getExtandList(this.row.id, this.chooseRoleData.id).then((res) => {
        if (res && res.length !== 0) {
          this.expandList = res.map((item) => {
            if (item.type == 0) {
              item.value = item.value.split(",");
              return item;
            }
            return item;
          });
          this.selectSysUser();
          this.selectDept();
          this.selectFieldList();
        } else {
          this.expandList = [
            {
              prefix: null,
              type: null,
              symbol: null,
              value: null,
              suffix: null,
              condition: null,
            },
          ];
        }
      });
    },
    //查询用户
    selectSysUser() {
      selectSysUser().then((res) => {
        this.userOptions = res.result;
      });
    },
    // 查询部门
    selectDept() {
      selectDept().then((res) => {
        this.deptOptions = res;
      });
    },
    // 查询字段数据
    selectFieldList() {
      formConfig({
        method: "get",
        reqInfo: { pageSize: 9999, pageNum: 1 },
      }).then((res) => {
        res.result.forEach((item) => {
          if (item.header === this.chooseRoleData.label) {
            fieldsConfig({
              method: "get",
              reqInfo: {
                pageSize: 9999,
                pageNum: 1,
                name: "",
                dataDict: "",
                sheetid: "",
                formId: item.id,
              },
            }).then((res) => {
              this.fieldList = res.result.filter((item) => item.showName);
              console.log(this.fieldList);
            });
          }
        });
      });
    },
    // 新增
    handleAddExpand() {
      this.expandList.push({
        prefix: null,
        type: null,
        symbol: null,
        value: null,
        suffix: null,
        condition: null,
      });
    },
    // 删除
    handleDelExpand(item, index) {
      if (index !== -1) {
        this.expandList.splice(index, 1);
      }
    },
    // 查看范围
    handleChooseCheck(e, data) {
      this.data.forEach((item) => {
        if (item.id === data.id && item.hasChildren) {
          item.children = item.children.map((iChild) => {
            iChild.scope = e;
            return iChild;
          });
          this.$set(data, "children", item.children);
        }
      });
    },
    // 选择类型
    handleChooseType(type, item) {
      item.value = null;
      if (item.fieldName) item.fieldName = null;
      switch (type) {
        case 0:
          this.selectSysUser();
          break;
        case 1:
          this.selectDept();
          break;
        case 2:
          this.selectFieldList();
          break;
        default:
          break;
      }
    },
    // 拓展范围设置
    handleSetExpand(data) {
      this.chooseRoleData = data;
      this.getExtandList();
      this.dialog = true;
    },
    handleClose() {
      this.expandList = [
        {
          prefix: null,
          type: null,
          symbol: null,
          value: null,
          suffix: null,
          condition: null,
        },
      ];
      this.chooseRoleData = {};
      this.dialog = false;
    },
    handleOk() {
      for (let i = 0; i < this.expandList.length; i++) {
        for (let key in this.expandList[i]) {
          let keys = [
            "prefix",
            "type",
            "symbol",
            "value",
            "suffix",
            "condition",
          ];
          if (this.expandList[i].type && this.expandList[i].type === 2) {
            keys.push("fieldName");
          }
          if (keys.includes(key) && this.expandList[i][key] == null) {
            this.$message.error("请完善第" + (i + 1) + "行的信息配置");
            return false;
          }
        }
      }
      let arr = this.expandList.map((item) => {
        let info = JSON.parse(JSON.stringify(item));
        if (info.type == 0) {
          info.value = info.value.join(",");
        }
        info.menuId = this.chooseRoleData.id;
        info.menuName = this.chooseRoleData.label;
        info.roleId = this.row.id;
        return info;
      });
      this.saveLoading = true;
      saveExtand({
        extendList: arr,
        menuId: this.chooseRoleData.id,
        roleId: this.row.id,
      })
        .then((res) => {
          this.saveLoading = false;
          this.$message.success("保存成功");
          this.handleClose();
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-tree-node__content {
  height: 35px;
}
/deep/ .el-input-group__append {
  padding: 0 10px;
}
/deep/ .el-tree-node {
  overflow: auto;
  overflow-y: hidden;
}
</style>
