<!-- @author zhengjie -->
<template>
  <div class="icon-body">
    <el-input
      v-model="name"
      style="position: relative"
      clearable
      placeholder="请输入图标中文名称或英文名称"
      @clear="filterIcons"
      @input="filterIcons"
    >
      <i slot="suffix" class="el-icon-search el-input__icon" />
    </el-input>
    <div class="content font-class">
      <el-tabs v-model="activeName">
        <el-tab-pane label="单色图标" name="first">
          <ul class="icon_lists dib-box">
            <li
              class="dib"
              v-for="(item, index) in iconList"
              :key="index"
              @click="selectedIcon(item.icon)"
            >
              <span :class="'icon iconfont ' + item.icon"></span>
              <div class="name">{{ item.name }}</div>
              <div class="code-name">{{ item.icon }}</div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="多色图标" name="second">
          <ul class="icon_lists dib-box">
            <li
              class="dib"
              v-for="(item, index) in colorIconList"
              :key="index"
              @click="selectedIcon(item.icon)"
            >
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="'#' + item.icon"></use>
              </svg>
              <div class="name">{{ item.name }}</div>
              <div class="code-name">{{ item.icon }}</div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// import icons from "./requireIcons";
export default {
  name: "IconSelect",
  data() {
    return {
      name: "",
      activeName: "first",
      dataSourceIconList: [
        { name: "目标管理", icon: "icon-mubiaoguanli" },
        { name: "站点设置", icon: "icon-zhandianshezhi" },
        { name: "人事设置", icon: "icon-renshishezhi" },
        { name: "CRM设置", icon: "icon-CRMshezhi" },
        { name: "名片设置", icon: "icon-mingpianshezhi" },
        { name: "网校设置", icon: "icon-wangxiaoshezhi" },
        { name: "我的点评", icon: "icon-wodedianping" },
        { name: "我的学生", icon: "icon-wodexuesheng" },
        { name: "联系记录", icon: "icon-lianxijilu" },
        { name: "投诉提问", icon: "icon-tousutiwen" },
        { name: "培训计划", icon: "icon-peixunjihua" },
        { name: "费用查询", icon: "icon-feiyongchaxun" },
        { name: "学生缴费", icon: "icon-xueshengjiaofei" },
        { name: "记账", icon: "icon-jizhang" },
        { name: "查账", icon: "icon-chazhang" },
        { name: "查账", icon: "icon-chazhang1" },
        { name: "报表", icon: "icon-baobiao" },
        { name: "设置", icon: "icon-shezhi" },
        { name: "招生报表", icon: "icon-daqiajilu" },
        { name: "打卡记录", icon: "icon-wangluobanji" },
        { name: "网络班级", icon: "icon-wangxiaoshezhi1" },
        { name: "网校设置", icon: "icon-xiaochengxu" },
        { name: "小程序", icon: "icon-houtaishezhi" },
        { name: "后台设置", icon: "icon-zhaoshengshezhi" },
        { name: "招生设置", icon: "icon-xiaoxizhongxin" },
        { name: "消息中心", icon: "icon-zhaoshengbaobiao" },
        { name: "菜单管理", icon: "icon-caidanguanli" },
        { name: "集团设置", icon: "icon-jituanshezhi" },
        { name: "市场管理", icon: "icon-shichangguanli" },
        { name: "创建系统", icon: "icon-chuangjianxitong" },
        { name: "工单管理", icon: "icon-gongdanguanli" },
        { name: "短信", icon: "icon-duanxin" },
        { name: "帮助中心", icon: "icon-bangzhuzhongxin" },
        { name: "学生异动", icon: "icon-xueshengyidong" },
        { name: "报考", icon: "icon-baokao" },
        { name: "成绩", icon: "icon-chengji" },
        { name: "阅卷", icon: "icon-yuejuan" },
        { name: "考核设置", icon: "icon-kaoheshezhi" },
        { name: "论文管理", icon: "icon-lunwenguanli" },
        { name: "申报", icon: "icon-shenbao" },
        { name: "课程", icon: "icon-kecheng" },
        { name: "题库", icon: "icon-tiku" },
        { name: "教务设置", icon: "icon-jiaowushezhi" },
        { name: "自定义设置", icon: "icon-zidingyishezhi" },
        { name: "超级统计", icon: "icon-chaojitongji" },
        { name: "成考管理", icon: "icon-chengkaoguanli_B" },
        { name: "成考管理", icon: "icon-chengkaoguanli_A" },
        { name: "远程管理", icon: "icon-yuanchengguanli" },
        { name: "书籍管理", icon: "icon-shujiguanli" },
        { name: "过程性考核", icon: "icon-guochengxingkaohe" },
        { name: "学分认定", icon: "icon-xuefenrending" },
        { name: "课程订单", icon: "icon-kechengdingdan" },
        { name: "问题反馈", icon: "icon-wentifankui" },
        { name: "资产", icon: "icon-zichan" },
        { name: "KPI管理", icon: "icon-KPIguanli" },
        { name: "项目管理", icon: "icon-xiangmuguanli" },
        { name: "微信公众号", icon: "icon-weixingongzhonghao" },
        { name: "安全设置", icon: "icon-anquanshezhi" },
        { name: "服务中心", icon: "icon-fuwuzhongxin" },
        { name: "企业服务", icon: "icon-qiyefuwu" },
        { name: "网课服务", icon: "icon-wangkefuwu" },
        { name: "收费管理", icon: "icon-shoufeiguanli" },
        { name: "权限设置", icon: "icon-quanxianshezhi" },
        { name: "招生统计", icon: "icon-zhaoshengtongji" },
        { name: "教务统计", icon: "icon-jiaowutongji" },
        { name: "教学统计", icon: "icon-jiaoxuetongji" },
        { name: "收费统计", icon: "icon-shoufeitongji" },
        { name: "学生统计", icon: "icon-xueshengtongji" },
        { name: "服务统计", icon: "icon-fuwutongji" },
        { name: "通知统计", icon: "icon-tongzhitongji" },
        { name: "考核统计", icon: "icon-kaohetongji" },
        { name: "用户活跃度", icon: "icon-yonghuhuoyuedu" },
        { name: "学生报名", icon: "icon-xueshengbaoming" },
        { name: "学生录取", icon: "icon-xueshengluqu" },
        { name: "学生注册", icon: "icon-xueshengzhuce" },
        { name: "在籍学生", icon: "icon-zaijixuesheng" },
        { name: "学籍清查", icon: "icon-xuejiqingcha" },
        { name: "花名册", icon: "icon-huamingce" },
        { name: "服务分类管理", icon: "icon-fenleiguanli" },
        { name: "学生服务列表", icon: "icon-fuwuliebiao" },
        { name: "通知分类管理", icon: "icon-fenleiliebiao" },
        { name: "通知列表", icon: "icon-tongzhiliebiao" },
        { name: "教学计划", icon: "icon-jiaoxuejihua" },
        { name: "教学安排", icon: "icon-jiaoxueanpai" },
        { name: "成绩管理", icon: "icon-chengjiguanli" },
        { name: "图片管理", icon: "icon-tupianguanli" },
        { name: "考试计划", icon: "icon-kaoshijihua" },
        { name: "考试配置", icon: "icon-kaoshipeizhi" },
        { name: "学习中心管理", icon: "icon-xuexizhongxinguanli" },
        { name: "考核设置", icon: "icon-kaoheshezhi1" },
        { name: "考核管理", icon: "icon-kaoheguanli" },
        { name: "教师管理", icon: "icon-jiaoshiguanli" },
        { name: "网课管理", icon: "icon-wangkeguanli" },
        { name: "补录结算", icon: "icon-bulujiesuan" },
        { name: "毕业管理", icon: "icon-biyeguanli" },
        { name: "考核指标", icon: "icon-kaohezhibiao" },
        { name: "企业管理", icon: "icon-qiyeguanli" },
        { name: "初步管理", icon: "icon-chubuguanli" },
        { name: "呼叫报表", icon: "icon-hujiaobaobiao" },
        { name: "扫描电镜", icon: "icon-saomiaodianjing" },
        { name: "结账", icon: "icon-jiezhang" },
        { name: "销售报表", icon: "icon-xiaoshoubaobiao" },
        { name: "凭证", icon: "icon-pingzheng" },
        { name: "合同异动", icon: "icon-hetongyidong" },
        { name: "审批设置", icon: "icon-shenpishezhi1" },
        { name: "销售品", icon: "icon-xiaoshoupin" },
        { name: "学术管理", icon: "icon-xueshuguanli" },
        { name: "易耗品", icon: "icon-yihaopin" },
        { name: "任务管理", icon: "icon-renwuguanli" },
        { name: "电商", icon: "icon-dianshang" },
        { name: "名片报表", icon: "icon-mingpianbaobiao_1" },
        { name: "固定资产", icon: "icon-gudingzichan" },
        { name: "动态管理", icon: "icon-dongtaiguanli" },
        { name: "基础配置", icon: "icon-jichupeizhi" },
        { name: "呼叫统计", icon: "icon-hujiaotongji" },
        { name: "销售报表", icon: "icon-xiaoshoubaobiao_1" },
        { name: "大数据", icon: "icon-dashuju" },
        { name: "活动管理", icon: "icon-huodongguanli" },
        { name: "呼叫设置", icon: "icon-hujiaoshezhi" },
        { name: "分销市场", icon: "icon-fenxiaoshichang" },
        { name: "成绩统计", icon: "icon-chengjitongji" },
        { name: "推广设置", icon: "icon-tuiguangshezhi" },
        { name: "课程中心", icon: "icon-kechengzhongxin" },
        { name: "合同设置", icon: "icon-hetongshezhi" },
        { name: "课程包管理", icon: "icon-kechengbaoguanli" },
        { name: "用户管理", icon: "icon-yonghuguanli" },
        { name: "用户画像", icon: "icon-yonghuhuaxiang" },
        { name: "帐号管理", icon: "icon-zhanghaoguanli" },
        { name: "SEM管理", icon: "icon-SEMguanli" },
        { name: "预算", icon: "icon-yusuan" },
        { name: "名片报表", icon: "icon-mingpianbaobiao" },
        { name: "订单管理", icon: "icon-dingdanguanli" },
        { name: "推广管理", icon: "icon-tuiguangguanli" },
        { name: "专题管理", icon: "icon-zhuantiguanli" },
        { name: "信息流", icon: "icon-xinxiliu" },
        { name: "销售设置", icon: "icon-xiaoshoushezhi" },
        { name: "素材管理", icon: "icon-sucaiguanli" },
        { name: "学生导入", icon: "icon-xueshengdaoru" },
        { name: "商品管理", icon: "icon-shangpinguanli" },
        { name: "排课管理", icon: "icon-paikeguanli" },
        { name: "信息导入", icon: "icon-xinxidaoru" },
        { name: "收费配置", icon: "icon-shoufeipeizhi" },
        { name: "用户权限", icon: "icon-yonghuquanxian" },
        { name: "在籍学生管理", icon: "icon-zaijixueshengguanli" },
        { name: "学号管理", icon: "icon-xuehaoguanli" },
        { name: "课程资源管理", icon: "icon-kechengziyuanguanli" },
        { name: "面授教学安排", icon: "icon-mianshoujiaoxueanpai" },
        { name: "账房", icon: "icon-zhangfang" },
        { name: "开课管理", icon: "icon-kaikeguanli" },
        { name: "毕业统计", icon: "icon-biyetongji" },
        { name: "任务管理", icon: "icon-renwuguanli1" },
        { name: "招生准备", icon: "icon-zhaoshengzhunbei" },
        { name: "网络教学安排", icon: "icon-wangluojiaoxueanpai" },
        { name: "排课设置", icon: "icon-paikeshezhi" },
        { name: "班级管理", icon: "icon-banjiguanli" },
        { name: "学籍状态异动", icon: "icon-xuejizhuangtaiyidong" },
        { name: "精品课堂", icon: "icon-jingpinketang" },
        { name: "员工提点", icon: "icon-yuangongtidian" },
        { name: "考试安排", icon: "icon-kaoshianpai" },
        { name: "账房", icon: "icon-zhangfang1" },
        { name: "信息导入", icon: "icon-xinxidaoru1" },
        { name: "员工提点", icon: "icon-yuangongtidian1" },
        { name: "学籍状态异动", icon: "icon-xuejizhuangtaiyidong1" },
        { name: "学籍状态异动", icon: "icon-xuejizhuangtaiyidong2" },
        { name: "学号管理", icon: "icon-xuehaoguanli1" },
        { name: "班级管理", icon: "icon-banjiguanli1" },
        { name: "排课管理", icon: "icon-paikeguanli1" },
        { name: "用户权限", icon: "icon-yonghuquanxian1" },
        { name: "排课设置", icon: "icon-paikeshezhi1" },
        { name: "课程资源管理", icon: "icon-kechengziyuanguanli1" },
        { name: "在籍学生管理", icon: "icon-zaijixueshengguanli1" },
        { name: "毕业统计", icon: "icon-biyetongji1" },
        { name: "面授教学安排", icon: "icon-mianshoujiaoxueanpai1" },
        { name: "网络教学安排", icon: "icon-wangluojiaoxueanpai1" },
        { name: "考试安排", icon: "icon-kaoshianpai1" },
        { name: "开课管理", icon: "icon-kaikeguanli1" },
        { name: "精品课堂", icon: "icon-jingpinketang1" },
        { name: "招生准备", icon: "icon-zhaoshengzhunbei1" },
        { name: "入学成绩", icon: "icon-ruxuechengji" },
        { name: "网络教学", icon: "icon-wangluojiaoxue" },
        { name: "面授教学", icon: "icon-mianshoujiaoxue" },
        { name: "教师教室管理", icon: "icon-jiaoshijiaoshiguanli" },
        { name: "录取管理", icon: "icon-luquguanli" },
        { name: "录取成绩", icon: "icon-luquchengji" },
        { name: "资源", icon: "icon-ziyuan" },
        { name: "资源", icon: "icon-ziyuan1" },
        { name: "课件", icon: "icon-kejian" },
        { name: "外部课件", icon: "icon-waibukejian" },
        { name: "日志管理", icon: "icon-rizhiguanli" },
        { name: "模版管理", icon: "icon-mobanguanli" },
        { name: "学籍异动", icon: "icon-xuejiyidong" },
        { name: "学历认证", icon: "icon-xuelirenzheng" },
        { name: "异议处理", icon: "icon-yiyichuli" },
        { name: "论文申报", icon: "icon-lunwenshenbao" },
        { name: "论文设置", icon: "icon-lunwenshezhi" },
        { name: "教学点设置", icon: "icon-jiaoxuedianshezhi" },
        { name: "补录沉降", icon: "icon-buluchenjiang" },
        { name: "模块设置", icon: "icon-mokuaishezhi" },
        { name: "资料管理", icon: "icon-ziliaoguanli" },
        { name: "集团管理", icon: "icon-jituanguanli" },
        { name: "应用管理", icon: "icon-yingyongguanli" },
        { name: "官网试卷", icon: "icon-guanwangshijuan" },
        { name: "我的网课", icon: "icon-wodewangke" },
        { name: "脚本管理", icon: "icon-jiaobenguanli" },
        { name: "专业管理员", icon: "icon-zhuanyeguanliyuan" },
        { name: "学生成绩", icon: "icon-xueshengchengji" },
        { name: "学位计划", icon: "icon-xueweijihua" },
        { name: "学士学位申请", icon: "icon-xueshixueweishenqing" },
        { name: "服务管理", icon: "icon-fuwuguanli" },
        { name: "代金券", icon: "icon-daijinquan" },
        { name: "呼叫中心", icon: "icon-hujiaozhongxin" },
        { name: "引进收费", icon: "icon-yinjinshoufei" },
        { name: "应缴清单", icon: "icon-yingjiaoqingdan" },
        { name: "对账单", icon: "icon-duizhangdan" },
        { name: "商机管理", icon: "icon-shangjiguanli" },
        { name: "企微会话存档", icon: "icon-qiyehuihuacundang" },
        { name: "用户设置", icon: "icon-yonghushezhi" },
        { name: "店铺装修", icon: "icon-dianpuzhuangxiu" },
        { name: "消息设置", icon: "icon-xiaoxishezhi" },
        { name: "营销设置", icon: "icon-yingxiaoshezhi" },
        { name: "服务市场", icon: "icon-fuwushichang" },
        { name: "素材资源", icon: "icon-sucaiziyuan" },
        { name: "营销玩法", icon: "icon-yingxiaowanfa" },
        { name: "人事", icon: "icon-fl-renshi" },
        { name: "推广", icon: "icon-tuiguang" },
        { name: "文件夹", icon: "icon-wenjianjia" },
        { name: "用户列表", icon: "icon-yonghuliebiao" },
        { name: "电波心电图", icon: "icon-dianboxindiantu" },
        { name: "文件夹", icon: "icon-wenjianjia2" },
        { name: "学员管理", icon: "icon-xueyuanguanli" },
        { name: "资源管理", icon: "icon-ziyuanguanli" },
        { name: "课程管理", icon: "icon-kechengguanli" },
        { name: "课程包", icon: "icon-kechengbao" },
        { name: "题库管理", icon: "icon-tikuguanli" },
        { name: "网课市场", icon: "icon-wangkeshichang" },
        { name: "工单", icon: "icon-gongdan" },
        { name: "访客管理", icon: "icon-fangkeguanli" },
        { name: "线索管理", icon: "icon-xiansuoguanli" },
        { name: "客户管理", icon: "icon-kehuguanli" },
        { name: "集团客户", icon: "icon-jituankehu" },
        { name: "跟进记录", icon: "icon-genjinjilu" },
        { name: "呼叫管理", icon: "icon-hujiaoguanli" },
        { name: "工作台", icon: "icon-gongzuotai" },
        { name: "数据报表", icon: "icon-shujubaobiao" },
        { name: "访客报表", icon: "icon-fangkebaobiao" },
        { name: "账簿管理", icon: "icon-zhangbuguanli" },
        { name: "账簿管理_子系统", icon: "icon-zhangbuguanli_zi" },
        { name: "账簿管理_子系统", icon: "icon-zhangbuguanli_zixitong_A" },
        { name: "合同管理", icon: "icon-hetongguanli" },
        { name: "运营者管理", icon: "icon-yunyingzheguanli" },
        { name: "招聘管理", icon: "icon-zhaopinguanli" },
        { name: "工资管理", icon: "icon-gongziguanli" },
        { name: "我的审批", icon: "icon-wodeshenpi" },
        { name: "标准模版设置", icon: "icon-biaozhunmobanshezhi" },
        { name: "基础设置", icon: "icon-jichushezhi" },
        { name: "财务设置", icon: "icon-caiwushezhi" },
        { name: "产品设置", icon: "icon-chanpinshezhi" },
        { name: "审批设置", icon: "icon-shenpishezhi" },
        { name: "仓储配置", icon: "icon-list_cangchupeizhi"},
        { name: "出库登记", icon: "icon-list_chukudengji"},
        { name: "出库审核", icon: "icon-list_chukushenhe"},
        { name: "采购计划", icon: "icon-list_caigoujihua"},
        { name: "合同管理", icon: "icon-list_hetongguanli"},
        { name: "合同审核", icon: "icon-list_hetongshenhe"},
        { name: "计划审核", icon: "icon-list_jihuashenhe"},
        { name: "出库通知", icon: "icon-list_chukutongzhi"},
        { name: "加工", icon: "icon-list_jiagong"},
        { name: "加工审核", icon: "icon-list_jiagongshenhe"},
        { name: "付款申请", icon: "icon-list_fukuanshenqing"},
        { name: "进项票", icon: "icon-list_jinxiangpiao"},
        { name: "库存费用", icon: "icon-list_kucunfeiyong"},
        { name: "合同结算", icon: "icon-list_hetongjiesuan"},
        { name: "盘库", icon: "icon-list_panku"},
        { name: "入库登记", icon: "icon-list_rukudengji"},
        { name: "入库审核", icon: "icon-list_rukushenhe"},
        { name: "库存查询", icon: "icon-list_kucunchaxun"},
        { name: "入库通知", icon: "icon-list_rukutongzhi"},
        { name: "销售订单", icon: "icon-list_xiaoshoudingdan"},
        { name: "销售资源", icon: "icon-list_xiaoshouziyuan"},
        { name: "销售定价", icon: "icon-list_xiaoshoudingjia"},
        { name: "销项票", icon: "icon-list_xiaoxiangpiao"},
        { name: "长协审核", icon: "icon-list_changxieshenhe"},
        { name: "质保书", icon: "icon-list_zhibaoshu"},
        { name: "长协管理", icon: "icon-list_changxieguanli"},
        { name: "转库", icon: "icon-list_zhuanku"},
        { name: "应收款", icon: "icon-list_yingshoukuan"},
        { name: "付款审核", icon: "icon-list_fukuanshenhe"},
        { name: "应付款", icon: "icon-list_yingfukuan"},
        { name: "订单审核", icon: "icon-list_dingdanshenhe"},
        { name: "销售员", icon: "icon-ERP_xiaoshouyuan"},
        { name: "删除", icon: "icon-ERP_shanchu"},
        { name: "下单", icon: "icon-ERP_xiadan"},
        { name: "编辑", icon: "icon-ERP_bianji"},
        { name: "购物车", icon: "icon-ERP_gouwuche"},
        { name: "下载", icon: "icon-ERP_xiazai"},
        { name: "银行卡", icon: "icon-ERP_yinhangqia"},
        { name: "选择", icon: "icon-ERP_xuanze"},
        { name: "选中", icon: "icon-ERP_xuanzhong"},
        { name: "注意", icon: "icon-ERP_zhuyi"},
        { name: "货款总金额", icon: "icon-ERP_huokuanzongjine"},
        { name: "向左箭头", icon: "icon-ERP_xiangzuojiantou"},
        { name: "向右箭头", icon: "icon-ERP_xiangyoujiantou"},
        { name: "计算器", icon: "icon-list_jisuanqi"},
        { name: "首页当前状态", icon: "icon-ERP_shouyedangqianzhuangtai"},
        { name: "首页", icon: "icon-ERP_shouye"},
        { name: "待办", icon: "icon-ERP_daiban"},
        { name: "待办当前状态", icon: "icon-ERP_daibandangqianzhuangtai"},
        { name: "工作当前状态", icon: "icon-ERP_gongzuodangqianzhuangtai"},
        { name: "工作", icon: "icon-ERP_gongzuo"},
        { name: "出库变更审核", icon: "icon-list_chukubiangengshenhe"},
        { name: "退货申请审核", icon: "icon-list_tuihuoshenqingshenhe"},
        { name: "库存明细查询", icon: "icon-list_kucunmingxichaxun"},
      ],
      dataSourceColorIconList: [
        { name: "购置计划", icon: "icon-gouzhijihua" },
        { name: "选型计划", icon: "icon-xuanxingjihua" },
        { name: "购置进度", icon: "icon-gouzhijindu" },
        { name: "保养管理", icon: "icon-baoyangguanli" },
        { name: "维护管理", icon: "icon-weihuguanli" },
        { name: "运行管理", icon: "icon-yunhangguanli" },
        { name: "更新计划", icon: "icon-gengxinjihua" },
        { name: "安撤管理", icon: "icon-ancheguanli-copy" },
        { name: "安装管理", icon: "icon-anzhuangguanli" },
        { name: "调拨调剂", icon: "icon-tiaobotiaoji" },
        { name: "更新计划", icon: "icon-gengxinjihua1" },
        { name: "报废管理", icon: "icon-baofeiguanli" },
        { name: "租赁管理", icon: "icon-zulinguanli" },
        { name: "修理管理", icon: "icon-xiuliguanli" },
        { name: "审批管理", icon: "icon-shenpiguanli" },
        { name: "仓库管理", icon: "icon-cangkuguanli" },
        { name: "报表管理", icon: "icon-baobiaoguanli" },
        { name: "看板管理", icon: "icon-kanbanguanli" },
        { name: "标签管理", icon: "icon-biaoqianguanli" },
        { name: "设备管理", icon: "icon-shebeiguanli" },
        { name: "设备交接", icon: "icon-shebeijiaojie" },
        { name: "系统数", icon: "icon-xitongshu" },
        { name: "租赁台账", icon: "icon-zulintaizhang" },
        { name: "租赁协议", icon: "icon-zulinxieyi" },
        { name: "租赁费", icon: "icon-zulinfei" },
        { name: "工作面配套", icon: "icon-gongzuomianpeitao" },
        { name: "修理记录", icon: "icon-xiulijilu" },
        { name: "修理鉴定", icon: "icon-xiulijianding" },
        { name: "修理计划", icon: "icon-xiulijihua" },
        { name: "修理验收", icon: "icon-xiuliyanshou" },
        { name: "出库管理", icon: "icon-chukuguanli" },
        { name: "入库管理", icon: "icon-rukuguanli" },
        { name: "盘点管理", icon: "icon-pandianguanli" },
        { name: "库位管理", icon: "icon-kuweiguanli" },
        { name: "基础资料", icon: "icon-jichuziliao" },
        { name: "运行资料", icon: "icon-yunhangziliao" },
        { name: "安装资料", icon: "icon-anzhuangziliao" },
        { name: "操作手册", icon: "icon-caozuoshouce" },
        { name: "购置计划报表", icon: "icon-gouzhijihuabaobiao" },
        { name: "系统统计分析报表", icon: "icon-xitongtongjifenxibaobiao" },
        { name: "证明资料说明书", icon: "icon-zhengmingziliaoshuomingshu" },
        { name: "技术参数", icon: "icon-jishucanshu" },
        { name: "安全技术措施", icon: "icon-anquanjishucuoshi" },
        { name: "技术方案", icon: "icon-jishufangan" },
        { name: "竣工验收", icon: "icon-jungongyanshou" },
        { name: "安装记录", icon: "icon-anzhuangjilu" },
        { name: "调试记录", icon: "icon-tiaoshijilu" },
        { name: "保养记录", icon: "icon-baoyangjilu" },
        { name: "维护记录", icon: "icon-weihujilu" },
        { name: "故障记录", icon: "icon-guzhangjilu" },
        { name: "运行记录", icon: "icon-yunhangjilu" },
        { name: "统计记录", icon: "icon-tongjijilu" },
      ],
      iconList: [],
      colorIconList: [],
    };
  },
  props: {
    //要回填的字段名
    fieldname: {
      type: String,
      default: "",
      required: true,
    },
  },
  created() {
    this.iconList = Object.assign(this.dataSourceIconList, []);
    this.colorIconList = Object.assign(this.dataSourceColorIconList, []);
  },
  methods: {
    filterIcons() {
      if (this.activeName == "first") {
        this.iconList = Object.assign(this.dataSourceIconList, []);
        if (this.name) {
          this.iconList = this.iconList.filter(
            (item) =>
              item.name.match(new RegExp(this.name)) || item.icon == this.name
          );
        }
      } else {
        this.colorIconList = Object.assign(this.dataSourceColorIconList, []);
        if (this.name) {
          this.colorIconList = this.colorIconList.filter(
            (item) =>
              item.name.match(new RegExp(this.name)) || item.icon == this.name
          );
        }
      }
    },
    selectedIcon(name) {
      this.$emit("selected", { name: name, fieldname: this.fieldname });
      document.body.click();
    },
    reset() {
      this.name = "";
      this.iconList = Object.assign(this.dataSourceIconList, []);
      this.colorIconList = Object.assign(this.dataSourceColorIconList, []);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
// .icon-body {
//   width: 100%;
//   padding: 10px;
//   .icon-list {
//     height: 200px;
//     overflow-y: scroll;
//     div {
//       height: 30px;
//       line-height: 30px;
//       margin-bottom: -5px;
//       cursor: pointer;
//       width: 33%;
//       float: left;
//     }
//     span {
//       display: inline-block;
//       vertical-align: -0.15em;
//       fill: currentColor;
//       overflow: hidden;
//     }
//   }
// }
.icon-body {
  width: 550px;
  height: 400px;
  margin: 0 auto;
  overflow-y: auto;
}
#tabs {
  border-bottom: 1px solid #eee;
}

#tabs li {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  color: #666;
}

#tabs .active {
  border-bottom-color: #f00;
  color: #222;
}

.tab-container .content {
  display: none;
}
.icon_lists {
  width: 100% !important;
  overflow: hidden;
  *zoom: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.icon_lists li {
  width: 80px;
  margin-bottom: 10px;
  margin-right: 20px;
  text-align: center;
  list-style: none !important;
  cursor: default;
}

.icon_lists li .code-name {
  line-height: 1.2;
}

.icon_lists .icon {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
  margin: 10px auto;
  color: #333;
  -webkit-transition: font-size 0.25s linear, width 0.25s linear;
  -moz-transition: font-size 0.25s linear, width 0.25s linear;
  transition: font-size 0.25s linear, width 0.25s linear;
}

.icon_lists .icon:hover {
  font-size: 40px;
}

.icon_lists .svg-icon {
  /* 通过设置 font-size 来改变图标大小 */
  width: 1em;
  /* 图标和文字相邻时，垂直对齐 */
  vertical-align: -0.15em;
  /* 通过设置 color 来改变 SVG 的颜色/fill */
  fill: currentColor;
  /* path 和 stroke 溢出 viewBox 部分在 IE 下会显示
      normalize.css 中也包含这行 */
  overflow: hidden;
}

.icon_lists li .name,
.icon_lists li .code-name {
  color: #666;
}
</style>
