var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        _vm._g(
          _vm._b(
            {
              ref: "input",
              attrs: {
                value: _vm.input,
                placeholder: _vm.placeholderspan,
                size: "mini",
                type: _vm.type
              },
              on: { input: _vm.handle }
            },
            "el-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.fetchId
            ? _c("i", {
                staticClass: "el-icon-edit el-input__icon",
                attrs: { slot: "suffix" },
                on: { click: _vm.suffixclick },
                slot: "suffix"
              })
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }