import { Message } from 'element-ui';
import clone from 'clone';

import Vue from "vue"
/** 复制粘贴 */
export class CopyTable {
  constructor(e, tableData, rowIndex, columnIndex, props, noWrites) {
    this.tableData = JSON.parse(JSON.stringify(tableData))
    this.columnIndex = columnIndex
    this.rowIndex = rowIndex
    this.$set = Vue.set
    this.props = props || null
    this.noWrites=noWrites||[]
    this.pasteInfo(e)
  }
  // 处理对象内部的字段 按顺序
  initObj(editObj) {
    const keys = Object.keys(this.props || editObj)
    let emptyObj = {}
    keys.map(it => {
      emptyObj[it] = JSON.parse(JSON.stringify(editObj))[it]
    })
    return emptyObj
  }
  pasteInfo(e) {
    try {
      e.preventDefault(); //阻止默认粘贴事件
      e.stopPropagation(); //阻止事件冒泡
      let data = null, clipboardData = e.clipboardData || window.clipboardData; // IE
      if(!clipboardData) clipboardData = e.originalEvent.clipboardData;
      data = clipboardData.getData("Text"); //复制过来的内容
      //首先对源头进行解析
      //单独复制文本，不是复制单个单元格
      if(data && !data.includes('\r\n')) data = data + '\r\n';
      let rowStrArray = data.split("\r\n"), rows = []; //拆成多行
      for(let i = 0; i < rowStrArray.length - 1; i++) {
        let row = [], tdStrArray = rowStrArray[i].split("\t"); //按列拆分
        for(let j = 0; j < tdStrArray.length; j++) {
          row.push(tdStrArray[j]);
        }
        rows.push(row);
      }
      let emptyObj = this.initObj(this.tableData[this.rowIndex])
      // 可能会有数据 给空对象复制
      for(let j = 0; j < rows.length; j++) {
        if(this.rowIndex + j > this.tableData.length - 1) {
          this.tableData.push({})
        }
        let item = {}, num = 0, numFlag = 0; //从哪一列开始粘贴：全部列都可以粘贴(即从第0列可以粘贴)
        item = this.initObj(this.tableData[this.rowIndex + j])
        for(let key in emptyObj) {
          if(!rows[j][num] &&rows[j][num]!=="") {
            break
          }
          if(this.columnIndex <= numFlag) {
            // 针对不能修改的列字段做处理，可以复制粘贴的列才做赋值。根据需求加下面的if判断
            if(!this.noWrites.includes(key)){
              console.log(key)
              item[key] = rows[j][num]
            } 
            num = num + 1
          }
          numFlag = numFlag + 1
        }
        this.$set(this.tableData, this.rowIndex + j, item)
      }
    } catch (err) {
      console.log(err)
      Message.error('请选择粘贴位置')
    }
  }
}
//  复制el-table的相关业务逻辑
export const copyElTable = (e, data, rowIndex, columnIndex) => {
  const { tableData } = new CopyTable(e, data, rowIndex, columnIndex)
  return tableData
}
export const copyVxeTable = (e, table, rowIndex, columnIndex, copyConfig) => {
  if(!copyConfig.able) return false
  // rowData 修改前的当前行数据 prop 当前点击的单元格列字段 +2 对应新明细调用方法中的传参 -2
  let rowData = clone(table.getTableData().fullData[rowIndex]), 
      prop = table.getColumns()[columnIndex+2]?.field
  const creates = _ => {
    table.createRow().then(res => {
      const data = table?.getTableData()?.fullData
      let newRes = clone(res)
      // 默认的res中没有返回id和rid字段，此处手动添加上去，如果被粘贴的行数据为原有数据而非新增的数据，则会有id和rid字段，反正则没有，id、rid存在则会正常赋对应的值，若不存在，值会为undefined
      newRes['id'] = null
      newRes['rid'] = null
      if(data.length) {
        const { tableData } = new CopyTable(e, data, rowIndex, columnIndex, newRes, copyConfig.ignoreArr)
        table.reloadData(tableData)
        return tableData
      }
    })
  }
  if(table.getRemoveRecords().length) {
    let removeData = table.getRemoveRecords(),
        ridx = removeData.findIndex(f => f.id)
    // 如果有已删除的非新增数据保留了下来，然后表格又进行了数据粘贴，重置了表格状态，到了保存的时候，新明细的saveform方法会读取不到表格已删除的数据getRemoveRecords，会导致要删除的数据无法传到后台（无法赋值isDel）
    if(ridx!==-1) {
      Message({message:'有已删除的非新增数据，请先点击保存后再进行粘贴操作',type:'warning',duration:3000})
      // 重新将粘贴前的值赋值回给当前字段
      setTimeout(_ => {
        table.getTableData().fullData[rowIndex][prop] = rowData[prop]
        return false
      },300)
    } else creates()
  } else creates()
}