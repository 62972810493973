var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vuequilleditor" },
    [
      _c(
        "el-dropdown",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { disabled: _vm.disabled, trigger: "click" },
          on: { command: _vm.handleCommand }
        },
        [
          _c("el-button", { attrs: { type: "primary" } }, [
            _vm._v(" 获取模板"),
            _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _vm._l(_vm.commandlistdata, function(item, index) {
                return _c(
                  "el-dropdown-item",
                  { key: index, attrs: { command: item.value } },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              _vm.commandlistdata.length == 0
                ? _c("el-dropdown-item", { attrs: { disabled: "" } }, [
                    _vm._v(" " + _vm._s(_vm.$t("configMoudle.noData")) + " ")
                  ])
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("editor-bar", {
        attrs: {
          editorData: _vm.editorDataList,
          disabled: _vm.disabled,
          fartherid: _vm.fartherid,
          id: _vm.idSpan
        },
        on: { input: _vm.inputchange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }