import request from '@/axios/index';

export function formConfig({ method, reqInfo, id }) {
	return request({
		url: id ? `/formConfig/${id}` : `/formConfig`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
//测试
export function fieldsConfig({ method, reqInfo, id }) {
	return request({
		url: id ? `/fieldsConfig/${id}` : `/fieldsConfig`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
export function itemsConfig({ method, reqInfo, id }) {
	return request({
		url: id ? `/itemsConfig/${id}` : `/itemsConfig`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
// sheet页
export function sheetsConfig({ method, reqInfo, id }) {
	return request({
		url: id ? `/sheetsConfig/${id}` : `/sheetsConfig`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
// sheet页
export function sheetsConfigrequest(params) {
	let count = Math.ceil(Math.random() * 10)
	return request({
		url: "/sheetsConfig?" + count,
		method: "get",
		params
	});
}
//调取
export function fetchConfig({ method, reqInfo, id }) {
	return request({
		url: id ? `/fetchConfig/${id}` : `/fetchConfig`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
//调取字段设置列表
export function fetchFieldsConfig({ method, reqInfo, id }) {
	return request({
		url: id ? `/fetchFieldsConfig/${id}` : `/fetchFieldsConfig`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
//数据字典
export function dataDic({ method, reqInfo, id }) {
	return request({
		url: id ? `/dataDic/${id}` : `/dataDic`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
//数据字典项
export function dictItem({ method, reqInfo, id }) {
	return request({
		url: id ? `/dataItem/${id}` : `/dataItem`,
		method,
		[method === 'get' ? 'params' : 'data']: reqInfo,
	});
}
// 获取数据权限
export function roleNamefunc(params) {
	return request({
		url: '/dataDic/role_perms',
		methods: 'get',
		params
	})
}
// 清除缓存
export function clearCache() {
	let timestamp = new Date().getTime();
	return request({
		url: `custom/clearCache?time=${timestamp}`,
		method: 'get',
	});
}

// 复制模块
export function copyModule(data) {
	return request({
		url: '/formConfig/copyModules',
		method: 'post',
		data,
		timeout: 1000 * 60 * 10
	})
}

//初始化模块语言包
export function initLanguage() {
	return request({
		url: '/formConfig/initLanguage',
		method: 'post',
		timeout: 1000 * 60 * 10
	})
}

// 表单计算公式 保存
export function submitFormulaInfo(data, id) {
	return request({
		url: id ? '/form-calculate-formula?id=' + id : '/form-calculate-formula',
		method: 'post',
		data
	})
}

// 表单计算公式 获取字段数据
export function getFormulaById(id) {
	return request({
		url: `/form-calculate-formula/form/${id}`,
		method: 'get'
	})
}

// 表单计算公式  获取字段数据
export function getFormulaByFieldId(data) {
	return request({
		url: `/form-calculate-formula/${data.type}/${data.fieldId}`,
		method: 'get'
	})
}

// 表单计算公式  列表
export function getFormulaList() {
	return request({
		url: '/form-calculate-formula',
		method: 'get',
	})
}

// 获取	登录背景图  免登录
export function getLoginBackgroundPass() {
	return request({
		url: '/custom-theme/background/pass',
		method: 'get'
	})
}

// 获取	登录背景图
export function getLoginBackground() {
	return request({
		url: '/custom-theme/background',
		method: 'get'
	})
}

// 获取logo
export function getLogoImg() {
	return request({
		url: '/custom-theme/logo',
		method: 'get'
	})
}

// 上传 登录背景图
export function uploadLoginBackground(data) {
	return request({
		url: '/custom-theme/background',
		method: 'post',
		data: data
	})
}

// 上传 logo图
export function uploadLogoImg(data) {
	return request({
		url: '/custom-theme/logo',
		method: 'post',
		data: data
	})
}

//关于
export function about() {
	return request({
		url: '/help/about',
		method: 'get'
	})
}
