var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", "max-height": "450px" }
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: _vm.$t("lconfigMoudle.inenum"),
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "fno",
              label: _vm.$t("configMoudle.purchaseContract")
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "spname",
              label: _vm.$t("configMoudle.supplierName")
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "real_attach_name",
              label: _vm.$t("configMoudle.fileName")
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "attach_path",
              label: _vm.$t("configMoudle.filePath")
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("Approval.operation"),
              width: "200",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("i", {
                      staticClass: "el-icon-download",
                      on: {
                        click: function($event) {
                          return _vm.handleEdit(scope.$index, scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }