<template>
  <div>
    <el-input
      ref="input"
      :value="input"
      v-bind="$attrs"
      v-on="$listeners"
      @input="handle"
      :placeholder="placeholderspan"
      size="mini"
      :type="type"
    >
      <i
        v-if="fetchId"
        class="el-icon-edit el-input__icon"
        slot="suffix" @click="suffixclick">
      </i>
    </el-input>
  </div>
</template>

<script>
export default {
  name: "Y-input",
  props: {
    input: {
      type: String,
    },
    type:{
      type:String,
      default:"text"
    },
    placeholder: String,
    fetchId: {
      type: String,
    }
  },
  data() {
    return {
      placeholderspan: ""
    };
  },
  computed: {},
  created() {
    // console.log(this.placeholder)
    if (this.placeholder === "" || this.placeholder === null || this.placeholder === undefined) {
      this.placeholderspan = this.$t('configMoudle.content')
    } else {
      this.placeholderspan = this.placeholder
    }
  },
  mounted() {
    // this.$refs.input.focus()
    this.$emit('mounted')
   },
  watch: {},
  methods: {
    handle(val) {
      this.$emit("update:input", val);
    },
    suffixclick () {
      this.$emit("suffixclick");
    }
  },
  components: {},
};
</script>

<style scoped lang="scss"></style>
