import request from '@/axios/index';

// 订单进度列表查询
export function nodeListRepertory(params) {
	return request({
		url: 'orderQuotectrlList/selectList',
		method: 'get',
		params,
	});
}

// 订单进度列表查询
export function selectOrderQuotectrlById(data) {
	return request({
		url: `orderQuotectrlList/selectListById/`+data,
		method: 'post',
	});
}


// 订单节点库查询
export function selectOrderNodeList(params) {
	return request({
		url: '/orderNode/selectList',
		method: 'get',
		params,
	});
}

// 订单节点库查询
export function insertOrderNodeList(data) {
	return request({
		url: '/orderNode/insert',
		method: 'post',
		data,
	});
}

// 根据id查询订单详情
export function selectById(id) {
	return request({
		url: 'orderNode/selectById/' + id,
		method: 'post',
	});
}



//删除订单节点
export function delData(id) {
	return request({
		url: `orderNode/delete/` + id,
		method: 'post',
	});
}

//查找用户信息
export function selectSysUser() {
	return request({
		url: `sys/user`,
		method: 'get',
	});
}

//删除订单节点
export function update(data) {
	return request({
		url: `orderNode/update`,
		method: 'post',
		data,
	});
}
//订单进度列表详情添加节点
export function addOrderNodeList(data) {
	return request({
		url: `orderNodeList/saveNodeListOne`,
		method: 'post',
		data,
	});
}

//订单节点详情
export function selectByOrderListId(data) {
	return request({
		url: `orderNodeList/selectById/`+data,
		method: 'post',
	});
}

//修改节点信息
export function updateOrderNode(data) {
	return request({
		url: `orderNodeList/updateOrderNode`,
		method: 'post',
		data
	});
}

//删除节点信息
export function deleteNodeOrder(data,params) {
	return request({
		url: `orderNodeList/deleteByIds/`+params,
		method: 'post',
		data,
		params
	});
}



//发送钉钉
export function sendDingDing(data) {
	return request({
		url: `orderNodeList/sendMsgByDingDing/`+data,
		method: 'post',
	});
}