var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "annexes" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            drag: "",
            action: _vm.baseUrl + "/common/upload",
            headers: _vm.headers,
            "show-file-list": false,
            multiple: "",
            data: _vm.resData,
            "on-success": _vm.onSuccess,
            "before-upload": _vm.onProgress
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" " + _vm._s(_vm.$t("FormOfSet.uploadDrag")) + " "),
            _c("em", [_vm._v(_vm._s(_vm.$t("FormOfSet.clickUpload")))])
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip"
            },
            [_vm._v(_vm._s(_vm.$t("FormOfSet.uploadSize")) + "1GB")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }