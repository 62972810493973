<template>
  <div class="btns">
    <el-button
      :loading="loading"
      :size="size"
      v-for="item in button"
      type="primary"
      :icon="item.icon"
      :style="{ fontSize: fonts, fontFamily: family, fontWeight: weight }"
      :key="item.key"
      :disabled="item.disabled"
      @click="handleClickButton(item.key, item.label)"
    >{{ item.label }}</el-button>
  </div>
</template>
<script>
import { checkFont, getSession } from "@/utils/methods";
export default {
  name: "Y-button",
  props: {
    button: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: "mini",
    },
  },
  data() {
    return {
      loading: false,
      fonts: null,
      family: '',
      weight: ''
    };
  },
  methods: {
    handleClickButton(val, label) {
      if (!val) return;
      this.$emit("handleClickButton", val, label);
    },
    isLoading(loading = false) {
      this.loading = loading
    }

  },
  mounted() {
    let font = checkFont(), info = getSession('users');
    this.fonts = font;
    this.family = info.fontFamily == '默认' ? "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif" : info.fontFamily;
    this.weight = info.fontWeight == 'bold' ? 'bold' : 'normal';
  }
};
</script>

<style scoped lang="scss">
.btns {
  display: flex;
  align-items: flex-start;
  // margin-bottom: 10px;
}
</style>
