var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inputs" },
    _vm._l(_vm.inputs, function(item, index) {
      return _c(
        "div",
        {
          key: "inputs" + index,
          staticClass: "yinput",
          staticStyle: { margin: "5px 10px 0px 0" }
        },
        [
          (_vm.inputType[item[_vm.itemTypeKey]] || !item[_vm.itemTypeKey]) &&
          item.fieldName != "fsaved"
            ? _c("Y-input", {
                attrs: {
                  input: _vm.inputsData[item[_vm.keyEn]],
                  placeholder: item[_vm.keyCh] || _vm.$t("LanguageSet.content")
                },
                on: {
                  "update:input": function($event) {
                    return _vm.$set(_vm.inputsData, item[_vm.keyEn], $event)
                  }
                }
              })
            : _vm._e(),
          item.fieldName == "fsaved"
            ? _c("Y-select", {
                attrs: {
                  value: _vm.inputsData[item[_vm.keyEn]],
                  options: _vm.listOptions,
                  placeholder: item[_vm.keyCh] || _vm.$t("LanguageSet.content")
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.inputsData, item[_vm.keyEn], $event)
                  }
                }
              })
            : _vm._e(),
          item[_vm.itemTypeKey] === "select" && item.fieldName != "fsaved"
            ? _c("Y-select", {
                attrs: {
                  value: _vm.inputsData[item[_vm.keyEn]],
                  options: item.options,
                  placeholder: item[_vm.keyCh] || _vm.$t("LanguageSet.content")
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.inputsData, item[_vm.keyEn], $event)
                  }
                }
              })
            : _vm._e(),
          item[_vm.itemTypeKey] === "date"
            ? _c("Y-date", {
                attrs: {
                  value: _vm.inputsData[item[_vm.keyEn]],
                  placeholder: item[_vm.keyCh] || _vm.$t("LanguageSet.content")
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.inputsData, item[_vm.keyEn], $event)
                  }
                }
              })
            : _vm._e(),
          item[_vm.itemTypeKey] === "number" ||
          item[_vm.itemTypeKey] === "decimal"
            ? _c("Y-numberInput", {
                attrs: {
                  input: _vm.inputsData[item[_vm.keyEn]],
                  placeholder: item[_vm.keyCh] || _vm.$t("LanguageSet.content"),
                  filterType: item.filterType
                },
                on: {
                  "update:input": function($event) {
                    return _vm.$set(_vm.inputsData, item[_vm.keyEn], $event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }