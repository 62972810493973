<template>
  <div class="vuequilleditor">
    <!-- <el-dropdown style="margin-bottom: 10px" @command="handleCommand" trigger="click">
      <el-button type="primary" >
        获取模板<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in commandlistdata"
          :key="index"
          :command="item.value"
        >
          {{ item.label }}
        </el-dropdown-item>
        <el-dropdown-item
          v-if="commandlistdata.length == 0"
          disabled
        >
          暂无数据
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
    <editor-bar :editorData="editorDataList" @input="inputchange" :fartherid="fartherid" :id="idSpan"></editor-bar>
  </div>
</template>

<script>
import EditorBar from './div/index.vue'

export default {
  name: "Y-vueQuillEditorMerchandise",
  components: { EditorBar },
  data() {
    return {
      isClear: false,
      editorDataList: "",
      fartherid: "",
      idSpan: ""
    };
  },
  props: {
    editorData: {
      type: String
    },
    ids: {
      type: String
    },
    id: {
      type: String
    },
    commandlistdata: {
      type: Array
    }
  },
  watch: {
    // 监听父组件穿过来的数据，数据改变后刷新当前组件数据。
    editorData: {
      handler(newVal) {
        // console.log(newVal)
        this.editorDataList = newVal
      }
    },
    id: {
      handler(newVal) {
        // console.log(newVal)
        this.idSpan = newVal
      }
    },
    ids: {
      handler(newVal) {
        // console.log(newVal)
        this.fartherid = newVal
      }
    }
  },
  created() {
    // this.editorDataList = this.editorData
    // console.log("获取模板")
    // console.log(this.commandlistdata)
  },
  mounted() {
  },
  methods: {
    handleCommand (command) {
      // console.log(command)
      // let data = "<p>" + command + "</p>"
      this.editorDataList = command
      this.inputchange(command)
    },
    inputchange(val) {
      // 数据改变事件，改变之后将数据发送到父组件
      this.$emit('input', val)
    },
  },
  
};
</script>

<style lang="scss" scoped>
.vuequilleditor {
  height: 100%;
}
</style>