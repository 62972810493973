import { initLanguage } from "@/api/sysTem";

export default {
// 报表管理
  reportSet:{
    template: "orang templat",
   username : "personil",
   set: "Penugasan",
   empty: "Datanya kosong",
   reportName: "Nama statistik",
   isEnabled: "gunakan atau tidak",
},
// 代码事件
codeEvent:{
  eventSet: "penempatan insiden",
  placeSetName: "Silakan masukkan nama acara",
  eventName: "nama acara",
  eventId: "ID Acara",
  placeSet: "silakan masuk",
  fieldChange: "Perubahan lapangan",
  field: "Bidang",
  status: "negara",
  eventContent: "Konten acara",
  sqlConfig: "konfigurasi sql",
  name: "nama",
  remark: "Komentar"

},


  // 新增的确认对话框 一个个改实在是太困难了

    $confirm:{
      message:{
      修改为已读吗: "Ubah saat sudah dibaca?",
      确定撤回该数据吗: "Apakah Anda yakin ingin menarik data ini?",
      '是否要变更该合同？': "Apakah Anda ingin mengubah kontrak ini?",
      '是否要取消变更该合同？': "Apakah Anda ingin membatalkan perubahan kontrak ini?"
      },
      title:{
        "提示": "petunjuk",
      },
      cancelButtonText:{},
      confirmButtonText:{},

    },

  // 新增的提示弹框 绑定到vue 中文作为建明 修改太困难了
  $message:{
    请选择数据: "Silakan pilih datanya",
    请选择人员进行数据转交: "Silakan pilih orang untuk transfer data!",
    部署成功: "Penerapan berhasil",
    请先添加计划明细: "Silakan tambahkan detail paket terlebih dahulu",
    该文件暂不支持预览: "File ini belum mendukung pratinjau",
    已取消关闭: "Tutup dibatalkan",
    成功清除缓存: "Berhasil membersihkan cache",
    '模型名称重复，请使用新名称创建模型。': "Duplikat nama model, silakan buat model dengan nama baru",
    '用户名或密码不能为空！': "Nama pengguna atau kata sandi tidak boleh kosong!",
    "提交成功": "Berhasil dikirimkan",
    "提交失败": "Pengiriman Gagal",
    "已变更": "berubah",
    "已取消变更": "Perubahan dibatalkan",
    "待采明细有采购不能重新生成": "Detail yang akan dibeli tidak dapat dibuat ulang jika ada pembelian.",
  "生成待采明细成功": "Berhasil menghasilkan detail yang akan ditambang",
  "生成待采明细失败": "Gagal menghasilkan detail untuk ditambang",
  "此调取未包含目标模块的字段，请切换到正确的页签": "Panggilan ini tidak berisi bidang modul target. Silakan beralih ke tab yang benar.",
  "明细中供应商存在多个，请检查！": "Ada beberapa pemasok dalam rinciannya, silakan periksa!",
  "认领金额不能大于收款金额，请重新填写！": "Jumlah klaim tidak boleh lebih besar dari jumlah pembayaran, silakan diisi kembali!",
  "请选择要赋值的人员": "Silakan pilih orang yang ingin Anda beri nilai",
  "请选择模板人员!": "Silakan pilih personel template",
  "赋值成功": "Tugas berhasil",
  "赋值失败": "Penugasan gagal"
  },
    // 个性化
  individuation:{
    fieldName: "Nama kolom",
    visible: "Apakah akan ditampilkan",
    total: "Apakah akan bertambah",
    locktopic: "Apakah akan mengunci",
    width: "lebar saat ini",
    enable: "memungkinkan",
    disabled: "Cacat"
  },
    formSet: {
      languageSet: "beralih bahasa",
      hyperlink: "hyperlink",

      stretch: "menggeliat",
      tile: "Ubin",
      half: "setengah peregangan",
      third: "Menempati sepertiga",
      leftJustify: "rata kiri",
      rightJustify: "rata kiri",
      center: "kesejajaran tengah",
      equal: "setara",
      less: "kurang dari",
      lessOrequal: "kurang dari atau sama dengan",
      greater: "lebih dari itu",
      greatorequal: "lebih besar atau sama dengan",
      like: "seperti",
      left: "左kiri",
      right: "kanan kiri",
      between: "DI ANTARA",
      database: "basis data",
      expression: "ekspresi khusus",
      year: "Tahun",
      month: "bulan",
      day: "Hari",
      hour: "jam",
      minute: "titik",
      second: "Kedua",
      input: "Kotak masukan",
      DateRange: "Kotak pilihan rentang tanggal",
      dateInput: "Kotak pemilihan tanggal",
      selectOption: "kotak tarik-turun",
      switch: "mengalihkan",
      textarea: "bidang teks",
      numberInput: "kotak nomor",
      richText: "teks yang kaya",
      checkBox: "kotak centang tarik-turun",
      people: "personil",
      citySelect: "Kotak tarik-turun kota",
      selectInput: "kotak masukan tarik-turun",
      permiAll: "Semua izin data",
       permiCustom: "Izin data khusus",
       department: "Izin data departemen ini",
       deptBelow: "Izin data untuk departemen ini dan berikut ini",
       selfOnly: "Hanya izin data pribadi"
    },
    // 版本管理
    version: {
      record: "mengubah log",
      times: "Jumlah perubahan",
      updateDate: "Ganti tanggal",
      updatePerson: "Ganti orang",
      mainTableData: "Data perubahan tabel utama",
      detailsOldData: "Data asli terperinci",
      detailsData: "Data perubahan terperinci",
      oldData: "data mentah",
      newData: "Data setelah perubahan"
    },

    // 补充的提示语
    addTips: {
      approve: "Sedang ditinjau",
      reject: "Menolak",
      pass: "lulus",
      noUse: "Modul tidak diaktifkan",
      hasPigeonhole: "Diarsipkan",
      noPigeonhole: "Tidak diarsipkan",
      all: "semua",
      cancel: "Membatalkan",
      confirm: "mengonfirmasi",
      noSupportpreview: "File ini belum mendukung pratinjau",
      fileServiceIsNotDeployed: "Layanan pratinjau file tidak diterapkan",
      isAssign: "Apakah akan menentukan",
      programmeNameRequire: "Silakan isi nama paket",
      programmeCodeRequire: "Silakan isi nomor paket",
      addPerson: "Silakan pilih seseorang",
      number: "TIDAK.",
      groupEmpty: "Nama grup tidak boleh kosong",
      approveEmpty: "Silakan tambahkan simpul persetujuan",
      nodeEmpty: "Data simpul tidak boleh kosong",
      tempNameRequire: "Silakan masukkan nama templat",
      tableNameRequire: "Silakan masukkan nama tabel",
      refreshDatabase: "Harap tunggu, perpustakaan sedang disegarkan",
      nowusebbs: "Nama subtabel tidak boleh diulang",
      tableNameNorepeat: "Nama tabel tidak boleh diulang",
      moduleNameNorepeat: "Nama template tidak dapat diulang",
      done: "Operasi yang berhasil",
      doneFailed: "operasi gagal",
      successZhengshi: "Berhasil diubah menjadi pelanggan formal",
      selectZhengshi: "Silakan pilih pelanggan yang perlu diubah menjadi resmi",
      successGonghai: "Berhasil dikonversi menjadi pelanggan laut lepas",
      selectGonghai: "Silakan pilih pelanggan yang ingin transfer ke laut lepas",
      selectGiveData: "Silakan pilih data yang akan ditransfer",
      selectClassIs: "Yang Anda pilih adalah",
      ifContinue: "Konfirmasikan untuk melanjutkan?",
      addTopClass: "Silakan pilih kategori utama terlebih dahulu",
      cantclaimed: "Tagihan air saat ini tidak dapat diklaim",
      treeBelow: "Silakan pilih tingkat terendah dari struktur pohon untuk melanjutkan",
      goodCanNotDelete: "Node ini juga memiliki produk dan tidak dapat dihapus.",
      orderNo: "Pesanan pembelian tidak ada",
      addoccupy: "Silakan pilih detail yang perlu ditempati",
      onlyOneoccupy: "Hanya satu rekaman yang dapat dipilih",
      occupyNum: "Silakan masukkan jumlah yang terisi",
      warehouseLocation: "Lokasi penyimpanan",
      productName: "nama Produk",
      currentInventory: "Persediaan saat ini",
      purchaseQuantity: "Kuantitas pembelian",
      occupationQuantity: "Kuantitas yang ditempati",

      onlyOneTop: "Hanya satu tabel utama tingkat atas yang dapat dipilih",
      comfirmDelet: "Apakah Anda yakin ingin menghapusnya?",
      selectRow: "Silakan pilih baris yang ingin Anda pindahkan",
      onlyOneRowMove: "Hanya satu baris yang dapat dipilih untuk pergerakan",
      noEffectRow: "Nomor jalur tujuan tidak valid, silakan masukkan kembali.",
      noMaxLine: "Tidak boleh melebihi jumlah baris maksimum",
      fetchNoMove: "Data detail telah dipanggil dan tidak dapat dihapus",
      checkError: "Pengecualian detail verifikasi",
      nodeNoGood: "Saat ini tidak ada produk di node ini",
      languageNoRepeat: "Bahasa telah ditambahkan, mohon jangan ditambahkan lagi!",
      printMessage: "Pratinjau dan pencetakan tidak dapat dilakukan di browser IE, silakan gunakan browser lain untuk membukanya.",
      tableNoData: "Pengaturan proses modul ini tidak ditemukan, silakan atur dan coba lagi!",
      subassembly: "Sub-aksesori:"
    },
    //登录
    loginPage: {
      login: "Gabung",
      welCome: "Silakan masuk",
      password: "kata sandi",
      account: "akun"
    },
    GlobalConfig: {
      duplicationCheck: "mempersiapkan",
      CustomerSwitch: "peralihan pelanggan",
      SupplierSwitch: "peralihan pemasok",
      GoodsSwitch: "Peralihan produk",
      FeedbackConfiguration: "konfigurasi dasar",
      projectName: "Nama Proyek",
      projectContact: "Nomor kontrak proyek",
      version: "nomor versi",
      systemCallbackAddress: "Alamat panggilan balik sistem",
      wincareAddress: "alamat wincare",
      projectNamePlace: "Silakan isi nama proyek",
      projectContactPlace: "Silakan isi nomor kontrak proyek",
      wincareAddressPlace: "Silakan isi alamat panggilan balik sistem"
    },
    // 消息中心
    messageCenter: {
      addNewMessage: "Tambahkan pesan baru",
      editNewMessage: "Sunting pesan",
      messageTitle: "Judul pesan",
      modelName: "nama modul",
      messageContent: "Konten teks",
      remark: "Komentar",
      receiveBy: "Penerima",
      sendBy: "sponsor",
      createTime: "Waktu penciptaan",
      isRead: "Apakah sudah dibaca?",

    },
    placeHolder: {
      expression: "Ekspresi rumus perhitungan",

      selectLanguage: "Silakan pilih bahasa",
      forTrackPlaceHolder: "Silakan masukkan nomor pesanan",
      start: "bangkit",
      end: "akhir",
      to: "ke",
      startDate: "mulai tanggal",
      endDate: "tanggal akhir",
      selectDate: "pemilihan tanggal",
      startTime: "Waktu mulai",
      endTime: "Akhir waktu",
      pleaseFill: "silakan masuk",
      pleaseSelect: "tolong pilih",
      Financialrecognition: "Silakan pilih konfirmasi keuangan",
      onWeek: "minggu lalu",
      onMonth: "bulan lalu",
      threeMonth: "tiga bulan terakhir",
      contractNumber: "Nomor kontrak ekspor",
    },
    // 导航栏上方的操作栏
    user: {
      quit: "keluar",
      noticeCenter: "Pusat pesan",
      preWarning: "peringatan dini",
      statistical: "Laporan statistik",
      noticeAlert: "pemberitahuan pesan",
      searchMenu: "Menu pesan"
    },
    // 首页设置
    Home: {
      ToBeapproved: "Dokumen menunggu persetujuan",
      realTimeData: "Data waktu nyata", // 实时数据
      recents: "kunjungan terakhir", // 最近访问

    },
    // 列表设置
    listOfSet: {
      batchExport: "Ekspor massal",
      ponoFieldExist: "Bidang PONO tidak ada",
      attachmentManagement: "Impor manajemen lampiran",
      enableOcr: "Pengakuan OCR",
      add: "Baru",
      clearCatch: "bersihkan cache",
      loadField: "Muat bidang",
      copyModule: "modul salinan",
      search: "mencari",
      reset: "mengatur ulang",
      edit: "sunting",
      delete: "menghapus",
      colWidth: "lebar kolom",
      moduleName: "nama modul",
      mainTable: "Tabel utama",
      foreignKey: "kunci asing",
      listSql: "daftar sql",
      isImage: "gambar",
      imageColumn: "Gambarnya ada di kolom apa?",
      storedProcedure: "formulir prosedur tersimpan",
      isReadOnly: "Lihat saja",
      isInlineEditable: "Pengeditan sebaris",
      isUpdateKeyOnImport: "Impor pembaruan",
      isCheckFno: "Verifikasi bahwa fno unik",
      parentModule: "Modul unggulan",
      isPage: "Paginasi",
      contactSql: "hubungi sql",
      listStoredProcedure: "daftar prosedur tersimpan",
      checkStoredProcedure: "Verifikasi prosedur tersimpan",
      isShowList: "Tampilan daftar",
      tableImgShow: "Tampilan gambar tabel",
      formImgShow: "Tampilan gambar bentuk",
      isImport: "impor",
      isExport: "Ekspor",
      isSendEmail: "mengirim email",
      isUploadFile: "Unggah Lampiran",
      isBatchModify: "Pengeditan Batch",
      isAboutTypography: "Pengaturan huruf kiri dan kanan",
      isGlobalRetrieval: "pencarian global",
      tableFieldSet: "Pengaturan bidang tabel",
      formFieldSet: "Pengaturan bidang formulir",
      codeEventSet: "Pengaturan acara kode",
      languageSet: "pengaturan bahasa",
      sheetSet: "pengaturan halaman lembar",
      isDbClkJump: "Klik dua kali untuk memasukkan rincian",
      isShowSubtotal: "Subtotal yang dikelompokkan",
      showTheApprovalTab: "Tampilkan tab persetujuan",
      passProcName: "melalui prosedur tersimpan",
      rejectProcName: "Tolak prosedur tersimpan",
      pageSize: "Kuantitas tampilan default per halaman",
      addSuccess: "berhasil ditambahkan",
      editSuccss: "pembaruan selesai",

      /* 字段设置 */
      fieldShowOrder: "Urutan tampilan lapangan",
      databaseFieldName: "Nama bidang basis data",
      showName: "Nama tampilan bagian depan",
      dataType: "jenis data",
      alignType: "Penyelarasan",
      queryOperator: "operator kueri",
      isSelect: "Menanyakan",
      isLocked: "mengunci",
      isGlobalQuery: "pencarian global",
      isSum: "total",
      isShow: "bisa dilihat",
      dataDict: "Kamus data",
      datePrecision: "ketepatan tanggal",
      language: "pengaturan bahasa",
      databaseFields: "Nama bidang basis data",
      configFormula: "Editor Persamaan",
      initialization: "Modul inisialisasi",
      initLanguage: "bahasa inisialisasi",
      //编码设置
      save: "menyimpan",
      year: "Tahun (2 digit)",
      years: "Tahun (4 digit)",
      month: "bulan",
      date: "tanggal",
      fixedContent1: "Memperbaiki konten 1",
      fixedContent2: "Memperbaiki konten 2",
      serialNumber: "nomor seri",
      loginId: "ID orang masuk",
      serialNoDigit: "Digit nomor seri",
      serialNoUt: "Satuan bilangan ganjil",
      pattern: "Format",
      formField1: "Bidang formulir 1",
      formField2: "Bidang formulir 2",
      formField3: "Bidang formulir 3",
      formField4: "Bidang formulir 4",
      formField5: "Bidang formulir 5",
      smallSerialStart: "Nomor awal air mengalir kecil",
      bigSerial: "Daliuhao",
      resetSuccess: "Mengatur ulang",
      soon: "Tunggu",
      strip: "mengupas",

    },

    FormOfSet: {
      uploadDrag: "Seret file ke sini, atau",
      clickUpload: "Klik untuk mengunggah",
      uploadSize: "Ukuran file tidak melebihi",

      add: "Baru",
      fieldOrder: "Urutan tampilan lapangan",
      name: "nama",
      showName: "nama tampilan",
      defaultValue: "Tambahkan nilai default",
      expressionDecimalDigit: "digit yang dipesan",
      dataType: "jenis data",
      editType: "Jenis edit",
      formConfigId: "ID konfigurasi formulir",
      isMustFill: "Diperlukan",
      approveParam: "Kirim bidang",
      approveChange: "Bidang perubahan persetujuan",
      combinedField: "Jumlah bidang",
      majorKey: "kunci utama",
      isShow: "bisa dilihat",
      isReadOnly: "hanya baca",
      dataDict: "Kamus data",
      remark: "Komentar",
      sheet: "Halaman lembar milik",
      expression: "ekspresi khusus",
      clauseTemplete: "Model Templat Ketentuan",
      widthType: "tipe lebar",
      matchingCondition: "Silakan pilih kriteria yang cocok",
      noFileSelected: "Tidak ada file yang dipilih"
    },

    // 配置模块
    configMoudle: {
      filesManage: "Manajemen lampiran",
      tip: "petunjuk",
      ocr: "Pengakuan OCR",
      tableExportName: "data tabel",
      timedifference: "Perbedaan waktu",
      nodeFinish: "Bidang waktu penyelesaian node",

      nodeFlow: "Proses simpul",
      waterKey: "xiaoliushuikey",
      topLeval: "Atas",

      operator: "operator seleksi",
      function: "Pilih fungsi",
      selectfieldName: "Pilih bidang",

      clear: "Jernih",
      fieldType: "Jenis Bidang",
      resultField: "bidang hasil",

      formulaEditor: "Editor Persamaan",
      fieldDigit: "digit yang dipesan",

      fieldLength: "panjang lapangan",
      characteristic: "Properti bidang",
      dbname: "Nama basis data",
      fieldName: "Nama Bidang",
      catalogue: "Daftar isi",
      menu: "menu",
      button: "tombol",

      male: "pria",
      female: "perempuan",
      normal: "normal",
      diabled: "menonaktifkan",
      signOrNot: "Maukah kamu menandatanganinya?",
      tableName: "Nama Tabel",
      bulkEdit: "Pengeditan massal",
      portDetails: "Detail dokumen",
      totalAll: "total",
      noData: "Tidak ada data",
      multipleChoice: "Pilihan tunggal",
      saveFirst: "Silakan simpan dulu",
      uploadTypeTip: "Upload gambar hanya boleh dalam format JPG atau PNG!",

      uploadSuccess: "Unggahan berhasil",
      uploadFail: "gagal mengunggah",
      ForTrackRemark: "Keterangan: Modul dan nomor pesanan yang dimilikinya wajib diisi. Silakan klik Query setelah menyelesaikan input.",
      searchForTrack: "Minta nomor pelacakan",

      addFile: "Tambahkan sisipan",
      updateFile: "Ubah lampiran",

      moveLineNum: "Nomor garis sasaran",
      destLineNum: "Nomor jalur tujuan",
      selctRowTip: "Silakan pilih baris yang akan diedit",
      selctRowTipDelet: "Silakan pilih baris yang ingin Anda hapus",

      getTemplate: "Dapatkan templat",
      details: "Detail",
      forModel: "Modul milik",
      forTrack: "Milik nomor pesanan",
      filesManage: "Manajemen lampiran",
      serialNumber: "nomor seri",

      filesLoading: "Pemuatan lampiran",
      noConfigTotal: "Anda belum mengonfigurasi subtotal",
      noGroup: "Silakan pilih kriteria pengelompokan",
      noTotalField: "Silakan pilih bidang subtotal",
      Financialrecognition: "konfirmasi keuangan",
      FinancialCancle: "Batalkan konfirmasi keuangan",

      noRepeat: "tidak dapat diulangi",
      confirmRecall: "Apakah akan mengingat data saat ini",
      recallSucces: "Penarikan berhasil",
      CategoryNum: "Nomor kategori",
      shareSuccess: "Bagikan kesuksesan",
      imageUpload: "Unggah banyak gambar",
      selectFillter: "Masukkan kata kunci untuk difilter",
      inventoryNumber: "Nomor pesanan stok",
      selectYes: "Ya",
      selectNo: "TIDAK",
      confirmDelete: "Hapus data saat ini?",
      transferOrder: "perintah pemindahan",
      highSeasCustomers: "Pelanggan laut lepas",
      customerMemorabilia: "Acara pelanggan",
      formalCustomers: "Pelanggan resmi",
      sendEmail: "mengirim email",
      excelCopy: "salinan Excel",
      claim: "mengeklaim",
      add: "Baru",
      copyTokey: "Salin ke tabel",
      copyToform: "salin ke formulir",
      edit: "sunting",
      delete: "menghapus",
      bulkEdit: "Pengeditan Batch",
      content: "Silakan masukkan konten",
      selectDate: "Pilih tanggal",
      select: "tolong pilih",
      commodityTypeAdmin: "Manajemen kategori produk",
      parentType: "Kategori Induk",
      TypeName: "nama klasifikasi",
      clickUpload: "Klik untuk mengunggah",
      fileView: "Pratinjau berkas",
      Viewflie: "Pratinjau",
      save: "menyimpan",
      printFormat: "Format cetak",
      headSeal: "stempel kop surat",
      cancel: "Membatalkan",
      print: "Mencetak",
      more: "Lagi",
      share: "bersama",
      placeOnFile: "Arsip",
      personnel: "Seleksi personel",
      personName: "personil",
      classChange: "Perubahan kategori",
      uploadPicture: "mengunggah gambar",
      billsPrint: "Pencetakan dokumen",
      uploadPictures: "Unggah gambar secara berkelompok",

      globalQuery: "pencarian global",
      modelName: "Nama templat",
      search: "Menemukan",
      img: "gambar",
      tip1: "Silakan masukkan nama kategori",
      tip2: "Berhasil dimodifikasi",
      tip3: "Berhasil ditambahkan",
      tip4: "Tolong pilih satu",
      tip5: "Apakah Anda yakin ingin menghapus kategori dan subkategori saat ini?",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      tip6: "berhasil dihapus",
      refresh: "menyegarkan",
      close: "penutup",
      closeLeft: "tutup kiri",
      closeRight: "tutup kanan",
      closeOther: "Tutup lainnya",
      closeAll: "Tutup semua",


      view: "Memeriksa",
      versionView: "Tampilan versi",
      tip7: "Hanya format Excel yang dapat diunggah",
      tip8: "Anda bukan pembuat dokumen ini",
      tip9: "Dokumen tersebut sedang ditinjau atau telah disetujui",
      tip10: "Anda tidak memiliki baris yang dipilih",
      tip11: "Mohon untuk tidak mencentang baris yang telah disetujui atau sedang dalam proses disetujui.",
      tip12: "Silakan pilih satu baris sebelum memodifikasinya",
      tip13: "Silakan pilih satu baris sebelum mencetak",
      tip14: "Silakan pilih format keluaran laporan!",
      tip15: "Silakan pilih nama modul!",
      tip16: "Cetak berhasil",
      tip17: "pengunduhan berhasil",

      approvalHistory: "Sejarah persetujuan",
      purchaseContract: "Nomor kontrak pembelian",
      supplierName: "Nama pemasok",
      fileName: "nama aksesori",
      filePath: "Jalur lampiran",
      fileType: "Jenis lampiran",

      compilingAndbrushingLibraries: "Kompilasi dan sikat perpustakaan",
      moduleName: "nama modul",
      mainTable: "Nama tabel utama",
      subTableName: "Nama subtabel",
      subTable: "Nama tabel subtabel",
      aboutMainTable: "Tabel utama yang sesuai",
      createBy: "pendiri",


      // 详情
      fetch: "Pengambilan",
      approval: "Persetujuan",
      reject: "Persetujuan ditolak",
      approvalOption: "Komentar persetujuan",
      sendEmail: "mengirim email",
      Examination: "Persetujuan",
      ExaminationSave: "Persetujuan dan simpan",

      AnnextopurchaseContract: "Lampiran kontrak pembelian",
      Appendixtoexportcontract: "Lampiran pada kontrak ekspor",
      uploadFile: "Unggah Lampiran",
      report: "Laporan",
      includeFetchSql: "Berisi diambil",
      includeSqlFinish: "Berisi data yang diambil",
      viewLargeImg: "Lihat gambar lebih besar",
      save: "menyimpan",
      status: "negara",
      copy: "menyalin",
      topcopy: "Naik",
      bottomcopy: "Turunkan",
      moveRow: "Pindahkan nomor bank",
      update: "menyimpan",
      submit: "kirim",
      recall: "menarik",
      list: "daftar",
      copyInvoice: "Salin dokumen",
      // tip17: "Silakan pilih konten dan klik OK",
      tip18: "Halaman telah disegarkan, silakan masuk kembali ke halaman ini dan lakukan operasi ini lagi!",
      subTotalSet: "Konfigurasi subtotal",
      openSubtotal: "Aktifkan subtotal",
      closeSubtotal: "Tutup subtotalnya",
      groupCondition: "Kondisi pengelompokan",
      subtotalField: "bidang subtotal",
      notSelected: "Tidak terpilih",
      selected: "terpilih",
      goLeft: "ke kiri",
      goRight: "ke kanan",
      noFno: "Nomor kontrak ini tidak ditemukan dan tidak dapat dialihkan",

      // Y-common
      linenum: "Nomor baris",
      batchDelete: "penghapusan batch",
      replaceLineNumber: "Nomor jalur tujuan",
      tip19: "Nomor baris yang Anda masukkan ilegal",
      tip20: "Status persetujuan sedang berlangsung...",
      tip21: "Silakan pilih data yang ingin Anda hapus",
      tip22: "Hanya satu baris yang dapat dipilih untuk pembungkusan baris",
      tip23: "Silakan pilih data yang ingin Anda bagikan",
      tip24: "Silakan pilih data yang akan diarsipkan atau dibatalkan pengarsipannya",
      tip25: "Silakan pilih orang yang ingin diajak berbagi",
      tip26: "Apakah akan membatalkan arsip",
      tip27: "Pembatalan pengarsipan berhasil",
      tip28: "Berhasil diarsipkan",
      tipQuit: "Apakah Anda yakin ingin keluar?",
      sureToSave: "Jika Anda sudah menyimpan datanya, klik OK!",
      cancelFilter: "Batalkan penyaring",

      SalesContract: "Kontrak penjualan ekspor",
      PurchaseContract: "kontrak pembelian",
      alteration: "mengubah",
      cancelAlteration: "Batalkan perubahan",

    },


    // 列表设置-----语言设置
    LanguageSet: {
      contentRequire: "Bahasa tidak boleh kosong!",
      contentRequire: "Konten tidak boleh kosong",
      add: "Baru",
      edit: "Merevisi",
      language: "bahasa",
      content: "isi",
      operation: "beroperasi",
      simplifiedChinese: "Cina disederhanakan",
      confirm: "Tentu",
      cancel: "Membatalkan"
    },

    //列表设置-- sheet页设置
    SheetSetting: {
      name: "nama",
      AccordingToTheOrder: "Tampilkan semacam",
      add: "Baru"
    },

    // 调取设置
    FetchSet: {
      sequence: "memesan",
      add: "Baru",
      name: "nama",
      targetModuleId: "ID modul target",
      parentModuleId: "ID modul unggul",
      targetField: "bidang sasaran",
      colWidth: "Lebar kolom bidang",
      isPaged: "Paginasi",
      fetchSql: "pernyataan panggilan",
      isShowImage: "menampilkan gambar",
      showColumns: "Di kolom manakah itu ditampilkan?",
      isRetrieval: "memungkinkan",
      containsCalledSQL: "Berisi Sql yang dimuat",
      edit: "sunting",
      delete: "menghapus",
      languageSetting: "pengaturan bahasa",
      tableFiledSetting: "Pengaturan bidang tabel",
      copySetting: "menyalin",
      fetchWarinning: "Silakan isi pernyataan panggilan!",
      cancel: "Membatalkan",
      confirm: "Tentu"
    },

    // 调取设置 ---- 调取字段设置
    FetchKeySet: {
      add: "Baru",
      fieldShowOrder: "Urutan tampilan lapangan",
      databaseFieldName: "Nama bidang basis data",
      showName: "Nama tampilan bagian depan",
      dataType: "jenis data",
      alignType: "Penyelarasan",
      isSelect: "Menanyakan",
      queryOperator: "operator kueri",
      isSortable: "menyortir",
      isFetched: "Pengambilan",
      isLocked: "mengunci",
      targetField: "bidang sasaran",
      isShow: "bisa dilihat",
      datePrecision: "ketepatan tanggal",
    },

    // 首页设置
    homeSet: {
      homeSet: "Pengaturan halaman beranda",
      roleNamePlaceHolder: "Silakan masukkan nama peran",
      warningSet: "Pengaturan peringatan",
      reportSet: "Pengaturan laporan",
      earlyWarning: "peringatan dini",
      title: "judul",
      router: "rute",
      sql: "sql",
      languageSetting: "pengaturan bahasa",
      save: "menyimpan",
      select: "tolong pilih",
      inputPlaceHolder: "Silakan masukkan konten",
      report: "Laporan",
      save: "menyimpan",
      add: "Baru",
      edit: "sunting",
      submitTip: "Data yang dikirimkan tidak boleh kosong",
      saveTip: "Berhasil disimpan",
      completeTip: "Silakan lengkapi data dan simpan sebelum mengatur bahasa",
      saveFailed: "Gagal menyimpan",
      qrSetting: "Pengaturan kode QR",
      qrUpload: "Unggah kode QR aplikasi",
      qrEdit: "Pengeditan informasi kode QR",
      qrPlaceholder: "Silakan masukkan informasi yang ingin Anda edit",
      qrTips: "Silakan unggah gambar terlebih dahulu",
      basicInfor: "informasi dasar",
      fontConfig: "Pengaturan huruf",
      passWordConfig: "ganti kata sandi",
      languageConfig: "pengaturan bahasa",
      fontSize: "Pengaturan ukuran halaman font",
      fontFamily: "Pengaturan jenis font halaman",
      oldPassword: "password lama",
      newPassword: "kata sandi baru",
      languageSet: "pengaturan bahasa",
      defaultFont: "bawaan",
      smallFont: "ukuran sedang",
      largeFont: "besar",
      defultFont: "bawaan",
      song: "Dinasti Song",
      kai: "skrip biasa",
      yuan: "tubuh bulat",
      shijian: "Puisi yang disederhanakan",
      logoSetting: "Unggah gambar logo",
      companySetting: "nama",
      loginBackgroundSetting: "Gambar latar masuk",
    },

    // 报表权限设置
    ReportSet: {
      jimuReportPermissionSetting: "Pengaturan izin laporan blok penyusun",
      roleNamePlaceHolder: "Silakan masukkan nama peran",
      save: "menyimpan",
      code: "nomor seri",
      reportName: "Nama laporan",
      createTime: "Waktu penciptaan",
      warningTip: "Silakan pilih setidaknya satu",
      saveTip: "Berhasil disimpan",
      add: "Baru",
      edit: "sunting",
    },

    // 字典设置
    DictSet: {
      searchButton: "mencari",
      resetButton: "mengatur ulang",
      add: "Baru",
      edit: "sunting",
      delete: "menghapus",
      dictName: "Nama kamus",
      isForbidden: "Cacat",
      enabled: "normal",
      disAbled: "Cacat",
      remark: "Komentar",
      dataItemSetting: "Pengaturan item data",
      name: "nama",
      alias: "Alias",
      sortOrder: "Memesan",
      desc: "menggambarkan",
      dataPermission: "izin data",
    },

    // 菜单管理
    menuSet: {
      shangpinku: "Ambil perpustakaan produk",
      kucun: "Ambil inventaris",
      yushou: "Alokasi penerimaan uang muka",
      permisVies: "Lihat izin",
      menuName: "Nama menu",
      search: "mencari",
      reset: "mengatur ulang",
      add: "Baru",
      edit: "sunting",
      delete: "menghapus",
      icon: "ikon",
      type: "jenis",
      orderNum: "urutan tampilan",
      permsCode: "ID Izin",
      remark: "Komentar",
      routingPath: "alamat perutean",
      menuStatus: "Status menunya",
      addMenu: "Menu baru",
      menuType: "Jenis menu",
      menuIcon: "Ikon menu (monokrom)",
      menuIconColor: "Ikon menu beraneka warna",
      menuVisable: "Menunya terlihat",
      menuOrder: "Urutan menu",
      parentCatalogue: "Direktori induk",
      associatedModule: "Modul terkait",
      buttonName: "Nama tombol",
      language: "pengaturan bahasa",
      action: "beroperasi",
      associateModule: "Modul terkait"
    },

    // 用户管理
    userAdmin: {
      deptPlace: "Silakan masukkan nama departemen",
      userName: "nama belakang",
      password: "kata sandi",
      loginAccount: "Masuk akun",
      phoneNumber: "nomor telepon",
      status: "negara",
      createTime: "Waktu penciptaan",
      search: "mencari",
      reset: "mengatur ulang",
      add: "Baru",
      passOn: "transfer",
      passOnSuccess: "Transfer berhasil",
      passOnPerson: "Transfer orang sasaran pesanan",
      passOnMoudle: "Modul pesanan transfer",

      deptName: "Nama departemen",
      nickName: "Nama Panggilan Pengguna",
      englishName: "nama Inggris",
      phone: "nomor telepon",
      email: "email pengguna",
      roleName: "Peran",
      sex: "jenis kelamin",
      postId: "Id Posisi",
      avatar: "avatar",
      deptId: "ID Departemen",
      passoword: "kata sandi",
      remark: "Komentar",
      dept: "departemen",
      edit: "sunting",
      delete: "menghapus",
      tip1: "Silakan pilih salah satu untuk diedit",
      loadingtip: "Sedang memuat, harap tunggu...",
      tip2: "Silakan pilih salah satu untuk dihapus",
      tip3: "Konfirmasi penghapusan",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      deleteSuccess: "berhasil dihapus!",
      editSuccess: "Berhasil dimodifikasi",
      noEmpty: "Tidak boleh kosong",
      limitOfAuthority: "Izin",
      synchronousAssignmentGroup: "Grup tugas sinkron",
    },
    // 用户管理 --- 编辑用户
    editUserAdmin: {
      save: "menyimpan",
      sync: "Sinkronisasi",
      add: "Baru",
      userInfo: "Info Pengguna",
      nickName: "Nama Panggilan Pengguna",
      englishName: "nama Inggris",
      password: "kata sandi",
      phoneNumber: "nomor telepon",
      remark: "Komentar",
      loginAccount: "Masuk akun",
      userEmail: "email pengguna",
      postId: "Id Posisi",
      dept: "departemen",
      roleName: "Peran",
      superiorLeaders: "Atasan",
      sex: "jenis kelamin",
      status: "negara",
      emailConfig: "Konfigurasi email",
      dataPermission: "izin data",
      modelName: "nama modul",
      groupName: "Nama grup",
      groupCode: "Kode grup",
      edit: "sunting",
      delete: "menghapus",
      loadingtip: "Sedang memuat, harap tunggu...",
      saveSuccess: "Berhasil disimpan",
      emailUserName: "Nama pengirim",
      emailPassword: "kata sandi email",
      smtpHost: "Alamat SMTP",
      smtpPort: "Pelabuhan SMTP",
      signature: "tanda",
      ownPhone: "Nomor ponsel pribadi",
      qq: "QQ",
      weChat: "Wechat wechat",
      platformName: "Nama platform",
      idcard: "nomor ID",
      isEmailManage: "Apakah itu administrator kotak surat?",
      noPicture: "Belum ada gambar"

    },



    // 在线用户
    userOnline: {
      userName: "nama belakang",
      name: "nama Cina",
      search: "mencari",
      reset: "mengatur ulang",
      kickOut: "Mengeluarkan",
      email: "Surat",
      phone: "Telepon",
      sex: "jenis kelamin",
      loginConputerIp: "Masuk IP komputer",
      loginTime: "Waktu masuk",
      nowTime: "Durasi masuk",
      status: "negara",
      remark: "Komentar",
      tip1: "Apakah Anda yakin ingin mengeluarkan pengguna ini?",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      tip2: "Silakan pilih terlebih dahulu sebelum menendang!",
      tip3: "Pengguna dikeluarkan"
    },


    // 角色管理
    roleAdmin: {
      addRole: "Tingkatkan izin",
      cancleRole: "Batalkan izin",
      tip1: "Apakah Anda yakin ingin menghapus peran yang dicentang?",
      menuAllot: "Penetapan menu",
      roleName: "Nama peran",
      status: "negara",
      search: "mencari",
      reset: "mengatur ulang",
      add: "Baru",
      save: "menyimpan",
      showSort: "urutan tampilan",
      roleStatus: "status karakter",
      permissionChar: "karakter izin",
      permissionScope: "Ruang lingkup kewenangan",
      dataPermission: "izin data",
      dataScope: "rentang data",
      remark: "Komentar",
      edit: "sunting",
      delete: "menghapus",
      tip2: "Tidak boleh kosong",
      tip3: "Silakan pilih sebelum menghapus",
      tip4: "Silakan pilih menu yang ingin Anda ubah tugasnya",
      tip5: "Berhasil disimpan"
    },

    // 部门管理
    deptAdmin: {
      deptName: "Nama departemen",
      status: "negara",
      search: "mencari",
      reset: "mengatur ulang",
      add: "Baru",
      deptCode: "Kode departemen",
      departmentHead: "kepala Departemen",
      contactWay: "Kontak informasi",
      showSort: "urutan tampilan",
      createTime: "Waktu penciptaan",
      addDept: "Tambahkan departemen baru",
      superiorDepartment: "Kantor yang lebih tinggi",
      edit: "sunting",
      delete: "menghapus",
      tip1: "Apakah Anda yakin akan menghapus departemen yang dipilih?",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      tip2: "Silakan pilih terlebih dahulu sebelum menghapus!",
      tip3: "berhasil dihapus"
    },

    // 部门管理
    deptAdmin: {
      deptName: "Nama departemen",
      status: "negara",
      search: "mencari",
      reset: "mengatur ulang",
      remark: "Komentar",
      add: "Baru",
      deptCode: "Kode departemen",
      departmentHead: "kepala Departemen",
      contactWay: "Informasi kontak (nomor ponsel)",
      showSort: "urutan tampilan",
      createTime: "Waktu penciptaan",
      addDept: "Tambahkan departemen baru",
      superiorDepartment: "Kantor yang lebih tinggi",
      edit: "sunting",
      delete: "menghapus",
      tip1: "Apakah Anda yakin akan menghapus departemen yang dipilih?",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      tip2: "Silakan pilih terlebih dahulu sebelum menghapus!",
      tip3: "berhasil dihapus"
    },

    //打印管理
    printAdmin: {
      add: "Baru",
      modelNumber: "Nomor seri modul",
      modelCode: "kode modul",
      modelId: "ModulId",
      mainTableName: "Nama tabel utama",
      modelName: "nama modul",
      reportName: "Nama laporan",
      idField: "bidang identitas",
      remark: "Komentar",
      edit: "sunting",
      delete: "menghapus",
      tip1: "Apakah Anda yakin untuk menghapus modul yang dicentang?",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      tip2: "Silakan pilih sebelum menghapus",
      tip3: "berhasil dihapus",
      classify: "Klasifikasi",
      printer: "pencetak",
      closeConfirm: "Apakah Anda menyimpan sebelum menutup?"
    },
    // 系统日志
    Systemlog: {
      tip: "petunjuk",
      requestParams: "Parameter permintaan",
      returnParams: "Parameter pengembalian",
      module: "modul",
      status: "negara",
      message: "informasi",
      requestMethodName: "Minta nama metode",
      requestIp: "Minta IP",
      requestWay: "Metode permintaan",
      bussinessType: "jenis bisnis",
      cancel: "Membatalkan",
      confirm: "Tentu",
      search: "mencari",
      reset: "mengatur ulang",
      add: "Baru",
      delete: "menghapus",
      tip1: "Apakah Anda yakin untuk menghapus parameter yang dicentang?",
      tip2: "Silakan pilih sebelum menghapus",
      tip3: "hapusSukses"
    },

    // 模板类型
    termsTemplete: {
      add: "Baru",
      templeteName: "Nama templat",
      templeteContent: "Konten templat",
      content: "Silakan masukkan konten",
      templeteType: "jenis templat",
      createBy: "pendiri",
      createTime: "Waktu penciptaan",
      updateBy: "pembaru",
      updateTime: "Perbarui waktu",
      selectDate: "Pilih tanggal",
      cancel: "Membatalkan",
      confirm: "Tentu",
      search: "mencari",
      reset: "mengatur ulang",
      delete: "menghapus",
    },

    // 预警设置
    warning: {
      content: "Silakan masukkan konten",
      select: "tolong pilih",
      warningTitle: "Judul peringatan",
      isPage: "Paginasi",
      warningSql: "Peringatan sql",
      CustomMenuName: "Nama menu khusus",
      remark: "Komentar",
      showOrder: "urutan tampilan",
      isShow: "bisa dilihat",
      cancel: "penutup",
      confirm: "Tentu",
      add: "Baru",
      edit: "sunting",
      batchDeletes: "penghapusan batch",
      delete: "menghapus",
      languageSet: "pengaturan bahasa",
      warningFieldSet: "Pengaturan bidang peringatan",
      tip1: "Silakan pilih data yang ingin Anda hapus",
      tip2: "tambahkanSukses",
      warningName: "Nama peringatan",
      emptyContent: "Silakan klik nama laporan di sebelah kiri untuk memilih laporan",
    },

    // 预警设置---预警字段设置
    warnFieldSet: {
      databaseName: "Nama bidang basis data",
      search: "Menemukan",
      fieldShowOrder: "Urutan tampilan lapangan",
      datebaseField: "Nama bidang basis data",
      showName: "Nama tampilan bagian depan",
      dataType: "jenis data",
      select: "tolong pilih",
      alignType: "Penyelarasan",
      isSelect: "Menanyakan",
      QueryOperator: "operator kueri",
      isLock: "mengunci",
      datePrecision: "ketepatan tanggal",
      isShow: "bisa dilihat",
      cancel: "Membatalkan",
      confirm: "Tentu",
      add: "Baru",
      edit: "sunting",
      languageSet: "pengaturan bahasa",
      tip1: "Silakan pilih data yang ingin Anda hapus",
      tip2: "berhasil ditambahkan",
    },


    // 流程模型
    flowModel: {
      name: "nama",
      search: "mencari",
      reset: "mengatur ulang",
      add: "Baru",
      flowKey: "Kunci Proses",
      version: "Versi: kapan",
      createTime: "Waktu penciptaan",
      updateTime: "Perbarui waktu",
      metadata: "metadata",
      edit: "sunting",
      delete: "menghapus",
      upload: "menyebarkan",
      download: "Ekspor",
      downLoad: "unduh",
      addFlowModel: "Tambahkan model proses",
      category: "Klasifikasi",
      desc: "menggambarkan",
    },

    // 流程定义
    flowDefinition: {
      operation: "beroperasi",
      moduleId: "id modul",
      moduleName: "nama modul",
      programmeId: "Id skema",
      programmeName: "Nama skema",
      processDefinitionKey: "Kunci instans proses",
      remark: "Komentar",
      tip4: "Apakah Anda yakin untuk menghapus modul yang dicentang?",
      tip5: "Silakan pilih sebelum menghapus",
      tip6: "Berhasil disimpan",
      save: "menyimpan",
      active: "pengaktifan",
      hangUp: "menggantung",
      transferModel: "Perpindahan model",
      delete: "menghapus",
      upload: "menyebarkan",
      category: "Klasifikasi",
      select: "tolong pilih",
      selectFile: "Pilih dokumen",
      clickUpload: "Klik untuk mengunggah",
      cancel: "Membatalkan",
      confirm: "Tentu",
      flowChart: "flow chart",
      flowKey: "Kunci Proses",
      flowName: "Nama proses",
      search: "mencari",
      reset: "mengatur ulang",
      flowId: "ID Proses",
      version: "Versi: kapan",
      flowDesc: "Deskripsi proses",
      uploadTime: "Waktu penerapan",
      flowDefinition: "definisi proses",
      deploymentProcessDefinition: "Definisi proses penerapan",
      flowDefinitionStatus: "status definisi proses",
      edit: "sunting",
      delete: "menghapus",
      actived: "diaktifkan",
      tip1: "Apakah Anda yakin akan menghapus proses ini?",
      tip: "petunjuk",
      confirm: "Tentu",
      cancel: "Membatalkan",
      tip2: "berhasil dihapus",
      tip3: "Silakan pilih kategori sebelum mengirimkan",
      add: "Baru",
      selectModule: "Pertama pilih nama modul",
      selectProgramme: "Pilih nama paket terlebih dahulu"

    },

    // 审批方案
    programmeSet: {
      name: "nama",
      search: "mencari",
      reset: "mengatur ulang",
      jointTrial: "Apakah akan ada persidangan",
      add: "Baru",
      groups: "Kelompok persetujuan",
      Appoint: "Tunjuk pemberi persetujuan",
      nodeSetTip: "Silakan pilih node persetujuan",
      nodeSet: "Pengaturan simpul persetujuan",
      programmeSetAdd: "Tambahkan rencana baru",
      programmeSetEdit: "Sunting rencana",
      programmeSetName: "Nama skema",
      programmeSetCode: "Nomor paket",
      approvalNode: "Node persetujuan",
      version: "Versi: kapan",
      createTime: "Waktu penciptaan",
      updateTime: "Perbarui waktu",
      metadata: "metadata",
      edit: "sunting",
      delete: "menghapus",
      upload: "menyebarkan",
      download: "Ekspor",
      addFlowModel: "Tambahkan",
      category: "Klasifikasi",
      desc: "menggambarkan",
    },


    // 审批
    Approval: {
      normal: "normal",
      remove: "menghapus",
      auditor: "pengulas",
      backTo: "Kembalikan simpul",
      tipNode: "Jika tidak dipilih, maka akan dikembalikan ke pengirim secara default!",
      operation: "beroperasi",
      agree: "setuju",
      reject: "Menolak",
      history: "sejarah",
      schedule: "jadwal",
      view: "Memeriksa",
      confirm: "Tentu",
      cancel: "Membatalkan",
      approvalOption: "Komentar persetujuan",
      approvalProcess: "Proses persetujuan",
      approvalHistory: "Sejarah persetujuan",
      approveParam: "Parameter persetujuan",
      scheduleChart: "grafik kemajuan",
      initiator: "sponsor",
      moduleName: "nama modul",
      search: "mencari",
      reset: "mengatur ulang",
      refresh: "menyegarkan",
      approvalAgree: "Disetujui",
      approvalReject: "Persetujuan ditolak",
      approvalTime: "Waktu pengerjaan",
      // assignee: "ditugaskan untuk",
      approvalParam: "Parameter persetujuan",
      taskName: "nama misi",
      assignee: "Pemberi persetujuan",
      startTime: "Waktu mulai",
      endTime: "Akhir waktu",
      durationTime: "memakan waktu",
      refreshSuccess: "Penyegaran berhasil",
      refressFail: "Penyegaran gagal",
      tip1: "Silakan centang kotak sebelum menyetujui!",
      tip2: "Silakan periksa sebelum melanjutkan operasi penolakan!",
      viewInvoices: "Memeriksa",
      print: "Mencetak",
      approvalStatus: "status persetujuan",
      tip44: "Silakan isi komentar persetujuan",
      initiationDate: "Tanggal peluncuran",
      fno: "Nomor dokumen",
      save: "menyimpan",
      status: "negara",
      billStatus: "negara",
    },

    // 订单进度管理
    Order: {
      orderProgressTable: "jadwal pemesanan",
      progress: "jadwal",
      operation: "beroperasi",
      viewDetail: "periksa detailnya",
      orderProgressChart: "Bagan kemajuan pesanan",
      contractNumber: "Nomor Kontrak",
      contractDate: "tanggal kontrak",
      customerName: "nama klien",
      search: "mencari",
      reset: "mengatur ulang",
      refresh: "menyegarkan",
      deliveryDate: "tanggal pengiriman",
      destinationPort: "Tujuan",
      shipmentPort: "pelabuhan pengiriman",
      createBy: "pengawas",
      progress: "jadwal",
      addNode: "Tambahkan simpul baru",
      confirmNode: "Konfirmasikan simpul",
      linkName: "Nama tautan",
      computeMode: "Perhitungan",
      basisNode: "Menurut simpul",
      Days: "hari yang diperlukan",
      createDate: "Tanggal pembuatan",
      chargePersion: "Bertanggung jawab",
      status: "negara",
      edit: "sunting",
      delete: "menghapus",
      selectNode: "Pilih simpul",
      confirm: "mengonfirmasi",
      cancel: "Membatalkan",
      sendSuccess: "Berhasil dikirim",
      addNodes: "Tambahkan perpustakaan simpul",
      nodeName: "Nama simpul",
      nodeEndCondition: "kondisi akhir simpul",
      nodeEndConditionSql: "Pernyataan kondisi akhir simpul",
      nodesName: "Nama perpustakaan simpul",
      createByName: "Nama pencipta",
      createTime: "Waktu penciptaan",
      tip1: "Apakah Anda yakin akan menghapus simpul ini?",
      tip: "petunjuk",
      saveSuccess: "Berhasil disimpan",
    },

    /* 左侧菜单 */
    Menu: {
      messageCenter: "Pusat pesan",
      nodeSettings: "Pengaturan simpul",
      businessFlowTracking: "Pelacakan aliran bisnis",
      SystemAdmin: "Manajemen Sistem",
      ListSet: "Pengaturan daftar",
      FetchSet: "Ambil pengaturan",
      HomeSet: "Pengaturan halaman beranda",
      home: "halaman Depan",
      ReportPermissSet: "Laporkan pengaturan izin",
      dataDict: "Kamus data",
      category: "Kamus klasifikasi",
      menuAdmin: "Manajemen menu",
      userAdmin: "manajemen pengguna",
      userOnline: "pengguna daring",
      roleAdmin: "manajemen peran",
      deptAdmin: "Manajemen departemen",
      printAdmin: "Manajemen pencetakan",
      systemLog: "Catatan sistem",
      termsTemplete: "Templat ketentuan",
      warningSet: "Pengaturan peringatan",
      fieldSet: "Pengaturan lapangan",
      dataDictSet: "Pengaturan item kamus data",
      fetchFieldSet: "Ambil pengaturan bidang",
      sheetSet: "pengaturan halaman lembar",
      formSet: "Pengaturan formulir",
      codeSet: "pengaturan pengkodean",
      editUser: "Sunting pengguna",
      languageSet: "pengaturan bahasa",
      versionView: "Tampilan versi",
      persionCenter: "Pusat pribadi",
      warnPermissSet: "Pengaturan izin peringatan",
      warning: "peringatan dini",
      warningFieldSet: "Pengaturan bidang peringatan",
      businessModel: "pemodelan bisnis",
      systemSet: "Pengaturan sistem",
      logoSet: "Pengaturan logo",
      loginSet: "Pengaturan latar belakang masuk",

      // 流程菜单
      flowMenu: "Menu proses",
      flowDefinition: "definisi proses",
      flowConfig: "Konfigurasi proses",
      flowModel: "model proses",
      modelOnlineDesign: "Proses desain",
      addFlowConfig: "Tambahkan konfigurasi proses",
      editFlowConfig: "Edit konfigurasi proses",
      programmeSet: "Pengaturan solusi",
      programmeSetAdd: "Tambahkan rencana baru",
      programmeSetEdit: "Sunting rencana",

      // 审批管理
      approvalAdmin: "Manajemen persetujuan",
      peddingApproval: "Tertunda",
      approval: "Disetujui",
      Initiated: "Dimulai",
      CCRecord: "catatan CC",

      // 订单进度管理
      orderProgressAdmin: "Manajemen kemajuan pesanan",
      orderProgressList: "Daftar kemajuan pesanan",
      orderNodes: "Memesan perpustakaan simpul",
      orderProgressDetail: "Detail kemajuan pesanan",
      statisticsReport: "Laporan statistik",

      // 代码事件配置
      codeEventSet: "Konfigurasi peristiwa kode",

    },
    // 嵌套BI系统
    Bisystem: {
      BiName: "D"
    },
    StatisticalManagement: {
      StatisticalManagement: "Manajemen statistik",
    },
    // 邮件模块
    Emails: {
      edit: "sunting",
      sign: "Pengaturan tanda tangan",
      folders: "Pengaturan folder",
      eName: "Sistem surat",
      write: "menulis email",
      contact: "Buku alamat",
      inbox: "kotak masuk",
      outbox: "Kotak keluar",
      drafts: "kotak rancangan",
      trash: "tempat sampah",
      folders: "map",
      searchFolder: "Folder pencarian",
      label: "label surat",
      settings: "Pengaturan email",
      please: "silakan masuk",
      login: "Gabung",
      loginsuccess: "login berhasil",
      loginfailed: "Gagal masuk",
      selectAccount: "Pilih akun",
      signContent: "Konten tanda tangan",

      account: "akun",
      password: "kata sandi",
      notnull: "Akun atau kata sandi wajib diisi",
      cancel: "Membatalkan",
      go: "Tentu",
      register: "daftar",
      regSuccess: "registrasi berhasil",
      khname: "nama perusahaan",
      copy: "CC",
      secret: "SM",
      addcopy: "tambahkan cc",
      addsecret: "Tambahkan BCC",
      delcopy: "Hapus CC",
      delsecret: "Hapus BCC",
      addressee: "penerima",
      sendName: "Nama pengirim",

      copyName: "CC",
      linkTips: "Jika belum ada kontak, silakan buka buku alamat untuk menambahkannya atau langsung masukkan.",
      theme: "tema",
      upload: "Unggah Lampiran",
      clearFiles: "bersihkan daftarnya",
      numsTips: "Jumlah lampiran yang dipilih saat ini:",
      sizeTips: "Ukuran total daftar lampiran adalah:",
      readReceipt: "Baca tanda terima",
      Sender: "pengirim",
      pickSender: "Silakan pilih pengirim",
      send: "mengirim",
      save: "menyimpan konsep",
      reset: "mengatur ulang",
      addresseeEmpty: "Penerima tidak boleh kosong",
      themeEmpty: "Topik tidak boleh kosong",
      senderEmpty: "Pengirim tidak boleh kosong",
      senderNull: "Belum ada pengirimnya, silakan masuk ke pengaturan email untuk menambahkannya.",
      textEmpty: "Konten pengiriman tidak boleh kosong",
      sendSuccess: "Berhasil dikirim",
      sendFail: "gagal mengirim",
      saveSuccess: "Draf berhasil disimpan",
      saveFail: "Gagal menyimpan draf",
      refresh: "menyegarkan",
      themeKeyword: "Kata kunci topik",
      contentKeyword: "Kata kunci konten",
      senderKeyword: "Pilih pengirim",
      getAll: "Pilih Semua",
      clearAll: "batalkan pilihan semua",
      oneByone: "Jumlah tunggal yang signifikan",
      generatedBy: "AI menulis surat",
      languageSet: "Bahasa sasaran",
      transLate: "menerjemahkan",
      promotional: "Surat pengembangan",
      exampleContent: "Contoh: Tolong buatkan saya surat pengembangan perdagangan luar negeri untuk pelanggan di industri ritel Nama saya Zhang San, dari perusahaan xxx.",
      addMails: "Masukkan ke email",

      readStatus: "status membaca",
      all: "semua",
      theLastDay: "hari terakhir",
      lastThreeDays: "tiga hari terakhir",
      lastSevenDays: "tujuh hari terakhir",
      lastThirtyDays: "30 hari terakhir",
      lastNinetyDays: "90 hari terakhir",
      lastOneHundredEightyDays: "180 hari terakhir",
      unread: "belum dibaca",
      read: "Telah membaca",
      removed: "dihapus",
      receive: "mengambil alih",
      delete: "menghapus",
      emailNums: "Jumlah total pesan",
      emailUnread: "surat yang belum dibaca",
      receiving: "Menerima...",
      receiveFail: "Penerimaan gagal",
      receiveSuccess: "Berhasil diterima",
      emailDetails: "Klik untuk melihat rincian email",
      delTips: "Silakan pilih sebelum menghapus",
      delSuccess: "berhasil dihapus",
      delFail: "gagal dihapus",
      contactsList: "Daftar kontak",
      contactsManage: "Manajemen kontak",
      categoryManage: "Manajemen kategori",
      categoryName: "nama klasifikasi",
      customerName: "nama klien",
      name: "Nama",
      customerCode: "Kode pelanggan",
      email: "Surat",
      operation: "beroperasi",
      categoryParent: "Sub-judul",
      phone: "Telepon",
      reply: "membalas",
      replyAll: "Membalas semua",
      date: "waktu",
      saving: "menyimpan",
      pickCategory: "Pilih Kategori",
      edit: "Merevisi",
      cateTips: "Tolong pilih satu",
      chooseAnItem: "Hanya satu opsi yang dapat dipilih",
      writeCategoryName: "Silakan isi nama kategori",
      nameMailTips: "Silakan lengkapi nama dan alamat email Anda",
      recoverySuccess: "Pemulihan berhasil",
      recoveryFail: "Pemulihan gagal",
      newFolder: "Tambahkan folder baru",
      editFolder: "Sunting map",

      addFolderTips: "Silakan masukkan nama folder",
      folderName: "Nama folder",
      addSuccess: "berhasil ditambahkan",
      addFail: "Gagal menambahkan",
      emailSetting: "A",
      signSetting: "Pengaturan tanda tangan",
      folderSetting: "Pengaturan folder",
      displayName: "nama tampilan",
      senderName: "Nama pengirim",
      emailAddress: "alamat email",
      required: "Silakan isi",
      addSetting: "Tambahkan pengaturan baru",
      requiredSelect: "tolong pilih",
      selectMoveWarning: "Silakan pilih pesan yang akan dipindahkan",
      moveSuccess: "Berhasil dipindahkan",
      maveFailed: "Pemindahan gagal",
      sendingServer: "server pengirim",
      Authorization: "Kata sandi otorisasi",
      sendserverType: "Jenis server (terima)",
      serverAddress: "alamat server",
      sendserverAddress: "Alamat server (pengiriman)",
      isDefault: "Apakah ini defaultnya",
      enable: "Apakah akan mengaktifkan",
      port: "pelabuhan",
      sendTime: "Kirim waktu",
      originalMail: "Pesan aslinya",
      receiveAll: "Tagih semua akun",
      receiveMailPick: "Silakan pilih akun yang akan ditagih",
      receiveMailTime: "Silakan pilih jangka waktu yang ingin Anda tagih",
      receiveEmail: "Opsi pengisian daya",
      emailNums: "Jumlah pesan",
      relatedModules: "Modul terkait",
      moduleName: "nama modul",
      fno: "Nomor Dokumen",
      selectReportFile: "Pilih lampiran laporan",
      uploaded: "Lampiran yang diunggah",
      uploadSuccess: "Lampiran laporan berhasil diunggah",
      uploadFailed: "Gagal mengunggah lampiran laporan",
      selectModelName: "Silakan pilih nama modul",
      selectPrintFormat: "Silakan pilih format cetak",
      readTime: "waktu membaca",
      readAddress: "daerah",
      readCount: "berapa kali dibaca",
      readCount: "berapa kali dibaca",
      markAllAsRead: "tandai semua telah dibaca",
      markSelectedAsRead: "Tanda centang sebagai telah dibaca",
      markAllAsReadTip: "Apakah Anda yakin ingin menandai semua pesan sebagai telah dibaca?",
      markSelectedAsReadtip: "Apakah Anda yakin ingin menandai email yang dipilih sebagai telah dibaca?",
      markedSuccess: "Tandai berhasil",
      markedFail: "Penandaan gagal",
      moveto: "Pindah ke",
      emailForward: "Maju",
      emailTemplate: "Templat email",
      addTemplate: "Tambahkan templat",
      EditTemplate: "Sunting templat",

      restore: "Apakah Anda yakin ingin memulihkan pesan ini?",
      emailSet: "Konfigurasi email",
      noAccount: "Anda belum mengkonfigurasi akun email",
      goToSet: "Pergi ke pengaturan",
      singleForwarding: "Hanya penerusan satu item yang didukung",
      attachments: "lampiran",
      download: "unduh",
      cancel: "penutup",
      selectTheMailTemplate: "Pilih templat email",
      name: "nama",
      defaultExpression: "Emotikon bawaan",
      receivingAccount: "Silakan pilih akun penerima",
      pleaseSelectAnAccount: "Silakan pilih akun",
      pleaseEnterYourSignature: "Silakan masukkan konten tanda tangan",
      pleaseFillInTheName: "Silakan isi namanya",
      pleaseFillInTheContent: "Silakan isi isinya",
      targetLanguage: "Silakan pilih bahasa target",
      english: "Bahasa inggris",
      french: "Perancis",
      spanish: "Orang Spanyol",
      japanese: "Jepang",
      korean: "Korea",
      burmese: "Birma",
      thai: "Thai",
      russian: "Rusia",
      servicesProvidedByOpenai: "Dilayani oleh openai",
    },
    Marketing: {
      explosibility: "Jumlah total yang dapat dikeluarkan",
      alreadySent: "Jumlah total yang dikeluarkan",
      remaining: "Sisanya bisa dikirim",
      screening: "Saring",
      reset: "mengatur ulang",
      sendEmail: "mengirim email",
      pleaseEnterSendEmail: "Silakan masuk untuk mengirim email",
      missionDataOverview: "Ikhtisar data misi",
      marketingNumber: "Nomor Pemasaran",
      sendingNumber: "Jumlah orang yang dikirim",
      deliveryNumber: "Jumlah orang yang dilayani",
      unpackNumber: "Buka jumlah orang",
      recoverNumber: "Jumlah balasan",
      taskName: "nama misi",
      add: "Baru",
      remove: "menghapus",
      selected: "terpilih",
      PCS: "individu",
      underWay: "sedang berlangsung",
      complete: "Menyelesaikan",
      creationTime: "Waktu penciptaan",
      startSending: "Mulai mengirim",
      sendComplete: "Pengiriman selesai",
      lastUpdated: "pembaruan terbaru",
      taskDeletion: "Silakan pilih tugas yang akan dihapus!",
      isDeleteCheck: "Hapus data yang dipilih?",
      tip: "petunjuk",
      senderSettings: "Pengaturan pengiriman",
      contentEditing: "mengedit konten",
      sendSetting: "Kirim pengaturan",
      taskType: "Jenis tugas",
      developmentLetter: "Surat pengembangan",
      companyIntroduction: "Profil Perusahaan",
      productIntroduction: "Deskripsi Produk",
      longTermConnection: "kontak jangka panjang",
      seasonsGreetings: "salam liburan",
      other: "lainnya",
      senderNickname: "Nama panggilan pengirim",
      sendingAddress: "alamat pengirim",
      sendingType: "Jenis kirim",
      none: "tidak ada",
      sendImmediately: "kirim segera",
      automatedMarketing: "Pemasaran otomatis",
      replyEmail: "Balasan Surat",
      correctEmail: "email yang benar",
      type: "jenis",
      addressee: "penerima",
      replyStopSending: "Balasan berhenti mengirim",
      readStoppedSending: "Baca berhenti mengirim",
      pleaseEnter: "silakan masuk",
      pleaseSelect: "tolong pilih",
      select: "memilih",
      optional: "Opsional",
      confirm: "mengonfirmasi",
      cancel: "Membatalkan",
      lastStep: "Sebelumnya",
      nextStep: "Langkah berikutnya",
      immediatelyEnable: "Sekarang aktif",
      emailSubject: "Subjek Email",
      outgoingInterval: "interval pengiriman",
      subjectName: "Nama topik",
      theme: "tema",
      controls: "beroperasi",
      editor: "sunting",
      successfulOperation: "Operasi yang berhasil",
      successfulReplication: "Berhasil disalin",
      operationFailure: "operasi gagal",
      confirmToDelete: "konfirmasi untuk menghapus?",
      mailSubjectName: "Nama subjek email",
      insertVariable: "masukkan variabel",
      emailContent: "isi email",
      uploadAttachment: "Unggah Lampiran",
      commodityList: "Daftar Produk",
      templateSelection: "Pemilihan templat",
      AILetterWriting: "huruf AI",
      AIWrite: "AI menulis surat",
      variable: "variabel",
      toggle: "mengalihkan",
      for: "untuk",
      language: "bahasa",
      english: "Bahasa inggris",
      french: "Perancis",
      spanish: "Orang Spanyol",
      japanese: "Jepang",
      korean: "Korea",
      burmese: "Birma",
      thai: "Thai",
      russian: "Rusia",
      day: "langit",
      remark: "Komentar",
      isUnsubscribe: "Apakah akan menyertakan akhiran berhenti berlangganan",
      companyName: "Nama perusahaan",
      languageUsed: "Bahasa keluaran email",
      productName: "nama Produk",
      toneOfMail: "Nada email",
      business: "bisnis",
      moreFormal: "lebih formal",
      politeness: "sopan",
      formal: "resmi",
      festivalName: "nama liburan",
      wordLimit: "Batas kata",
      unlimitedWordCount: "Tidak ada batasan kata",
      intraWord: "Dalam kata-kata",
      generalDescription: "Gambaran umum",
      send: "mengirim",
      insertIntoAMessage: "masukkan ke dalam pesan",
      copyContent: "Salin konten",
      examples: "Contoh: Tolong buatkan saya surat pengembangan perdagangan luar negeri untuk pelanggan di industri ritel Nama saya Zhang San, dari perusahaan xxx.",
      product: "barang dagangan",
      templateName: "Nama templat",
      hostedDataOverview: "Ikhtisar data yang dihosting",
      mailUserGrouping: "Grup pengguna email",
      addGroup: "Buat grup baru",
      deleteGroup: "Hapus grup",
      serialNumber: "nomor seri",
      userGroup: "grup pengguna",
      creationDate: "Tanggal pembuatan",
      instructions: "menjelaskan",
      name: "nama",
      mailbox: "Surat",
      groupName: "Nama grup",
      userMailbox: "email pengguna",
      userGroupName: "Nama grup pengguna",
      refresh: "menyegarkan",
      deletingUserGroup: "Silakan pilih grup pengguna yang akan dihapus!",
      isCheckDeleteUserGroup: "Apakah Anda yakin ingin menghapus grup pengguna yang dipilih?",
      successfullyDeleted: "berhasil dihapus!",
      saveSuccessfully: "Berhasil disimpan!",
      success: "kesuksesan",
      search: "mencari",
      personalTemplate: "Templat pribadi",
      mailTemplateName: "Nama templat email",
      privatelyOwned: "pribadi",
      publicity: "publik",
      isPublicTemplate: "Apakah akan membuat template menjadi publik",
      templateTopic: "Tema templat",
      templateContent: "Konten templat",
      templateRemarks: "Komentar templat",
      saveTemplate: "Simpan templat",
      NOTranslationPicture: "Konten yang mengandung gambar tidak dapat diterjemahkan!",
      marketingTask: "Tugas pemasaran",
      setbasicInformation: "Tetapkan informasi dasar",
      submitScheme: "Kirim dan konfirmasikan rencananya",
      trusteeshipScheme: "Paket yang memerlukan hosting",
      view: "Memeriksa",
      edit: "Merevisi",
      addCustomScheme: "Buat rencana khusus baru",
      outgoingMailbox: "Mengirim email",
      replyEmail: "Membalas email",
      recipientEmailAddress: "Alamat email penerima",
      industry: "Industri",
      textile: "tekstil",
      homeFurnishings: "Rumah",
      mailLanguage: "Bahasa email",
      confirmationMarketingLetter: "Mohon konfirmasi surat pemasaran",
      tip1001: "Melaksanakan pengembangan pelanggan melalui beberapa putaran pemasaran, dengan setiap putaran berfokus pada fokus yang berbeda dan secara otomatis mengirimkan email secara berkala ke penerima yang tidak membalas.",
      startCircularMarketing: "Mulai pemasaran melingkar",
      change: "Ubahlah",
      addScheme: "Buat rencana baru",
      schemeName: "Nama skema",
      templateLibrary: "Perpustakaan templat",
      generateTemplate: "Hasilkan templat",
      intervalTime: "Interval",
      triggerCondition: "Kondisi pemicu",
      NoReplyYet: "Tidak terjawab",
      unread: "belum dibaca",
      translation: "Terjemahkan ke dalam bahasa target",
      description: "menggambarkan",
      save: "menyimpan",
      isGenerateRemplate: "Hasilkan templat?",
      addSuccessfully: "Berhasil ditambahkan",
      generateNewScheme: "Apakah rencana baru akan dibuat setelah konten yang dimodifikasi diaktifkan?",
      mailTemplate: "Templat email",
      addTemplate: "Tambahkan templat",
      updateTime: "Perbarui waktu",
      personalTemplate: "Templat pribadi",
      commonTemplate: "templat publik",
    }
  }
