<template>
  <div>
    <el-date-picker
      v-model="value"
      :type="type"
      :picker-options="options"
     :range-separator="$t('placeHolder.to')"
      :start-placeholder="this.placeholderspan + $t('placeHolder.start')"
      :end-placeholder="this.placeholderspan + $t('placeHolder.end')"
      size="mini"
      v-on="$listeners"
      @change="datechange"
      style="height: 100%;"
    >
    </el-date-picker>
    <!-- <el-date-picker
      :value="value"
      :type="type"
     :range-separator="$t('placeHolder.to')"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="timestamp"
      @change="abc"
    >
    </el-date-picker> -->
  </div>
</template>

<script>
import { switchTime } from "@/utils/methods";
export default {
  name: "Y-date",
  props: {
    type: {
      type: String,
      default: "daterange",
    },
    placeholder: String,
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valuelist: '',
      placeholderspan: ""
    };
  },
  created() {
    // console.log(this.placeholder)
    if (this.placeholder === "" || this.placeholder === null || this.placeholder === undefined) {
      this.placeholderspan =this.$t('homeSet.inputPlaceHolder')
    } else {
      this.placeholderspan = this.placeholder
    }
  },
  watch: {
    // value: {
    //   handler (val) {
    //     this.valuelist = val
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  computed: {
    options() {
      return {
        onPick: ({ maxDate, minDate }) => {
           if (maxDate && minDate) {
            if (this.type === "daterange") {
              const _maxDate = switchTime(new Date(maxDate).getTime());
              const _minDate = switchTime(new Date(minDate).getTime());
              this.$emit("update:value", [_minDate, _maxDate]);
            }
          }
        },
        handleClear: () => {
          this.$emit("update:value", []);
        },
      };
    },
  },
  methods: {
    datechange (val) {
      // this.valuelist = val
       this.$emit("update:value", val)
    }
  },
  // created () {
  //   console.log(this.value, "valuevaluevaluevalue")
  //   this.valuelist = this.value
  // },
  mounted(){
    this.$emit('mounted')
  }
};
</script>

<style scoped lang='scss'>
/deep/.el-range-separator {
  display: flex;
  align-items: center;
}
</style>
