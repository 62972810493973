<template>
  <div class="inputs">
    <div 
      v-for="(item, index) in inputs" 
      style="margin: 5px 10px 0px 0;" 
      :key="`inputs${index}`"
      class="yinput"
    >
      <Y-input
        v-if="(inputType[item[itemTypeKey]] || !item[itemTypeKey]) && item.fieldName != 'fsaved'"
        :input.sync="inputsData[item[keyEn]]"
        :placeholder="item[keyCh] || $t('LanguageSet.content')"
      ></Y-input>
      <Y-select
        v-if="item.fieldName == 'fsaved'"
        :value.sync="inputsData[item[keyEn]]"
        :options="listOptions"
        :placeholder="item[keyCh] || $t('LanguageSet.content')"
      ></Y-select>
      <Y-select
        v-if="item[itemTypeKey] === 'select' && item.fieldName != 'fsaved'"
        :value.sync="inputsData[item[keyEn]]"
        :options="item.options"
        :placeholder="item[keyCh] || $t('LanguageSet.content')"
      ></Y-select>
      <Y-date
        v-if="item[itemTypeKey] === 'date'"
        :value.sync="inputsData[item[keyEn]]"
        :placeholder="item[keyCh] || $t('LanguageSet.content')"
      ></Y-date>
      <Y-numberInput
        v-if="item[itemTypeKey] === 'number' || item[itemTypeKey] === 'decimal'"
        :input.sync="inputsData[item[keyEn]]"
        :placeholder="item[keyCh] || $t('LanguageSet.content')"
        :filterType="item.filterType"
      ></Y-numberInput>
    </div>
  </div>
</template>

<script>
export default {
  name: "Y-inputs",
  props: {
    inputs: {
      type: Array,
      default: () => [],
    },
    keyEn: {
      type: String,
      default: "keyEn",
    },
    keyCh: {
      type: String,
      default: "keyCh",
    },
    itemTypeKey: {
      type: String,
      default: "type",
    },
  },
  data() {
    return {
      inputsData: {},
      listOptions: [
        {
          label: this.$t('addTips.hasPigeonhole'),
          value: '1'
        },
        {
          label:this.$t('addTips.noPigeonhole'),
          value: '0'
        },
        {
          label:this.$t('addTips.all'),
          value: ' '
        }
      ]
    };
  },
  created() {
    // console.log("搜索条件数据", this.inputs)
  },
  computed: {
    inputType() {
      return {
        text: true,
        password: true,
        textarea: true,
        url: true,
        email: true,
        date: false,
        number: true,
        tel: true,
        number: false,
        decimal: false,
        select: false,
      };
    },
  },
  // watch: {
  //   "$attrs.inputsVal": {
  //     handler(a) {
  //       console.log(a);
  //       if (!a) return;
  //       this.inputsData =Object.assign({},a) ;
  //       // this.$set(this.inputsData,a.inputskey,a.inputsData)
  //     },
  //    },
  // },
  methods: {
    clearInputData(){
      this.inputsData = {}
    }
  }
};
</script>

<style scoped lang="scss">
.inputs {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-input {
  width: 160px;
  // margin-right: 10px;
}
/deep/ .el-select {
  width: 160px;
  // margin-right: 10px;
}
/deep/ .el-input-number--mini {
  width: 160px;
}
</style>
