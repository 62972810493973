<template>
  <div>
    <el-table
      v-loading="loading"
      ref="Ytable"
      :data="tableDataList"
      border
      size="mini"
      v-bind="$attrs"
      :style="[{width: '100%'},{fontSize:(fonts? fonts:'12px')}]"
      v-on="$listeners"
      :default-expand-all="expendAll"
      lazy
      :row-key="rowKey"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      :max-height="maxHeight"
      :height="height"
      highlight-current-row
      @selection-change="selectionChage"
      @select="handleSelectChange"
      @current-change="currentChange"
      @row-dblclick="(row, column, event) => rowDbClick(row, column, event)"
      @row-click="(row, column, event) => rowClick(row, column, event)"
      @sort-change="sortChange"
      @cell-click="cellClick"
      @keydown.tab.native="handleTab(noShowName)"
      @header-dragend='changeheight'
      @select-all="selectAllData"
      @cell-mouse-enter="cellMouseEnter"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      :header-cell-class-name="cellClass"
    >
    <!-- @keydown.native="(key) => keydownFunction(key, column)" -->
      <!-- :show-summary="showSummary" -->
      <!-- :summary-method="getSummaries" -->

      <!-- <el-table-column type="expand">
        <template>
          <Y-table :column="[]" :tableData="[]" v-if=""/>
        </template>
      </el-table-column> -->

    <!-- <el-table-column type="expand" width="1" v-show="false">
      <template slot-scope="props">

        <el-table
            :data="props.row.subtotal"
            border
            :show-header="false"
            style="width: 100%">
            <el-table-column
                type="selection"
                width="40"
                align="center"
                fixed="left"
                :reserve-selection="true"
              />
              <el-table-column
                type="index"
                width="30"
                align="center"
                fixed="left"
              ></el-table-column>
              <el-table-column
                v-for="(val,index) in columnList"
                :key="index"
                :prop="val[columnKey]"
                :label="val[columnLabel]"
                :resizable="val.resizable || true"
                :fixed="val.fixed || val.isLocked"
                align="left"
                :min-width="val.width === 'auto' ? '' : val.width || val.colWidth || 120"
                :sortable="'custom'"
                :formatter="val.formatter"
                :show-overflow-tooltip="val.fieldName != 'img_ids'"

              ></el-table-column>
          </el-table>
      </template>
    </el-table-column> -->

      <el-table-column
        type="selection"
        width="60"
        align="center"
        fixed="left"
        :reserve-selection="true"
      />
      <!-- v-if="selection" -->
      <!-- label="序号" -->
      <el-table-column
        :label="$t('configMoudle.linenum')"
        type="index"
        width="60"
        align="center"
        fixed="left"
        header-align="center"
        sortable
      ></el-table-column>
      <!-- v-if="index" -->

        <!-- v-show="val.showName !== '' && val.showName !== null && val.showName !== undefined" -->
        <!-- val[alingitem] == 1 ? 'left' : (val[alingitem] == 2 ? 'center' : 'right') -->
      <el-table-column
        v-for="(val, index) in columnList"
        :key="index"
        :prop="val[columnKey]"
        :label="val[columnLabel]"
        :resizable="val.resizable || true"
        :fixed="val.fixed || val.isLocked"
        :align="val[alingitem] == 1 ? 'left' : (val[alingitem] == 2 ? 'center' : (val[alingitem] == 3 ? 'right' : 'left'))"
        header-align="center"
        :min-width="val.width === 'auto' ? '' : val.width || val.colWidth || 120"
        :sortable="'custom'"
        :formatter="val.formatter"
        sortable
        :show-overflow-tooltip="val.fieldName != 'img_ids'"
      >
       <!-- sortable >>>> && val.prop !== 'roleName' -->
        <!-- <template #header="{column}">
          <span>
            <span>{{column.label}}</span>
             <el-checkbox title="合计"></el-checkbox>
            <button @click="aaa(scope)">按钮</button>
          </span>
        </template> -->

        <!-- 表头批量修改图标 -->
        <template #header="{ column }">
          {{ column.label }}
          <br v-if="headerbe" />
          <el-popover placement="top" width="400" trigger="click">
            <el-input
              :placeholder="$t('configMoudle.content')"
              v-model="val.inputSearch"
              @keyup.enter.native="handleSearch(column)"
              size="mini"
              clearable
            >
            </el-input>
            <i
              v-if="isColumnFilter"
              style="cursor: pointer"
              class="el-icon-search"
              @click.stop="handleHeaderSearch(column, index, val)"
              slot="reference"
            ></i>
          </el-popover>
          <i
            style="cursor: pointer"
            class="el-icon-edit"
            v-if="isBatchModify"
            @click.stop="handleHeaderEdit(column)"
          ></i>
        </template>
        <template #default="scope">
          <!-- 行内编辑显示 -->
          <slot
            v-if="
              (((editCell && !val.isReadOnly) ||
                (val.isEditableUnderApproval &&
                  $route.query.peddingApproval &&
                  !tabItemIsReadOnly)) &&
                val[columnKey] === cellColumn &&
                scope.row.$index === cellRow) ||
              (val[columnKey] === cellColumn &&
                scope.row.$index === cellRow &&
                $route.path == '/CW/sdrl_edit')
            "
            name="myColumn"
            :row="scope.row"
            :column="val"
            :$index="scope.$index"
          ></slot>

          <div
            v-else-if="val.fieldName === 'http'"
            style="text-decoration: underline; color: #551a8b"
          >
            <a
              :href="scope.row[val[columnKey]]"
              target="_Blank"
              @click="handlePreview(scope.row, val)"
              >{{ scope.row[val[columnKey]] | booleanFilter(val, itemsConfigVOs) }}</a
            >
          </div>
          <!-- 图片 -->
          <div
            v-else-if="val.fieldName === 'img_ids'"
            style="height: 100px; width: 100px; margin-right: 10px"
          >
            <img
              style="width: auto; height: auto; max-width: 100%; max-height: 100%"
              v-if="scope.row.img_ids"
              :src="scope.row.img_ids[0].url"
              @click.stop="handleImgClick(scope.row.img_ids[0].url)"
              alt=""
            />
          </div>
          <!-- 表格显示 -->
          <template v-else>
            <span
              v-if="
                ($route.path == '/CW/fksq_view' ||
                  $route.path == '/CW/sfgl_view' ||
                  $route.path == '/CW/sfgl_add' ||
                  $route.path == '/CW/fksq_add' ||
                  $route.path == '/CW/fksq_edit' ||
                  $route.path == '/CW/sfgl_edit') &&
                val.fieldName === 'pono'
              "
              :style="
                $route.path == '/CW/fksq_view' ||
                $route.path == '/CW/sfgl_view' ||
                $route.path == '/CW/sfgl_add' ||
                $route.path == '/CW/fksq_add' ||
                $route.path == '/CW/fksq_edit' ||
                $route.path == '/CW/sfgl_edit'
                  ? 'width: 100%;color:blue;text-decoration:underline'
                  : 'width: 100%;'
              "
            >
              {{ scope.row[val[columnKey]] | booleanFilter(val, itemsConfigVOs) }}
            </span>
            <span
              v-else
              :style="
                scope.row.subtotal
                  ? 'color:red;font-weight:bold;background-color:rgb(192,195,202);width:100%;'
                  : 'width: 100%;'
              "
              v-show="scope.row[val[columnKey]]"
            >
              {{ scope.row[val[columnKey]] | booleanFilter(val, itemsConfigVOs) }}
            </span>
          </template>
        </template>
      </el-table-column>
      <template v-if="(this.$route.path !== '/kcgl/kccx' && !handleDisable) || cwqrisok">
        <slot name="handle">
          <Y-table-Handle
            @handleClickHandle="handleClickHandle"
            @handleExport="handleExport"
            @handleImport="handleImport"
            @handleSettings="handleSettings"
            :isExcelExport="isExcelExport"
            :isExcelImport="isExcelImport"
            :columnSetting="columnSetting"
            :headerbr="headerbe"
          />
        </slot>
      </template>
      <template
        v-if="handleDisable && (handleBtn.length || $route.query.biaoshi) && !cwqrisok"
      >
        <slot name="handle">
          <Y-table-Handle
            :handleDisable="handleDisable"
            @handleClickHandle="handleClickHandle"
            @handleExport="handleExport"
            @handleImport="handleImport"
            @handleSettings="handleSettings"
            :isExcelExport="isExcelExport"
            :isExcelImport="isExcelImport"
            :columnSetting="columnSetting"
            :headerbr="headerbe"
          />
        </slot>
      </template>
    </el-table>
    <el-dialog :visible.sync="imgdivDialogVisible" style="width: 100%">
      <div style="margin: 0 auto; text-align: center">
        <img :src="imgUrl" style="width: 500px; height: auto" alt="" />
      </div>
    </el-dialog>
    <footer v-if="total" style="text-align: end; user-select: none">
      {{ _total }}
    </footer>
  </div>
</template>

<script>
import { isFunction } from "@/utils/validate";
import { findPoctrl } from "@/api/detail";
import Sortable from "sortablejs";
import { getSession, checkFont } from "@/utils/methods";
import clone from "clone";
// import { query } from 'express';
export default {
  name: "Y-table",
  props: {
    cellClassdiabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerbe: {
      type: Boolean,
      default: false,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Array,
      default: () => [],
    },
    itemsConfigVOs: {
      type: Array,
      default: () => [],
    },
    isColumnFilter: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    selection: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Boolean,
      default: true,
    },
    total: {
      type: [Boolean, String],
      default: false,
    },
    totalData: {
      type: Object,
      default: () => {},
    },
    selectionKey: String,
    expendAll: {
      type: Boolean,
      default: false,
    },
    rowKey: {
      type: String,
      default: "id",
    },
    alingitem: {
      type: String,
      default: "",
    },
    columnKey: {
      type: String,
      default: "prop",
    },
    columnLabel: {
      type: String,
      default: "label",
    },
    handleBtn: {
      type: Array,
      default: () => [],
    },
    handleWidth: {
      type: [String, Number],
      default: "170",
    },
    handleDisable: {
      type: Boolean,
      default: false,
    },
    cwqrisok: {
      type: Boolean,
      default: false,
    },
    editCell: Boolean,
    isImage: {
      type: Boolean,
      default: false,
    },
    isExcelExport: {
      type: Boolean,
      default: false,
    },
    isExcelImport: {
      type: Boolean,
      default: false,
    },
    columnSetting: {
      type: Boolean,
      default: false,
    },
    isBatchModify: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [String, Number],
    },
    maxHeight: {
      type: [String, Number],
    },
    tabItemIsReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fonts: null,
      cellColumn: null,
      cellRow: null,
      columnListcount: 0,
      noShowName: [],
      tableDataList: [],
      imgUrl: "",
      imgdivDialogVisible: false,
    };
  },
  computed: {
    // _total() {
    //   if (!this.totalData) return `合计： 无`;
    //   let obj;
    //   Object.keys(this.totalData).map((item) => {
    //     obj = this.column.find((val) => val.keyEn === item);
    //   });
    //   if (!obj) return `合计： 无`;
    //   return `合计： ${obj.keyCh}—${this.totalData[obj.keyEn]}`;
    // },
    columnList() {
      // 不知为啥created里面获取不到noShowName
      this.noShowName = this.column.filter((row) => {
        return (
          row.showName !== "" &&
          row.showName !== null &&
          row.showName !== undefined &&
          row.isShow
        );
      });
      return this.column.filter((item) => {
        return (
          (item.hasOwnProperty("isShow") &&
            item.isShow &&
            item.showName !== "" &&
            item.showName !== null &&
            item.showName !== undefined) ||
          !item.hasOwnProperty("isShow")
        );
      });
    },
  },
  created() {
    this.column.map(async (item) => {
      if (isFunction(item.list)) {
        item.list = await item.list();
      }
    });
    this.column.forEach((item) => this.$set(item, "inputSearch", ""));
    this.noShowName = this.column.filter((row) => {
      return row.showName !== "" && row.showName !== null && row.showName !== undefined;
    });
    this.tableDataList = this.tableData.map((item) => {
      for (let i in item) {
        if (item[i] === "true") {
          item[i] = true;
        } else if (item[i] === "false") {
          item[i] = false;
        }
      }
      return item;
    });
  },
  activated() {},
  deactivated() {
    this.$nextTick(() => {
      let list = document.getElementsByClassName("el-tooltip__popper");
      if(list.length > 0) {
        list.forEach((item) => {
          item.style.display = "none";
        });
      }
    });
  },
  watch: {
    tableData(newVal, oldVal) {
      if (oldVal?.length) return;
      this.$refs.Ytable.setCurrentRow(newVal[0]);
    },
    tableData: {
      handler(newVal) {
        this.tableDataList = newVal.map((item) => {
          for (let i in item) {
            if (item[i] === "true") {
              item[i] = true;
            } else if (item[i] === "false") {
              item[i] = false;
            }
          }
          return item;
        });
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    document.body.ondrop = function (e) {
      e.stopPropagation();
    };
    // this.rowDrop();
    window.addEventListener("resize", this.handleResize);
    // 浏览器tab切换与表格tab切换方法冲突故阻止默认的tab切换
    window.addEventListener("keydown", (e) => {
      if (e.keyCode == 9) e.preventDefault();
    });

    this.fonts = checkFont();
  },
  methods: {
    // 图片悬浮显示
    handleImgClick(url) {
      this.imgdivDialogVisible = true;
      this.imgUrl = url;
    },

    // 表头增加样式
    cellClass(row) {
      if (row.columnIndex === 0 && this.cellClassdiabled) {
        return "disableheadselection";
      }
    },
    //设置分组小计行的样式
    rowClassName({ row, rowIndwx }) {
      let className = "";
      if (row.subtotal) {
        className = "subtotalClass";
      }
      return className;
    },
    // 精准计算加法
    FloatAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
    numberAdd(arg1, arg2) {
      var r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m + arg2 * m) / m).toFixed(n);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = this.$t("configMoudle.totalAll");
          return;
        }
        // subtotal == false过滤掉分组小计行，不参与计算
        const values = data.map((item) =>
          !item.subtotal ? Number(item[column.property]) : 0
        );
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              // return Number(prev) + Number(curr);
              return this.numberAdd(Number(prev), Number(curr));
            } else {
              return Number(prev);
            }
          }, 0);
          if (sums[index] !== "") {
            sums[index] = Number(sums[index]).toLocaleString();
          }
          for (let i = 0; i < this.columnList.length; i++) {
            if (this.columnList[i].showName == column.label) {
              if (this.columnList[i].isSum) {
                if (
                  sums[index] === "" ||
                  sums[index] === null ||
                  sums[index] === undefined
                ) {
                  sums[index] = 0;
                } else {
                  // sums[index] += 0;
                }
              } else {
                sums[index] = "";
              }
            }
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.Ytable.doLayout();
      });
    },
    changeheight() {
      this.$refs.Ytable.doLayout();
    },
    selectAllData(selection) {
      this.$emit("selectAllData", selection);
    },
    cellClick(row, column, cell, event) {
      this.cellRow = row.$index;
      this.cellColumn = column.property;
      this.$emit("cellClick", row, column, cell, event);
    },
    cellMouseEnter(row, column, cell, event) {
      this.$nextTick(_ => {
        let list = document.getElementsByClassName('el-tooltip__popper')
        if(list.length) {
          list[list.length - 1].style.display = 'none'
        }
      })
    },
    columncountfunction(column, datacolumn) {
      let newcolumns = null;
      if (datacolumn?.fieldName !== column[column.length - 1]?.fieldName) {
        if (column[Number(datacolumn.index) + 1].isReadOnly) {
          return this.columncountfunction(column, column[Number(datacolumn.index) + 1]);
        } else {
          // debugger
          let a = null;
          a = clone(column[Number(datacolumn.index) + 1]);
          newcolumns = a;
          return newcolumns;
        }
      } else {
        // 最后一个column才会走
        for (let i = 0; i < column.length; i++) {
          if (!column[i].isReadOnly) {
            newcolumns = column[i];
            return newcolumns;
          }
        }
      }
    },
    handleTab(column) {
      let finalRow = false;
      let index = column.find((item, index) => {
        if (item?.fieldName == this.cellColumn) {
          item.index = index;
          if (index == column.length - 1) {
            finalRow = true;
          }
          return item;
        }
      });
      if (finalRow) {
        this.cellRow = Number(this.cellRow) + Number(1);
      }
      for (let i = 0; i < column.length; i++) {
        column[i].index = i;
      }
      let newcolumn = clone(column);
      let newcolumncountfunction = this.columncountfunction(
        newcolumn,
        column[Number(index.index)]
      );
      this.cellColumn = newcolumncountfunction.fieldName;
      this.$refs.Ytable.bodyWrapper.scrollLeft = Number(
        this.$refs.Ytable.bodyWidth.replace("px", "")
      );
    },
    setCurrentRow(row) {
      this.$refs.Ytable.setCurrentRow(row);
    },
    sortChange({ column, prop, order }) {
      this.$emit("sortChange", { column, prop, order }, this.tableData);
    },
    currentChange(row, oldRow) {
      this.$emit("currentChange", row, oldRow);
    },
    // getSummaries({ columns, data }) {
    //   this.$emit("getSummaries", { columns, data });
    // },
    selectionChage(selection) {
      this.$emit("selectionChage", selection);
    },
    handleSelectChange(selection, row) {
      this.$emit("handleSelectChange", selection, row);
    },
    handleClickHandle({ e, scope }) {
      let title = e.target.getAttribute("val");
      if (!title) return;
      if (title === "delete") {
        if (getSession("LanguageName") == "zh-cn") {
          this.$confirm(this.$t("userAdmin.tip3"), this.$t("configMoudle.tip"), {
            confirmButtonText: this.$t("configMoudle.confirm"),
            cancelButtonText: this.$t("configMoudle.cancel"),
            type: "warning",
          })
            .then((res) => {
              this.$emit("handleClickHandle", { title, scope });
              // this.$message({
              //   type: "success",
              //   message: "删除成功！",
              // });
            })
            .catch(() => {});
        } else if (getSession("LanguageName") == "en") {
          this.$confirm("Confirm deletion?", "hint", {
            confirmButtonText: "confirm",
            cancelButtonText: "cancel",
            type: "warning",
          })
            .then((res) => {
              this.$emit("handleClickHandle", { title, scope });
              // this.$message({
              //   type: "success",
              //   message: "删除成功！",
              // });
            })
            .catch(() => {});
        }
      } else {
        this.$emit("handleClickHandle", { title, scope });
      }
    },
    handleExport({ e, scope }) {
      let title = scope.column.className;
      if (title == "y-handle") this.$emit("handleExport", { title });
    },
    handleImport({ e, scope }) {
      let title = scope.column.className;
      if (title == "y-handle") this.$emit("handleImport", { title });
    },
    handleSettings({ e, scope }) {
      let title = scope.column.className;
      if (title == "y-handle") this.$emit("handleSettings", { title });
    },
    handleHeaderEdit(column) {
      this.$emit("handleHeaderEdit", column);
    },
    handleHeaderSearch(column, idx, val) {
      // this.$emit("handleHeaderSearch", column)
      console.log(column, idx, val);
    },
    handleSearch(column) {
      this.$emit("handleHeaderSearch", this.columnList);
    },
    rowDbClick(row, column) {
      if (
        (this.$route.path == "/CW/fksq_view" ||
          this.$route.path == "/CW/sfgl_view" ||
          this.$route.path == "/CW/sfgl_add" ||
          this.$route.path == "/CW/fksq_add" ||
          this.$route.path == "/CW/fksq_edit" ||
          this.$route.path == "/CW/sfgl_edit") &&
        column.property == "pono"
      ) {
        findPoctrl(row.pono).then((res) => {
          if (res.id == null || res.id == "" || res.id == "undefined" || !res.id) {
            this.$message({
              message: this.$t("addTips.orderNo"),
              type: "warning",
            });
            return;
          }
          this.$router.push({
            path: "/cggl/cght_view",
            query: {
              configId: res.configId,
              id: res.id,
              biaoshi: "true",
            },
          });
        });
      }
      this.$emit("rowDbClick", row, column);
    },
    rowClick(row, column) {
      this.$emit("rowClick", row, column);
    },
    showInput(row) {
      return Object.values(row).some((item) => item);
    },
    // rowDrop() {
    //   const tbody = document.querySelector(".el-table__body-wrapper tbody");
    //   const that = this;
    //   Sortable.create(tbody, {
    //     handle: '.hover-row',
    //     onEnd({ newIndex, oldIndex }) {
    //       const currRow = that.tableData.splice(oldIndex, 1)[0];
    //       that.tableData.splice(newIndex, 0, currRow);
    //     },
    //   });
    // },
  },
  components: {},
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss" scoped>
/deep/ .disableheadselection > .cell .el-checkbox__inner {
  display: none;
}

/deep/.el-table-column--selection {
  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    height: 100%;
  }
}

/deep/.el-tooltip {
  display: flex;
  align-items: center;

  span {
    // width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/deep/.cell {
  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 400;
    // width: 100% !important;
  }
}

/deep/ .el-table th,
.el-table th.is-leaf {
  background: rgb(218, 219, 221) !important;
}

// /deep/ .el-table--border {
//   border-bottom: none!important;
// }
/deep/ .el-table--scrollable-x .el-table__body-wrapper {
  z-index: 2;
}

// /deep/ .el-table {
//   .el-table__fixed {
//     height: auto !important; // 此处的important表示优先于element.style
//     bottom: 45px !important; // 改为自动高度后，设置与父容器的底部距离，则高度会动态改变
//   }
// }
/deep/ .el-table--fluid-height .el-table__fixed,
.el-table--fluid-height .el-table__fixed-right {
  background: white;
}

/deep/.el-table--mini .el-table__cell {
  // background: #ffffff;
  padding: 1px 0;
  // font-weight: 400;
}

/deep/.subtotalClass {
  font-size: 14px;
  font-weight: bold;
}

/deep/ ::-webkit-scrollbar {
  width: 5px !important;
  /*滚动条宽度*/
  height: 10px !important;
  /* 滚动条高度 */
}
</style>
