import request from "@/axios/index";
export function renderDom(configId) {
  return request({
    url: "/custom/selectConfigs?configId=" + configId,
    method: "get",
  });
}

// 上传附件，新增获取临时id接口
export function userGetId(params) {
  return request({
    url: "/sys/user/getId",
    method: "get",
    params,
  });
}

export function renderData({ params = {}, data = {} }) {
  return request({
    url: "/custom/selectDetail",
    method: "POST",
    params,
    data,
  });
}

// 复制单据
export function copyInvoicehistoryList(id, table) {
  return request({
    url: "/custom/historyList?id=" + id + '&table=' + table,
    method: "get",
  });
}

// 获取变更数据
export function copyInvoice(configId, id) {
  return request({
    url: "/custom/copyBill?configId=" + configId + '&id=' + id,
    method: "post",
    data: {},
  });
}

export function updateData({ params = {}, data = {} }) {
  return request({
    url: "/custom/updateAll",
    method: "POST",
    params,
    data,
  });
}
export function gxupdateData(data1, data2) {
  return request({
    url: "/custom/updateAll",
    method: "POST",
    params: data1,
    data: data2,
  });
}
export function delData({ params = {}, data = {} }) {
  return request({
    url: "/custom/deleteDetail",
    method: "POST",
    params,
    data,
  });
}
export function addData({ params = {}, data = {} }) {
  return request({
    url: "/custom/insertAll",
    method: "POST",
    params,
    data,
  });
}
// id调取
export function fetchConfigId(configId) {
  return request({
    url: "/custom/selectFetchConfigs",
    method: "get",
    params: { configId },
  });
}

// 调取
export function transConfigId(configId) {
  return request({
    url: "/custom/selectFieldFetchConfig",
    method: "get",
    params: { configId },
  });
}
/* 
data调取
params:{
  fetchConfigId:表id,
  asc:boolean,
  orderBy:排序字段
}
data：{}主表关联行
*/
export function fetchList({ data = {}, params = {} }) {
  return request({
    url: "/custom/selectFetchList",
    method: "post",
    data,
    params,
  });
}

export function deleteImage(id) {
  return request({
    url: `/common/association/${id}`,
    method: "delete",
  });
}

// 附件修改
export function saveEnclosure(id, data = {}) {
  return request({
    url: `/common/association/${id}`,
    method: "put",
    data,
  });
}


//调取邮件联系人列表
export function getContactList(params, id) {
  return request({
    url: "/sendMail/getContact?id=" + id,
    method: "post",
    data: params
  });
}

// 获取商品分类信息
export function getCommodityClassi(params) {
  return request({
    url: "/productCategory/select",
    method: "get",
    params
  });
}

// 出运计划查看采购合同附件：
export function getPurchaseAccessory(params) {
  return request({
    url: "/ymt/ship_attach",
    method: "get",
    params
  });
}
// 采购合同查看外销附件
export function getSaleAccessory(params) {
  return request({
    url: "/common/association/page",
    method: "get",
    params
  });
}


//强制刷新看看是否已撤销
export function getIsRepeal(id){
  return request({
    url: `/activiti/chk?procInstId=${id}`,
    method: "get",
  })
}

// 获取合同id和configId
export function findPoctrl(id) {
  return request({
    url: `/hai_hua/findPoctrl?fno=${id}`,
    method: "get",
  })
}

//客户管理转公海客户、转正式客户接口
export function getbatchUpdateCustom(data, params) {
  return request({
    url: "/custom/batchUpdateCustom",
    method: "post",
    data,
    params
  })
}