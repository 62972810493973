var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("Marketing.templateSelection"),
            visible: _vm.templateDialog,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.templateDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.searchInfo },
              on: { "before-close": _vm.handleClose },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.triggerClick($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "190px", "margin-right": "15px" },
                    attrs: {
                      placeholder: _vm.$t("Emails.templateSearch"),
                      size: "small"
                    },
                    model: {
                      value: _vm.searchInfo.title,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "title", $$v)
                      },
                      expression: "searchInfo.title"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v(_vm._s(_vm.$t("listOfSet.search")))]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: { margin: "15px 0 0 0" },
              attrs: { data: _vm.templateList, border: "" }
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "55",
                  label: _vm.$t("Marketing.select")
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.id },
                            model: {
                              value: _vm.radio,
                              callback: function($$v) {
                                _vm.radio = $$v
                              },
                              expression: "radio"
                            }
                          },
                          [_vm._v(_vm._s(""))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("Marketing.templateName"),
                  prop: "title"
                }
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("Marketing.remark"), prop: "remark" }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { margin: "15px 0 0 0" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
                [_vm._v(_vm._s(_vm.$t("Marketing.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleOk }
                },
                [_vm._v(_vm._s(_vm.$t("Marketing.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }