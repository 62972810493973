var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "btns" },
    _vm._l(_vm.button, function(item) {
      return _c(
        "el-button",
        {
          key: item.key,
          style: {
            fontSize: _vm.fonts,
            fontFamily: _vm.family,
            fontWeight: _vm.weight
          },
          attrs: {
            loading: _vm.loading,
            size: _vm.size,
            type: "primary",
            icon: item.icon,
            disabled: item.disabled
          },
          on: {
            click: function($event) {
              return _vm.handleClickButton(item.key, item.label)
            }
          }
        },
        [_vm._v(_vm._s(item.label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }