import request from "@/axios/index";
// 查询在线用户
export function getOnline(params) {
  return request({
    url: '/sys/online',
    method: "get",
    params
  });
}

// 踢出用户
export function deleteOnline(data) {
  return request({
    url: '/sys/online',
    method: "delete",
    data
  })
}


export function exportOnline(data) {
  return request({
    url: '/sys/online/onlineExport',
    method: "post",
    responseType: 'blob',
    data,
  });
}