import auto from "./auto";
const install = function(Vue) {
  Vue.directive("table-auto-height", auto);
};

if (window.Vue) {
  window["table-auto-height"] = auto;
  Vue.use(install);
}

auto.install = install;
export default auto;
