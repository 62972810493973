var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor" },
    [
      _c("el-input", {
        staticClass: "textareaInput",
        attrs: {
          type: "textarea",
          disabled: _vm.disabled,
          placeholder: _vm.$t("configMoudle.content")
        },
        on: { input: _vm.input },
        model: {
          value: _vm.editorData,
          callback: function($$v) {
            _vm.editorData = $$v
          },
          expression: "editorData"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }