<template>
  <div>
    <el-select
      :value="value"
      :placeholder="`${$t('placeHolder.pleaseSelect')} ${placeholder}`"
      size="mini"
      @change="(val) => $emit('update:value', val)"
    >
      <el-option
        v-for="item in options"
        :key="item.value ? item.value : item"
        :label="item.label ? item.label : item"
        :value="item.value ? item.value : item"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "Y-select",
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: String,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="scss"></style>
