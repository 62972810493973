import '@babel/polyfill';
import Vue from 'vue';
import store from './store';
import ElementUI from 'element-ui';
import com from '_com';
import dir from '@/directive';
import VXETable from 'vxe-table';
import {handleTree} from "@/utils/tools"

import {copyElTable,copyVxeTable} from "@/utils/copyRow"

import { language, vueThis } from '_utils/init';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/iconfont.css';
import './assets/fonts/iconfont.js';
import 'default-passive-events'
import '@/utils/drag.js'

import '@/styles/index.scss';
import 'vxe-table/lib/style.css'
import * as filters from './filter';
import _ from 'lodash';
import * as pro from '@/utils/prototype';
import './assets/icons'; // icon
import router from './router';
import { getSession, setSession } from '@/utils/methods';
import { option, options } from 'runjs';
Vue.prototype.$copyElTable = copyElTable
Vue.prototype.$copyVxeTable = copyVxeTable
Vue.prototype.$handleTree = handleTree


// import Astrict from '@/utils/astrict'
// Vue.use(Astrict)
Object.entries(pro).forEach(item => {
	Array.prototype[item[0]] = item[1];
});

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key]);
});
router.beforeEach((to, from, next) => {
	// 动态title
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	setSession('tagStep', from.fullPath);
	next();
});
Vue.prototype._ = _;
// Vue.prototype._CancelApi = CancelApi;
// Vue.use(CKEditor);


Vue.use(ElementUI);
Vue.use(com);
Vue.use(dir);
Vue.use(store);
Vue.prototype.$getSession = getSession

// 处理多语言的弹框问题 弹框内输入中文 配置语言包 key为对应的中文即可
const $message = Vue.prototype.$message
// import language from '@/utils/checkLang'

// $message({type,message}) 调用方式的处理
Vue.prototype.$message = (options) => {


	if (typeof options === 'string') {
		options = language.$message[options] || options
	} else {
		let { message, type } = options
		options.message = language.$message[message] || message
	}
	console.log(options)
	return $message(options)
}
// $message.error() 调用方式的处理
['success', 'warning', 'info', 'error'].forEach(it => {

	let messageIt = $message[it]
	Vue.prototype.$message[it] = function (options) {
		options = language.$message[options] || options
		return messageIt(options)

	}
})

// 处理多语言 确认框

const $confirm = Vue.prototype.$confirm
Vue.prototype.$confirm = (message, title, options) => {
	message = language.$confirm.message[message] || message
	title = language.$confirm.title[title] || title
	if(options){
		let { cancelButtonText, confirmButtonText } = options

		options.cancelButtonText = language.$confirm.message[cancelButtonText] || cancelButtonText
		options.confirmButtonText = language.$confirm.message[confirmButtonText] || confirmButtonText
	}
	return $confirm(message, title, options)
}

Vue.config.productionTip = false;
