<template>
  <div class="shop_wrapper">
    <div v-for="(item, index) in tableData" :key="index" class="shop_item_bg">
      <div class="shop_item" v-if="item.img_ids">
        <a href="#" class="shop_item_link">
          <div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
            <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
              <img
                style="width: auto; height: auto; max-width: 100%; max-height: 100%;"
                class="rax-image"
                :src="item.img_ids[0].url"
                @dblclick="imgDbClick(item.id)"
              />
            </div>
            <div class="shop_main-wrapper" style="width: 100%; height: 30%">
              <span class="shop__main-title">{{ item.spname }}</span>
              <span class="shop__main-title">{{ item.sdesc }}</span>
            </div>
          </div>
        </a>
      </div>
      <div class="shop_item noimage" v-else>
        <i class="iconfont icon-tupianguanli"></i>
        <span>{{ $t('editUserAdmin.noPicture') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { deepFindOne } from "@/utils/methods";
export default {
  name: "Y-productView",
  data() {
    return {
      formConfigId: "",
    };
  },
  props: {
    tableData: [Array],
  },
  computed: {
    routes() {
      return this.$store.state.global.routes;
    },
    path() {
      return this.$route.path;
    },
  },
  mounted() {},
  created() {
    const { formConfigId } = deepFindOne(
      this.routes,
      "formConfigId",
      this.path
    );
    this.formConfigId = formConfigId;
  },
  methods: {
    async imgDbClick(itemId) {
      this.$router.push({
        path:'spk_edit',
        query: { configId: this.formConfigId, id: itemId,  refreshDetailData: true, },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.shop_wrapper {
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  flex-shrink: 0;
  width: 1000px;
  margin: 0vw auto;
}
.shop_item_bg {
  background-color: #f7f7f7;
}
.shop_item {
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  width: 240px;
  float: left;
  margin: 0vw 10px 10px 0vw;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  height: 334px;
}
.shop_item_link {
  display: block;
  position: relative;
  text-decoration: none;
  height: 334px;
}
.rax-image {
  width: 240px;
  height: 240px;
  display: block;
}
.shop_main-wrapper {
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  width: 240px;
  height: 94px;
  margin: 0vw auto;
  background-color: #ffffff;
}
.shop__main-title {
  border-width: 0px;
  border-style: solid;
  border-color: black;
  position: relative;
  box-sizing: border-box;
  display: block;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  font-size: 16px;
  white-space: nowrap;
  width: 216px;
  height: 21px;
  line-height: 21px;
  color: rgb(51, 51, 51);
  margin: 0px auto 0vw;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noimage {
  justify-content: center;
  align-items: center;
  font-weight: 600;
  background-image: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
  background-clip: text;
  color: transparent;
  i {
    font-size: 45px;
    margin-bottom: 15px;
  }
}
</style>
