<template>
  <el-tabs 
    v-model="activeName" 
    @tab-click="tabClick"
    style="height:285px"
  >
    <el-tab-pane
      v-for="(item, index) in tab"
      :name="item[tabName]"
      :key="'tabs-' + item[tabName]"
    >
      <div slot="label">{{item[tabLabel]}}</div>
      <slot :tabItem="item" :$index="index" name="default" ></slot>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
export default {
  name: "Y-tab",
  props: {
    tab: {
      type: Array,
      default: () => [],
    },
    tabName: {
      type: String,
      default: "name",
    },
    tabLabel: {
      type: String,
      default: "label",
    },
    checkout:{
      type:String
    }
  },

  directives: {
    dragging(el){
      let current = el; //当前元素
      let targetDiv = document.querySelectorAll('.dragble-box')[0];
      current.onmousedown = function(e){
          //鼠标按下
          document.onmousemove = function(e){
              let th = e.clientY-380+250; 
              if(th<=140){
                th=140;
              }
              if(targetDiv){
                 targetDiv.style.height = th + "px";
                 return ;
              }
             
          }
          document.onmouseup = function(e){
              document.onmousemove = null;
              document.onmouseup = null;
          };
          return false;
      };
    }
  },
  data() {
    return {
      activeName: "",
    };
  },
  computed: {
    _style() {
      return {
        minHeight: "250px",
        maxHeight: "350px",
        overflowY: "auto",
      };
    },
  },
  created() {
    if(this.tab && this.tab.length) this.activeName = this.tab[0]?.[this.tabName];
  },
  mounted(){},
  watch: {
    checkout(ev){
      if(ev=='报关'){
        this.activeName = this.tab[1]?.[this.tabName];
      }else if(ev=='单证'){
         this.activeName = this.tab[0]?.[this.tabName];
      }else{
        this.activeName = this.tab[0]?.[this.tabName];
      }
    },
    activeName(newVal) {
      this.$emit("update:changeActive", newVal);
      /* 这个$emit是调取框明细调取的赋值才用，其他没用过 */
      this.$emit("changeTabItem", this.tab?.find((item) => item.key === newVal));
    },
    tab (val) {
      if (val && this.tab.length) {
        // console.log('aaa',val)
        // console.log('bbb', this.tabName)
        // console.log('ccc',this.tab)
        // this.activeName = val[0]?.[this.tabName];
      }
    },
  },
  methods: {
    tabClick(obj) {
      const { name } = obj;
      if (!name) return;
      const { tab } = this;
      const cur = tab.find((item) => item[this.tabName] === name);
      if (!cur) return;
      this.$emit("handleTabClick", { cur, tab });
    },
  },
  components: {},
  
};
</script>
<style scoped lang="scss">

.el-table__body {
  max-height: 300px;
  overflow: auto;
}
</style>
 