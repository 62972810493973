

export function deepMap(cb) {
   if (!this) return;
  let tem = [];
  for (let i = 0; i < this.length; i++) {
    if (this[i].children) {
      const data = this[i].children.deepMap(cb);
      if (data.length) {
        this[i].children = data;
      }
    }
    const result = cb(this[i], i);
    if (result) {
      tem.push(result);
    }
  }
  return tem;
}

export function deepFilter(cb) {
  if (!this) return;
  let tem = [];
  for (let i = 0; i < this.length; i++) {
    if (this[i].children) {
      const data = this[i].children.deepFilter(cb);
      this[i].children = data;
    }
    cb(this[i]) && tem.push(this[i]);
  }
  return tem;
}
