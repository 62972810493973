var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      staticClass: "info-form",
      attrs: {
        model: _vm.curData,
        rules: _vm.rules,
        "label-width": "180px",
        inline: _vm.inline,
        size: _vm.size
      }
    },
    [
      _c(
        "el-row",
        { staticClass: "rowBox" },
        _vm._l(_vm.form, function(item, colIndex) {
          return _c(
            "el-col",
            {
              key: "form-row-" + colIndex,
              style:
                item.stretchTile == "1"
                  ? "width: 100%"
                  : item.stretchTile == "2"
                  ? "width: 264px"
                  : item.stretchTile == "3"
                  ? "width: 50%"
                  : item.stretchTile == "4"
                  ? "width: 30%"
                  : item.stretchTile == "5"
                  ? "width: 48%"
                  : "width: 264px",
              attrs: { span: _vm.spanSet(item, _vm.reactive) }
            },
            [
              _vm.showOnlyInfo
                ? _c(
                    "el-form-item",
                    {
                      key: "form-item-" + item[_vm.itemProp],
                      class: ["y-" + (item[_vm.itemTypeKey] || "input")],
                      attrs: {
                        label: item[_vm.itemLabel],
                        prop: item[_vm.itemProp]
                      }
                    },
                    [
                      _c("div", { staticClass: "hhh" }, [
                        _vm._v(_vm._s(_vm.curData[item[_vm.itemProp]]))
                      ])
                    ]
                  )
                : _c(
                    "el-form-item",
                    {
                      key: "form-item-" + item[_vm.itemProp],
                      class: ["y-" + (item[_vm.itemTypeKey] || "input")],
                      attrs: {
                        label: item[_vm.itemLabel] + ":",
                        prop: item[_vm.itemProp]
                      }
                    },
                    [
                      _c("template", { slot: "label" }, [
                        _c(
                          "span",
                          { staticStyle: { position: "relative" } },
                          [
                            _c(
                              "span",
                              { style: { fontSize: _vm.fonts } },
                              [
                                _vm._v(_vm._s(item[_vm.itemLabel]) + " "),
                                _c(
                                  "el-popover",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item["extAttr"] &&
                                          item["extAttr"] ==
                                            "formAddDefaultValue",
                                        expression:
                                          "item['extAttr'] && item['extAttr'] == 'formAddDefaultValue'"
                                      }
                                    ],
                                    attrs: {
                                      placement: "top-start",
                                      title: _vm.$t("configMoudle.tip"),
                                      width: "300",
                                      trigger: "click"
                                    }
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(" 默认值支持常量和变量"),
                                      _c("br"),
                                      _vm._v("支持的系统变量如下:"),
                                      _c("br"),
                                      _vm._v(" sys_user_code 登陆用户账号"),
                                      _c("br"),
                                      _vm._v(" sys_user_name 登陆用户名"),
                                      _c("br"),
                                      _vm._v(
                                        " sys_date 系统当前日期(yyyy-MM-dd) "
                                      )
                                    ]),
                                    _c("i", {
                                      staticClass: "el-icon-question",
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    })
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top",
                                  tabindex: _vm.tabindex
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content"
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(_vm._s(item[_vm.itemLabel]))
                                    ])
                                  ]
                                ),
                                _c("i", {
                                  staticStyle: {
                                    position: "absolute",
                                    left: "0",
                                    display: "inline-block",
                                    width: "100%",
                                    height: "28px"
                                  }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      item.isShows
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom-start",
                                trigger: "click"
                              }
                            },
                            [
                              _c("IconSelect", {
                                ref: "iconSelect",
                                refInFor: true,
                                attrs: { fieldname: item[_vm.itemProp] },
                                on: { selected: _vm.selected }
                              }),
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    slot: "reference",
                                    placeholder: "点击选择图标",
                                    readonly: ""
                                  },
                                  slot: "reference",
                                  model: {
                                    value: _vm.curData[item[_vm.itemProp]],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.curData,
                                        item[_vm.itemProp],
                                        $$v
                                      )
                                    },
                                    expression: "curData[item[itemProp]]"
                                  }
                                },
                                [
                                  _vm.curData[item[_vm.itemProp]]
                                    ? _c("svg-icon", {
                                        staticClass: "el-input__icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class":
                                            _vm.curData[item[_vm.itemProp]]
                                        },
                                        slot: "prefix"
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-search el-input__icon",
                                        attrs: { slot: "prefix" },
                                        slot: "prefix"
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.inputType[item[_vm.itemTypeKey]] ||
                      (!item[_vm.itemTypeKey] && !item.isShows)
                        ? _c(
                            "el-input",
                            {
                              ref: "contents",
                              refInFor: true,
                              staticClass: "formStyle",
                              attrs: {
                                type: item[_vm.itemTypeKey]
                                  ? item[_vm.itemTypeKey]
                                  : "text",
                                autosize: { minRows: 4, maxRows: 4 },
                                resize: "none",
                                disabled:
                                  (item[_vm.disabledKey] ||
                                    _vm.disabledReadOnly) &&
                                  !(
                                    _vm.$route.query.peddingApproval &&
                                    item.isEditableUnderApproval
                                  )
                              },
                              on: {
                                input: function($event) {
                                  return _vm.changeValue(
                                    $event,
                                    item[_vm.itemProp],
                                    _vm.curData
                                  )
                                }
                              },
                              nativeOn: {
                                dblclick: function($event) {
                                  return _vm.Enlarge(
                                    item,
                                    _vm.curData[item[_vm.itemProp]],
                                    colIndex
                                  )
                                }
                              },
                              model: {
                                value: _vm.curData[item[_vm.itemProp]],
                                callback: function($$v) {
                                  _vm.$set(_vm.curData, item[_vm.itemProp], $$v)
                                },
                                expression: "curData[item[itemProp]]"
                              }
                            },
                            [
                              item.fetchId
                                ? _c("i", {
                                    staticClass:
                                      "el-icon-edit el-input__icon coin",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { slot: "suffix" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleFocus(item)
                                      }
                                    },
                                    slot: "suffix"
                                  })
                                : _vm._e()
                            ]
                          )
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "date"
                        ? _c("el-date-picker", {
                            attrs: {
                              disabled:
                                item[_vm.disabledKey] &&
                                !(
                                  _vm.$route.query.peddingApproval &&
                                  item.isEditableUnderApproval
                                ),
                              type: "date",
                              "value-format": "timestamp"
                            },
                            on: {
                              change: function($event) {
                                return _vm.handlePicker(
                                  $event,
                                  item[_vm.itemProp],
                                  _vm.curData
                                )
                              }
                            },
                            model: {
                              value: _vm.curData[item[_vm.itemProp]],
                              callback: function($$v) {
                                _vm.$set(_vm.curData, item[_vm.itemProp], $$v)
                              },
                              expression: "curData[item[itemProp]]"
                            }
                          })
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "datetimerange"
                        ? _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "range-separator": _vm.$t("placeHolder.to"),
                              "start-placeholder": _vm.$t(
                                "placeHolder.startDate"
                              ),
                              "end-placeholder": _vm.$t("placeHolder.endDate")
                            },
                            on: { change: _vm.datechange },
                            model: {
                              value: _vm.datetimerange,
                              callback: function($$v) {
                                _vm.datetimerange = $$v
                              },
                              expression: "datetimerange"
                            }
                          })
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "select" ||
                      item[_vm.itemTypeKey] == "multipleSelect" ||
                      item[_vm.itemTypeKey] == "person" ||
                      item[_vm.itemTypeKey] == "selectroles"
                        ? _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: !item.multiple ? true : false,
                                filterable: "",
                                disabled:
                                  item[_vm.disabledKey] &&
                                  !(
                                    _vm.$route.query.peddingApproval &&
                                    item.isEditableUnderApproval
                                  ),
                                multiple:
                                  item.multiple ||
                                  item[_vm.itemTypeKey] == "multipleSelect" ||
                                  item[_vm.itemTypeKey] == "person" ||
                                  item[_vm.itemTypeKey] == "selectroles",
                                "collapse-tags":
                                  item.collapseTags ||
                                  item[_vm.itemTypeKey] == "multipleSelect" ||
                                  item[_vm.itemTypeKey] == "person",
                                "value-key": item.valueKey || item.itemName
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleSelect(
                                    _vm.curData[item[_vm.itemProp]],
                                    item[_vm.itemProp],
                                    item.list,
                                    _vm.curData
                                  )
                                }
                              },
                              model: {
                                value: _vm.curData[item[_vm.itemProp]],
                                callback: function($$v) {
                                  _vm.$set(_vm.curData, item[_vm.itemProp], $$v)
                                },
                                expression: "curData[item[itemProp]]"
                              }
                            },
                            [
                              item.multiple
                                ? _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("Emails.getAll"),
                                      value: "all"
                                    }
                                  })
                                : _vm._e(),
                              item.multiple
                                ? _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("Emails.clearAll"),
                                      value: "empty"
                                    }
                                  })
                                : _vm._e(),
                              _vm._l(item.list, function(val, index) {
                                return _c(
                                  "el-option",
                                  {
                                    key:
                                      (val[item.optionValue || "value"] ||
                                        val) +
                                      "-select-option" +
                                      index,
                                    attrs: {
                                      value: item.valueKey
                                        ? val
                                        : val[item.optionValue || "value"] ||
                                          val,
                                      label:
                                        val[item.optionLabel || "label"] || val
                                    }
                                  },
                                  [
                                    item.titleValue
                                      ? _c(
                                          "span",
                                          { staticStyle: { float: "left" } },
                                          [_vm._v(_vm._s(val.label))]
                                        )
                                      : _vm._e(),
                                    item.titleValue
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              float: "right",
                                              color: "#8492a6",
                                              "font-size": "13px"
                                            }
                                          },
                                          [_vm._v(_vm._s(val.value))]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "switch"
                        ? _c("el-switch", {
                            attrs: {
                              "active-text": item.activeText,
                              disabled:
                                item[_vm.disabledKey] &&
                                !(
                                  _vm.$route.query.peddingApproval &&
                                  item.isEditableUnderApproval
                                )
                            },
                            model: {
                              value: _vm.curData[item[_vm.itemProp]],
                              callback: function($$v) {
                                _vm.$set(_vm.curData, item[_vm.itemProp], $$v)
                              },
                              expression: "curData[item[itemProp]]"
                            }
                          })
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "radioGroupButton"
                        ? _c(
                            "el-radio-group",
                            {
                              attrs: {
                                size: "small",
                                disabled:
                                  item[_vm.disabledKey] &&
                                  !(
                                    _vm.$route.query.peddingApproval &&
                                    item.isEditableUnderApproval
                                  )
                              },
                              nativeOn: {
                                change: function($event) {
                                  return _vm.chaneRadioButton($event)
                                }
                              },
                              model: {
                                value: _vm.curData[item[_vm.itemProp]],
                                callback: function($$v) {
                                  _vm.$set(_vm.curData, item[_vm.itemProp], $$v)
                                },
                                expression: "curData[item[itemProp]]"
                              }
                            },
                            _vm._l(item.GroupList, function(items) {
                              return _c("el-radio-button", {
                                key: items.label,
                                attrs: { label: items.label }
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "radioGroup"
                        ? _c(
                            "el-radio-group",
                            {
                              attrs: {
                                size: "small",
                                disabled:
                                  item[_vm.disabledKey] &&
                                  !(
                                    _vm.$route.query.peddingApproval &&
                                    item.isEditableUnderApproval
                                  )
                              },
                              model: {
                                value: _vm.curData[item[_vm.itemProp]],
                                callback: function($$v) {
                                  _vm.$set(_vm.curData, item[_vm.itemProp], $$v)
                                },
                                expression: "curData[item[itemProp]]"
                              }
                            },
                            _vm._l(item.GroupList, function(items) {
                              return _c("el-radio", {
                                key: items.label,
                                attrs: { label: items.label }
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      item[_vm.itemTypeKey] === "treeselect"
                        ? _c("Y-treeSelect", {
                            attrs: {
                              input: _vm.curData[item[_vm.itemProp]],
                              options: item.options,
                              multiple: item.multiple,
                              normalizerFormat: item.normalizerFormat,
                              size: _vm.size,
                              appendToBody: item.appendToBody,
                              loadOptions: item.loadOptions,
                              "auto-load-root-options": item.autoLoadRootOptions
                            },
                            on: {
                              "update:input": function($event) {
                                return _vm.$set(
                                  _vm.curData,
                                  item[_vm.itemProp],
                                  $event
                                )
                              },
                              "update:options": function($event) {
                                return _vm.$set(item, "options", $event)
                              },
                              selectDepart: function(val) {
                                return _vm.selectDepart(val, item[_vm.itemProp])
                              }
                            }
                          })
                        : _vm._e(),
                      item[_vm.itemTypeKey] == "citySelect"
                        ? _c("Y-citySelect", {
                            attrs: {
                              modellist: _vm.curData[item[_vm.itemProp]]
                            },
                            on: {
                              changecasca: function(val) {
                                return _vm.changecasca(val, item, _vm.curData)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  )
            ],
            1
          )
        }),
        1
      ),
      _vm.isImage && _vm.firstTab
        ? _c(
            "el-row",
            {
              nativeOn: {
                contextmenu: function($event) {
                  $event.preventDefault()
                },
                dragstart: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传图片:" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.baseUrl + "/common/upload",
                            "list-type": "picture-card",
                            "file-list": _vm.imageList,
                            headers: _vm.headers,
                            data: _vm.resData,
                            "auto-upload": true,
                            "show-file-list": true,
                            multiple: "",
                            name: "file",
                            disabled:
                              (_vm.isView || _vm.isReadOnly || _vm.isUploads) &&
                              !(
                                _vm.$route.query.peddingApproval &&
                                _vm.item.isEditableUnderApproval
                              ),
                            accept: ".png, .jpeg, .jpg",
                            "on-success": _vm.handleSuccess,
                            "on-preview": _vm.handlePictureCardPreview,
                            "before-upload": _vm.handleBefore,
                            "on-remove": _vm.handleRemove
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "file",
                                fn: function(ref) {
                                  var file = ref.file
                                  return _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "100%",
                                        display: "flex",
                                        "justify-content": "center",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "el-upload-list__item-thumbnail",
                                        staticStyle: {
                                          width: "auto",
                                          height: "auto",
                                          "max-width": "100%",
                                          "max-height": "100%"
                                        },
                                        attrs: { src: file.url, alt: "" }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-actions"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-preview"
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-zoom-in",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.previewImgClick(
                                                      file
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-delete"
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteImgClick(
                                                      file
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3536234109
                          )
                        },
                        [
                          _vm.isUpload
                            ? _c("i", { staticClass: "el-icon-plus" })
                            : _c("i", { staticClass: "el-icon-remove-outline" })
                        ]
                      ),
                      _c(
                        "el-dialog",
                        {
                          directives: [
                            { name: "dialogDrag", rawName: "v-dialogDrag" }
                          ],
                          staticStyle: { width: "100%" },
                          attrs: { visible: _vm.dialogVisible },
                          on: {
                            "update:visible": function($event) {
                              _vm.dialogVisible = $event
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: {
                              height: "auto",
                              width: "90%",
                              src: _vm.dialogImageUrl,
                              alt: ""
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.EnlargeTitle,
            visible: _vm.dialogV
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogV = $event
            }
          }
        },
        [
          _c("el-input", {
            ref: "dialog",
            staticClass: "tArea",
            attrs: {
              type: "textarea",
              resize: "none",
              value: _vm.curData[_vm.textareaVal]
            },
            on: { input: _vm.textareaChange }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.EnSubmit } },
                [_vm._v(_vm._s(_vm.$t("configMoudle.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }