<template>
  <div class="citySelect">
    <el-cascader v-model="cascaderlist" @change="changecasca" :options="options" clearable filterable></el-cascader>
  </div>
</template>

<script>
import citySelect from "@/assets/json/citySelect.json"
export default {
  name: "Y-citySelect",
  props: {
    // disabledspan: {
    //   type: Boolean,
    //   default: false,
    // },
    modellist: {
      type: String,
      default: "",
    }
  },
  data () {
    return {
      optionsList: [],
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { children } = node;
          setTimeout(() => {
             const nodes = Array.from({ length: children })
              .map(item => ({
                value: item.value,
                label: item.label,
                leaf: children
              }));
            resolve(nodes);
          }, 1000);
        }
      },
      cascaderlist: []
    }
  },
  watch: {
    modellist: {
      handler(newVal) {
        console.log(newVal)
        if (newVal !== "" && newVal !== null && newVal !== undefined) {
          this.cascaderlist = this.modellist.split("/")
        } else {
          this.cascaderlist = []
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    console.log("this.modellistthis.modellist", this.modellist)
    this.options = this.optionsfunction(citySelect.provinces)
    if (this.modellist !== "" && this.modellist !== null && this.modellist !== undefined) {
      this.cascaderlist = this.modellist.split("/")
    } else {
      this.cascaderlist = []
    }
  },
  methods: {
    optionsfunction (options) {
      let optionsdata = []
      for (let i = 0; i < options.length; i ++) {
        let data = {
          label: options[i].label,
          value: options[i].label
        }
        if (options[i].children && options[i].children.length > 0) {
          data.children = this.optionsfunction(options[i].children)
        }
        optionsdata.push(data)
      }
      return optionsdata
    },
    changecasca (val) {
      let newval = val.join("/")
      this.$emit("changecasca", newval)
    },
  },
};
</script>

<style scoped>
</style>
