<template>
  <el-drawer
    id="Y-drawer"
    :visible="drawer"
    :direction="direction"
    :append-to-body="true"
    :wrapperClosable="false"
    :title="title"
    :size="size"
    @close="$emit('update:drawer',false)"
   >
    <div slot="title">
      <slot name="title"></slot>
    </div>

    <slot></slot>
  </el-drawer>
</template>

<script>
export default {
  name: "Y-drawer",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: "rtl",
    },
    title: {
      type: String,
      default: "默认标题",
    },
    size: String,
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  watch: {},
  methods: {
   },
  components: {},
};
</script>

<style scoped lang="scss">
/deep/ :focus {
  outline: 0;
}
</style>
