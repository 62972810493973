<template>
  <div class="container">
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item :label="$t('Emails.addressee')">
        <el-select
          v-model="form.tos"
          filterable
          :placeholder="$t('placeHolder.pleaseSelect')"
          multiple
          clearable
        >
          <el-option
            v-for="item in emailContactList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Emails.theme')">
        <el-input :placeholder="$t('configMoudle.content')" v-model="form.subject"></el-input>
      </el-form-item>
      <el-form-item>
        <div>
          <div class="fujian">
            <a
              href="javascript:void(0)"
              class="cb0 nui-txt-link"
              @click="chooseFile"
              ><b class="nui-ico nui-ico-cmpAttach"></b>{{$t('configMoudle.uploadFile')}}</a
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div
          class="fitem"
          style="width: 292px"
          v-for="(item, index) in uploadFileList"
          :key="index"
        >
          <b id="_mail_icon_48_302" class="nui-ico-file"></b>
          <div class="o0">{{ item.name }}</div>
          <div class="m0">
            <a
              href="javascript:void(0)"
              class="nui-txt-link"
              @click="delFile(item)"
            >{{$t('configMoudle.delete')}}</a
            >
          </div>
          <div class="j0">{{ renderSize(item.size) }}</div>
          <div class="n0" style="display: none"></div>
        </div>
      </el-form-item>
      <el-form-item label="内容">
        <div id="editor"></div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="loading">{{$t('Emails.send')}}</el-button>
      </el-form-item>
    </el-form>

    <el-upload
      ref="attachUpload"
      :file-list="fileList"
      :auto-upload="false"
      v-show="false"
      :http-request="httpRequest"
      :on-change="attachUploadOnChange"
      action=""
    >
    </el-upload>
  </div>
</template>

<script>
import request from "@/axios/index";
import E from "wangeditor";
export default {
  name: "Y-sendemail",
  data() {
    return {
      form: {
        tos: [],
        subject: "",
        content: "",
      },
      options: [],
      fileList: [],
      uploadFileList: [],
      binfile: [],
      loading: false,
    };
  },
  props: {
    emailContactList: {
      type: Array,
    },
  },
  mounted() {
    const editor = new E("#editor");
    editor.config.uploadImgShowBase64 = true;
    editor.config.onchange = (newHtml) => {
      this.form.content = newHtml;
    };
    editor.config.menus = [
      "head",
      "bold",
      "fontSize",
      "fontName",
      "italic",
      "underline",
      "strikeThrough",
      "indent",
      "lineHeight",
      "foreColor",
      "backColor",
      "link",
      "list",
      "todo",
      "justify",
      "quote",
      "emoticon",
      "image",
      "table",
      "code",
      "splitLine",
      "undo",
      "redo",
    ];
    editor.create();
  },
  created() {},
  methods: {
    //attachUpload
    onSubmit() {
      this.binfile = [];
      let data = new FormData();
      for (let i = 0; i < this.form.tos.length; i++) {
        data.append("tos", this.form.tos[i]);
      }
      data.append("subject", this.form.subject);
      data.append("content", this.form.content);
      if (this.uploadFileList.length > 0) {
        this.$refs.attachUpload.submit();
      }
      for (let i = 0; i < this.binfile.length; i++) {
        data.append("multipartFiles", this.binfile[i]);
      }
      this.loading = true;
      request({
        url: "/sendMail",
        method: "post",
        data: data,
      })
        .then((res) => {
          this.loading = false;
          this.$message.success(this.$t('Order.sendSuccess'));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    chooseFile() {
      this.$refs.attachUpload.$el.children[0].children[0].click();
    },
    attachUploadOnChange(file, fileList) {
      console.log(this.$refs.attachUpload.$el.children[0].children[0].files[0]);
      console.log(file, fileList);
      this.uploadFileList = JSON.parse(JSON.stringify(fileList));
    },
    delFile(file) {
      this.uploadFileList.splice(this.uploadFileList.indexOf(file), 1);
      this.fileList = JSON.parse(JSON.stringify(this.uploadFileList));
    },
    /// <summary>
    /// 格式化文件大小的JS方法
    /// </summary>
    /// <param name="value">文件的大小,传入的是一个bytes为单位的参数</param>
    /// <returns>格式化后的值</returns>
    renderSize(value) {
      if (null == value || value == "") {
        return "0 Bytes";
      }
      var unitArr = new Array(
        "Bytes",
        "KB",
        "MB",
        "GB",
        "TB",
        "PB",
        "EB",
        "ZB",
        "YB"
      );
      var index = 0;
      var srcsize = parseFloat(value);
      index = Math.floor(Math.log(srcsize) / Math.log(1024));
      var size = srcsize / Math.pow(1024, index);
      size = size.toFixed(2); //保留的小数位数
      return size + unitArr[index];
    },
    httpRequest(param) {
      this.binfile.push(param.file);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input__inner {
  border: 0;
}
.container {
  padding-left: 104px;
  padding-right: 104px;
  padding: 0 52px;
  zoom: 1;
  position: relative;
  overflow: visible;
}
/deep/.el-form-item:not(:last-of-type) {
  margin-bottom: 10px;
}
/deep/.w-e-toolbar,
/deep/.w-e-text-container {
  z-index: 1 !important;
}
/deep/.el-select-dropdown {
  z-index: 9999 !important;
}
</style>
<style>
/* .w-e-text-container{
  z-index: 2000  !important;
}
.w-e-toolbar{
   z-index: 2000  !important;
} */
.attachOperate {
  height: 40px;
  white-space: nowrap;
  position: relative;
}
.fujian {
  height: 31px;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  left: -4px;
}
.cb0 {
  margin-left: 14px;
  float: left;
  display: block;
  height: 22px;
  line-height: 12px;
}
.nui-ico-cmpAttach {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALQAAABGAgMAAAAY+VazAAAAA3NCSVQICAjb4U/gAAAACVBMVEX///////96enqykic0AAAAA3RSTlMA//9EUNYhAAAACXBIWXMAAArrAAAK6wGCiw1aAAAAFnRFWHRDcmVhdGlvbiBUaW1lADExLzIxLzEzNm64tAAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABLSURBVEiJY+BqYIAAVgcGwkBBAcoQECBCdRMHlOHIQoTqjg4ow8VlVDVZqhtgqh2IUa0FY4gSoXgUjIJRMApGwSgYBaNgFIwCWgEAHAIO8o6JYwoAAAAASUVORK5CYII=)
    no-repeat 0 0;
  width: 7px;
  height: 12px;
  overflow: hidden;
  position: absolute;
  left: 5px;
}
.fitem {
  width: 320px;
  background-color: #efefef;
  height: 44px;
  float: left;
  position: relative;
  margin: 0 8px 8px 0;
  border-radius: 3px;
  overflow: visible;
  font-size: 12px;
}
.nui-ico-file {
  width: 32px;
  height: 32px;
}
.o0 {
  width: 200px;
  height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666;
  position: absolute;
  left: 41px;
  top: -4px;
}
.m0 {
  position: absolute;
  right: 6px;
  top: 5px;
  font-size: 12px;
}
.nui-txt-link {
  outline: 0;
  cursor: pointer;
  padding: 2px 4px 4px;
  padding: 3px 4px;
  text-decoration: none;
  border-radius: 3px;
}
.j0 {
  height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b0b0b0;
  position: absolute;
  left: 41px;
  top: 21px;
  line-height: 160%;
  overflow: visible;
  font-size: 12px;
}
.nui-txt-suc {
  color: #3d882d !important;
}

</style>