<template lang="html">
  <div class="editor">
    <!-- <div :id="id" v-model="editorData" :ref="id" @input="input">
    </div> -->
    <el-input class="textareaInput" type="textarea" :disabled="disabled" :placeholder="$t('configMoudle.content')" v-model="editorData" @input="input">
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'editoritem',
  data() {
    return {
      ids: ""
    }
  },
  props: {
    editorData: {
      type: String
    },
    fartherid: {
      type: String
    },
    disabled:{
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
  },
  watch: {
    // 监听父组件穿过来的数据，数据改变后刷新当前组件数据。
    fartherid: {
      handler(newVal) {
        this.ids = newVal
      }
    }
  },
  mounted() {
  },
  methods: {
    input (e) {
      this.$emit('input', e)
    },
  }
}
</script>

<style lang="scss">
  .editor {
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
    z-index: 10;
  }
  .textareaInput {
    height: calc(50vh - 20px);
    textarea {
      height: 100%;
    }
  }
</style>