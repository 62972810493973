import { initLanguage } from "@/api/sysTem";

export default {
// 报表管理
  reportSet:{
    template: "шаблон человека",
   username : "персонал",
   set: "Назначение",
   empty: "Данные пусты",
   reportName: "Название статистики",
   isEnabled: "использовать или нет",
},
// 代码事件
codeEvent:{
  eventSet: "размещение инцидента",
  placeSetName: "Пожалуйста, введите название мероприятия",
  eventName: "название события",
  eventId: "Идентификатор события",
  placeSet: "Пожалуйста входите",
  fieldChange: "Изменения полей",
  field: "Поле",
  status: "состояние",
  eventContent: "Содержание мероприятия",
  sqlConfig: "конфигурация SQL",
  name: "имя",
  remark: "Примечание"

},


  // 新增的确认对话框 一个个改实在是太困难了

    $confirm:{
      message:{
      修改为已读吗: "Изменить как прочитано?",
      确定撤回该数据吗: "Вы уверены, что хотите удалить эти данные?",
      '是否要变更该合同？': "Вы хотите изменить этот контракт?",
      '是否要取消变更该合同？': "Вы хотите отменить изменения в этом договоре?"
      },
      title:{
        "提示": "намекать",
      },
      cancelButtonText:{},
      confirmButtonText:{},

    },

  // 新增的提示弹框 绑定到vue 中文作为建明 修改太困难了
  $message:{
    请选择数据: "Пожалуйста, выберите данные",
    请选择人员进行数据转交: "Пожалуйста, выберите человека для передачи данных!",
    部署成功: "Развертывание успешно",
    请先添加计划明细: "Сначала добавьте информацию о плане",
    该文件暂不支持预览: "Этот файл пока не поддерживает предварительный просмотр",
    已取消关闭: "Закрытие отменено",
    成功清除缓存: "Кэш очищен успешно",
    '模型名称重复，请使用新名称创建模型。': "Повторяющееся название модели. Создайте модель с новым именем.",
    '用户名或密码不能为空！': "Имя пользователя или пароль не могут быть пустыми!",
    "提交成功": "Отправлено успешно",
    "提交失败": "Отправка не удалась",
    "已变更": "измененный",
    "已取消变更": "Изменения отменены",
    "待采明细有采购不能重新生成": "Приобретаемые реквизиты не могут быть восстановлены, если есть покупки.",
  "生成待采明细成功": "Детали для майнинга успешно сгенерированы.",
  "生成待采明细失败": "Не удалось сгенерировать данные для анализа.",
  "此调取未包含目标模块的字段，请切换到正确的页签": "Этот вызов не содержит полей целевого модуля. Переключитесь на правильную вкладку.",
  "明细中供应商存在多个，请检查！": "В деталях указано несколько поставщиков, пожалуйста, проверьте!",
  "认领金额不能大于收款金额，请重新填写！": "Сумма претензии не может быть больше суммы платежа, пожалуйста, заполните ее еще раз!",
  "请选择要赋值的人员": "Пожалуйста, выберите человека, которому нужно присвоить значение",
  "请选择模板人员!": "Пожалуйста, выберите шаблон персонала",
  "赋值成功": "Задание выполнено успешно",
  "赋值失败": "Не удалось выполнить задание."
  },
    // 个性化
  individuation:{
    fieldName: "Имя столбца",
    visible: "Отображать ли",
    total: "Стоит ли складывать",
    locktopic: "Стоит ли блокировать",
    width: "текущая ширина",
    enable: "давать возможность",
    disabled: "Запрещать"
  },
    formSet: {
      languageSet: "переключить язык",
      hyperlink: "Гиперссылка",

      stretch: "потягиваться",
      tile: "Плитка",
      half: "полурастяжка",
      third: "Занимайте одну треть",
      leftJustify: "выравнивание по левому краю",
      rightJustify: "выравнивание по левому краю",
      center: "выравнивание по центру",
      equal: "равный",
      less: "меньше, чем",
      lessOrequal: "меньше или равно",
      greater: "больше, чем",
      greatorequal: "больше или равно",
      like: "全нравится",
      left: "左левый",
      right: "право лево",
      between: "МЕЖДУ",
      database: "база данных",
      expression: "пользовательское выражение",
      year: "Год",
      month: "луна",
      day: "День",
      hour: "час",
      minute: "точка",
      second: "Второй",
      input: "Поле ввода",
      DateRange: "Поле выбора диапазона дат",
      dateInput: "Поле выбора даты",
      selectOption: "выпадающий список",
      switch: "выключатель",
      textarea: "текстовое поле",
      numberInput: "числовой ящик",
      richText: "богатый текст",
      checkBox: "раскрывающийся флажок",
      people: "персонал",
      citySelect: "Раскрывающийся список городов",
      selectInput: "раскрывающееся поле ввода",
      permiAll: "Все разрешения на данные",
       permiCustom: "Пользовательские разрешения на данные",
       department: "Разрешения на данные этого отдела",
       deptBelow: "Разрешения на данные для этого отдела и следующих",
       selfOnly: "Только разрешения на личные данные"
    },
    // 版本管理
    version: {
      record: "журнал изменений",
      times: "Количество изменений",
      updateDate: "Изменить дату",
      updatePerson: "Сменить человека",
      mainTableData: "Данные изменения основной таблицы",
      detailsOldData: "Подробные исходные данные",
      detailsData: "Подробные данные об изменениях",
      oldData: "необработанные данные",
      newData: "Данные после изменения"
    },

    // 补充的提示语
    addTips: {
      approve: "На рассмотрении",
      reject: "отказываться",
      pass: "проходить",
      noUse: "Модуль не включен",
      hasPigeonhole: "В архиве",
      noPigeonhole: "Не в архиве",
      all: "все",
      cancel: "Отмена",
      confirm: "подтверждать",
      noSupportpreview: "Этот файл пока не поддерживает предварительный просмотр",
      fileServiceIsNotDeployed: "Служба предварительного просмотра файлов не развернута",
      isAssign: "Стоит ли указывать",
      programmeNameRequire: "Пожалуйста, введите название плана",
      programmeCodeRequire: "Пожалуйста, укажите номер плана",
      addPerson: "Пожалуйста, выберите человека",
      number: "Нет.",
      groupEmpty: "Название группы не может быть пустым",
      approveEmpty: "Пожалуйста, добавьте узел утверждения",
      nodeEmpty: "Данные узла не могут быть пустыми",
      tempNameRequire: "Пожалуйста, введите название шаблона",
      tableNameRequire: "Пожалуйста, введите название таблицы",
      refreshDatabase: "Пожалуйста подождите, библиотека обновляется",
      nowusebbs: "Имена подтаблиц не могут повторяться",
      tableNameNorepeat: "Названия таблиц не могут повторяться",
      moduleNameNorepeat: "Название шаблона не может повторяться",
      done: "Успешная операция",
      doneFailed: "операция не удалась",
      successZhengshi: "Успешно преобразован в официального клиента",
      selectZhengshi: "Пожалуйста, выберите клиента, которого необходимо преобразовать в официального",
      successGonghai: "Успешно преобразовано в клиента открытого моря",
      selectGonghai: "Пожалуйста, выберите клиента, которому необходимо перевестись в открытое море.",
      selectGiveData: "Пожалуйста, выберите данные для переноса",
      selectClassIs: "То, что вы выбрали, это",
      ifContinue: "Подтвердить, чтобы продолжить?",
      addTopClass: "Пожалуйста, сначала выберите основную категорию",
      cantclaimed: "Текущий счет за воду не может быть востребован",
      treeBelow: "Пожалуйста, выберите самый нижний уровень древовидной структуры, чтобы продолжить.",
      goodCanNotDelete: "В этом узле также есть товары, и его нельзя удалить.",
      orderNo: "Заказ на покупку не существует",
      addoccupy: "Пожалуйста, выберите детали, которые необходимо занять",
      onlyOneoccupy: "Можно выбрать только одну запись",
      occupyNum: "Пожалуйста, введите занятое количество",
      warehouseLocation: "Место хранения",
      productName: "наименование товара",
      currentInventory: "Текущий запас",
      purchaseQuantity: "Количество покупок",
      occupationQuantity: "Занимаемое количество",

      onlyOneTop: "Можно выбрать только одну главную таблицу верхнего уровня.",
      comfirmDelet: "Вы уверены, что хотите это удалить?",
      selectRow: "Пожалуйста, выберите строки, которые вы хотите переместить",
      onlyOneRowMove: "Для перемещения можно выбрать только один ряд",
      noEffectRow: "Номер линии назначения недействителен, введите его еще раз.",
      noMaxLine: "Невозможно превысить максимальное количество строк",
      fetchNoMove: "Подробные данные были вызваны и не могут быть удалены",
      checkError: "Исключение сведений о проверке",
      nodeNoGood: "На данный момент в этом узле нет товаров",
      languageNoRepeat: "Язык добавлен, пожалуйста, не добавляйте его снова!",
      printMessage: "Предварительный просмотр и печать невозможны в браузере IE. Чтобы открыть файл, используйте другие браузеры.",
      tableNoData: "Настройки процесса этого модуля не найдены, установите их и повторите попытку!",
      subassembly: "Дополнительные аксессуары:"
    },
    //登录
    loginPage: {
      login: "Авторизоваться",
      welCome: "Войдите, пожалуйста",
      password: "пароль",
      account: "счет"
    },
    GlobalConfig: {
      duplicationCheck: "настраивать",
      CustomerSwitch: "переключатель клиента",
      SupplierSwitch: "переключатель поставщика",
      GoodsSwitch: "Переключение продукта",
      FeedbackConfiguration: "базовая конфигурация",
      projectName: "название проекта",
      projectContact: "Номер контракта проекта",
      version: "номер версии",
      systemCallbackAddress: "Системный адрес обратного вызова",
      wincareAddress: "адрес WinCare",
      projectNamePlace: "Пожалуйста, заполните название проекта",
      projectContactPlace: "Пожалуйста, укажите номер контракта по проекту",
      wincareAddressPlace: "Пожалуйста, заполните адрес обратного вызова системы"
    },
    // 消息中心
    messageCenter: {
      addNewMessage: "Добавить новое сообщение",
      editNewMessage: "Редактировать сообщение",
      messageTitle: "Название сообщения",
      modelName: "имя модуля",
      messageContent: "Текстовый контент",
      remark: "Примечание",
      receiveBy: "Получатель",
      sendBy: "спонсор",
      createTime: "время создания",
      isRead: "Было ли оно прочитано?",

    },
    placeHolder: {
      expression: "Выражение формулы расчета",

      selectLanguage: "Пожалуйста, выберите язык",
      forTrackPlaceHolder: "Пожалуйста, введите номер заказа",
      start: "рост",
      end: "конец",
      to: "к",
      startDate: "Дата начала",
      endDate: "Дата окончания",
      selectDate: "выбор даты",
      startTime: "Время начала",
      endTime: "Время окончания",
      pleaseFill: "Пожалуйста входите",
      pleaseSelect: "пожалуйста, выбери",
      Financialrecognition: "Пожалуйста, выберите финансовое подтверждение",
      onWeek: "на прошлой неделе",
      onMonth: "прошлый месяц",
      threeMonth: "последние три месяца",
      contractNumber: "Номер экспортного контракта",
    },
    // 导航栏上方的操作栏
    user: {
      quit: "выход",
      noticeCenter: "Центр сообщений",
      preWarning: "предварительное оповещение",
      statistical: "Статистические отчеты",
      noticeAlert: "уведомление о сообщении",
      searchMenu: "Меню сообщений"
    },
    // 首页设置
    Home: {
      ToBeapproved: "Документы, ожидающие одобрения",
      realTimeData: "Данные в реальном времени", // 实时数据
      recents: "недавние посещения", // 最近访问

    },
    // 列表设置
    listOfSet: {
      batchExport: "Пакетный экспорт",
      ponoFieldExist: "Поле PONO не существует",
      attachmentManagement: "Управление импортом вложений",
      enableOcr: "Распознавание OCR",
      add: "Новый",
      clearCatch: "очистить кэш",
      loadField: "Загрузить поля",
      copyModule: "копировать модуль",
      search: "поиск",
      reset: "перезагрузить",
      edit: "редактировать",
      delete: "удалить",
      colWidth: "ширина колонки",
      moduleName: "имя модуля",
      mainTable: "Основная таблица",
      foreignKey: "внешний ключ",
      listSql: "список SQL",
      isImage: "картина",
      imageColumn: "В каком столбце картинка?",
      storedProcedure: "сформировать хранимую процедуру",
      isReadOnly: "Только просмотр",
      isInlineEditable: "Встроенное редактирование",
      isUpdateKeyOnImport: "Импорт обновлений",
      isCheckFno: "Убедитесь, что fno уникально",
      parentModule: "Улучшенный модуль",
      isPage: "Пагинация",
      contactSql: "связаться с sql",
      listStoredProcedure: "список хранимых процедур",
      checkStoredProcedure: "Проверка хранимой процедуры",
      isShowList: "Отображение списка",
      tableImgShow: "Отображение изображения таблицы",
      formImgShow: "Форма отображения изображения",
      isImport: "Импортировать",
      isExport: "Экспорт",
      isSendEmail: "отправить электронное письмо",
      isUploadFile: "Загрузить вложение",
      isBatchModify: "Пакетное редактирование",
      isAboutTypography: "Левая и правая верстка",
      isGlobalRetrieval: "глобальный поиск",
      tableFieldSet: "Настройки полей таблицы",
      formFieldSet: "Настройки полей формы",
      codeEventSet: "Настройки события кода",
      languageSet: "языковые настройки",
      sheetSet: "настройки страницы листа",
      isDbClkJump: "Дважды щелкните, чтобы ввести детали",
      isShowSubtotal: "Сгруппированный промежуточный итог",
      showTheApprovalTab: "Показать вкладку одобрения",
      passProcName: "через хранимую процедуру",
      rejectProcName: "Отклонить хранимую процедуру",
      pageSize: "Количество отображения по умолчанию на странице",
      addSuccess: "успешно добавлено",
      editSuccss: "обновление завершено",

      /* 字段设置 */
      fieldShowOrder: "Порядок отображения полей",
      databaseFieldName: "Имя поля базы данных",
      showName: "Отображаемое имя интерфейса",
      dataType: "тип данных",
      alignType: "Выравнивание",
      queryOperator: "оператор запроса",
      isSelect: "Запросить",
      isLocked: "запирание",
      isGlobalQuery: "глобальный поиск",
      isSum: "общий",
      isShow: "видимый",
      dataDict: "Словарь данных",
      datePrecision: "точность даты",
      language: "языковые настройки",
      databaseFields: "Имя поля базы данных",
      configFormula: "Редактор уравнений",
      initialization: "Модуль инициализации",
      initLanguage: "язык инициализации",
      //编码设置
      save: "держать",
      year: "Год (2 цифры)",
      years: "Год (4 цифры)",
      month: "месяц",
      date: "дата",
      fixedContent1: "Фиксированный контент 1",
      fixedContent2: "Фиксированный контент 2",
      serialNumber: "серийный номер",
      loginId: "Идентификатор пользователя для входа",
      serialNoDigit: "Цифры серийного номера",
      serialNoUt: "Единица нечетного числа",
      pattern: "Формат",
      formField1: "Поле формы 1",
      formField2: "Поле формы 2",
      formField3: "Поле формы 3",
      formField4: "Поле формы 4",
      formField5: "Поле формы 5",
      smallSerialStart: "Небольшой стартовый номер проточной воды",
      bigSerial: "Далюхао",
      resetSuccess: "Перезагрузить",
      soon: "ждать",
      strip: "полоска",

    },

    FormOfSet: {
      uploadDrag: "Перетащите файлы сюда или",
      clickUpload: "Нажмите, чтобы загрузить",
      uploadSize: "Размер файла не превышает",

      add: "Новый",
      fieldOrder: "Порядок отображения полей",
      name: "имя",
      showName: "отображаемое имя",
      defaultValue: "Добавить значение по умолчанию",
      expressionDecimalDigit: "зарезервированные цифры",
      dataType: "тип данных",
      editType: "Изменить тип",
      formConfigId: "Идентификатор конфигурации формы",
      isMustFill: "Необходимый",
      approveParam: "Отправить поле",
      approveChange: "Поля изменения утверждения",
      combinedField: "Общее поле",
      majorKey: "основной ключ",
      isShow: "видимый",
      isReadOnly: "только чтение",
      dataDict: "Словарь данных",
      remark: "Примечание",
      sheet: "Страница принадлежности листа",
      expression: "пользовательское выражение",
      clauseTemplete: "Модель шаблона терминов",
      widthType: "тип ширины",
      matchingCondition: "Пожалуйста, выберите критерии соответствия",
      noFileSelected: "Файлы не выбраны"
    },

    // 配置模块
    configMoudle: {
      filesManage: "Управление вложениями",
      tip: "намекать",
      ocr: "Распознавание OCR",
      tableExportName: "табличные данные",
      timedifference: "Разница во времени",
      nodeFinish: "Поле времени завершения узла",

      nodeFlow: "Процесс узла",
      waterKey: "Сяолюшуйкей",
      topLeval: "Вершина",

      operator: "оператор выбора",
      function: "Выберите функцию",
      selectfieldName: "Выберите поля",

      clear: "Прозрачный",
      fieldType: "Тип поля",
      resultField: "поле результата",

      formulaEditor: "Редактор уравнений",
      fieldDigit: "зарезервированные цифры",

      fieldLength: "длина поля",
      characteristic: "Свойства поля",
      dbname: "Имя базы данных",
      fieldName: "Имя поля",
      catalogue: "Оглавление",
      menu: "меню",
      button: "кнопка",

      male: "мужской",
      female: "женский",
      normal: "нормальный",
      diabled: "деактивировать",
      signOrNot: "Подпишешь?",
      tableName: "Имя таблицы",
      bulkEdit: "Пакетное редактирование",
      portDetails: "Детали документа",
      totalAll: "общий",
      noData: "Нет данных",
      multipleChoice: "Единый выбор",
      saveFirst: "Пожалуйста, сначала сохраните",
      uploadTypeTip: "Загружать изображения можно только в формате JPG или PNG!",

      uploadSuccess: "Загрузка успешна",
      uploadFail: "Загрузка не удалась",
      ForTrackRemark: "Примечания: Необходимо указать модуль и номер заказа, к которому он принадлежит. После завершения ввода нажмите «Запрос».",
      searchForTrack: "Запросить номер отслеживания",

      addFile: "Добавить приложение",
      updateFile: "Изменить вложение",

      moveLineNum: "Номер целевой строки",
      destLineNum: "Номер линии назначения",
      selctRowTip: "Пожалуйста, выберите строку для редактирования",
      selctRowTipDelet: "Пожалуйста, выберите строки, которые вы хотите удалить",

      getTemplate: "Получить шаблон",
      details: "Подробности",
      forModel: "Принадлежащий модуль",
      forTrack: "Принадлежащий номер заказа",
      filesManage: "Управление вложениями",
      serialNumber: "серийный номер",

      filesLoading: "Загрузка вложений",
      noConfigTotal: "Вы не настроили промежуточные итоги",
      noGroup: "Пожалуйста, выберите критерии группировки",
      noTotalField: "Пожалуйста, выберите поле промежуточного итога",
      Financialrecognition: "финансовое подтверждение",
      FinancialCancle: "Отменить финансовое подтверждение",

      noRepeat: "не может быть повторено",
      confirmRecall: "Следует ли вызывать текущие данные",
      recallSucces: "Вывод средств успешен",
      CategoryNum: "Номер категории",
      shareSuccess: "Поделитесь успехом",
      imageUpload: "Загрузка нескольких изображений",
      selectFillter: "Введите ключевые слова для фильтрации",
      inventoryNumber: "Номер складского заказа",
      selectYes: "да",
      selectNo: "нет",
      confirmDelete: "Удалить текущие данные?",
      transferOrder: "заказ на передачу",
      highSeasCustomers: "Клиенты открытого моря",
      customerMemorabilia: "Мероприятия для клиентов",
      formalCustomers: "Официальный заказчик",
      sendEmail: "отправить электронное письмо",
      excelCopy: "Копия Excel",
      claim: "требовать",
      add: "Новый",
      copyTokey: "Копировать в таблицу",
      copyToform: "скопировать в форму",
      edit: "редактировать",
      delete: "удалить",
      bulkEdit: "Пакетное редактирование",
      content: "Пожалуйста, введите содержание",
      selectDate: "Выберите дату",
      select: "пожалуйста, выбери",
      commodityTypeAdmin: "Управление категориями продуктов",
      parentType: "Родительская категория",
      TypeName: "название классификации",
      clickUpload: "Нажмите, чтобы загрузить",
      fileView: "Предварительный просмотр файла",
      Viewflie: "Предварительный просмотр",
      save: "держать",
      printFormat: "Формат печати",
      headSeal: "фирменный бланк штамп",
      cancel: "Отмена",
      print: "Распечатать",
      more: "Более",
      share: "общий",
      placeOnFile: "Архив",
      personnel: "Подбор персонала",
      personName: "персонал",
      classChange: "Изменение категории",
      uploadPicture: "загрузить изображение",
      billsPrint: "Печать документов",
      uploadPictures: "Загружайте изображения партиями",

      globalQuery: "глобальный поиск",
      modelName: "Имя Шаблона",
      search: "Находить",
      img: "картина",
      tip1: "Пожалуйста, введите название категории",
      tip2: "Успешно изменено",
      tip3: "Добавлено успешно",
      tip4: "Пожалуйста, выберите один",
      tip5: "Вы уверены, что хотите удалить текущую категорию и подкатегории?",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      tip6: "успешно удалено",
      refresh: "обновить",
      close: "закрытие",
      closeLeft: "закрыть слева",
      closeRight: "закрыть вправо",
      closeOther: "Закрыть другое",
      closeAll: "Закрыть все",


      view: "Проверять",
      versionView: "Просмотр версии",
      tip7: "Можно загрузить только формат Excel.",
      tip8: "Вы не являетесь создателем этого документа",
      tip9: "Документ находится на рассмотрении или одобрен.",
      tip10: "У вас нет выбранных строк",
      tip11: "Не проверяйте строки, которые были одобрены или находятся в процессе утверждения.",
      tip12: "Пожалуйста, выберите строку перед ее изменением",
      tip13: "Пожалуйста, выберите строку перед печатью",
      tip14: "Пожалуйста, выберите формат вывода отчета!",
      tip15: "Пожалуйста, выберите название модуля!",
      tip16: "Печать успешно",
      tip17: "загрузка успешна",

      approvalHistory: "История одобрений",
      purchaseContract: "Номер договора купли-продажи",
      supplierName: "Наименование поставщика",
      fileName: "название аксессуара",
      filePath: "Путь вложения",
      fileType: "Тип прикрепления",

      compilingAndbrushingLibraries: "Скомпилируйте и очистите библиотеку",
      moduleName: "имя модуля",
      mainTable: "Имя основной таблицы",
      subTableName: "Имя подтаблицы",
      subTable: "Имя таблицы подтаблицы",
      aboutMainTable: "Соответствующая основная таблица",
      createBy: "основатель",


      // 详情
      fetch: "Поиск",
      approval: "Одобрение",
      reject: "Одобрение отклонено",
      approvalOption: "Комментарии по утверждению",
      sendEmail: "отправить электронное письмо",
      Examination: "Одобрение",
      ExaminationSave: "Одобрить и сохранить",

      AnnextopurchaseContract: "Приложение к договору купли-продажи",
      Appendixtoexportcontract: "Приложение к экспортному контракту",
      uploadFile: "Загрузить вложение",
      report: "Отчет",
      includeFetchSql: "Содержит полученные",
      includeSqlFinish: "Содержит полученные данные",
      viewLargeImg: "Посмотреть увеличенное изображение",
      save: "держать",
      status: "состояние",
      copy: "копировать",
      topcopy: "Вверх",
      bottomcopy: "двигаться вниз",
      moveRow: "Переместить номер банка",
      update: "держать",
      submit: "представлять на рассмотрение",
      recall: "отзывать",
      list: "список",
      copyInvoice: "Копировать документ",
      // tip17: "Пожалуйста, выберите контент и нажмите «ОК».",
      tip18: "Страница обновлена, пожалуйста, повторно войдите на эту страницу и выполните эту операцию еще раз!",
      subTotalSet: "Итоговая конфигурация",
      openSubtotal: "Включить промежуточный итог",
      closeSubtotal: "Закрыть промежуточный итог",
      groupCondition: "Условия группировки",
      subtotalField: "Поле промежуточного итога",
      notSelected: "Не выбран",
      selected: "выбранный",
      goLeft: "Слева",
      goRight: "Направо",
      noFno: "Этот номер контракта не найден и не может быть перенаправлен",

      // Y-common
      linenum: "Номер строки",
      batchDelete: "пакетное удаление",
      replaceLineNumber: "Номер линии назначения",
      tip19: "Введенный вами номер строки недействителен.",
      tip20: "Статус одобрения в процессе...",
      tip21: "Пожалуйста, выберите данные, которые вы хотите удалить",
      tip22: "Для переноса строк можно выбрать только одну строку.",
      tip23: "Пожалуйста, выберите данные, которыми вы хотите поделиться",
      tip24: "Пожалуйста, выберите данные для архивации или разархивирования",
      tip25: "Выберите людей, с которыми хотите поделиться",
      tip26: "Отменить ли архивирование",
      tip27: "Разархивация прошла успешно",
      tip28: "Архивировано успешно",
      tipQuit: "Вы уверены, что хотите выйти?",
      sureToSave: "Если вы сохранили данные, нажмите ОК!",
      cancelFilter: "Отменить фильтр",

      SalesContract: "Договор экспортной продажи",
      PurchaseContract: "договор купли-продажи",
      alteration: "изменять",
      cancelAlteration: "Отменить изменения",

    },


    // 列表设置-----语言设置
    LanguageSet: {
      contentRequire: "Язык не может быть пустым!",
      contentRequire: "Содержимое не может быть пустым",
      add: "Новый",
      edit: "Исправлять",
      language: "язык",
      content: "содержание",
      operation: "действовать",
      simplifiedChinese: "Упрощенный китайский",
      confirm: "Конечно",
      cancel: "Отмена"
    },

    //列表设置-- sheet页设置
    SheetSetting: {
      name: "имя",
      AccordingToTheOrder: "Показать сортировку",
      add: "Новый"
    },

    // 调取设置
    FetchSet: {
      sequence: "заказ",
      add: "Новый",
      name: "имя",
      targetModuleId: "Идентификатор целевого модуля",
      parentModuleId: "Идентификатор улучшенного модуля",
      targetField: "целевое поле",
      colWidth: "Ширина столбца поля",
      isPaged: "Пагинация",
      fetchSql: "заявление о вызове",
      isShowImage: "отображать изображение",
      showColumns: "В каком столбце это отображается?",
      isRetrieval: "давать возможность",
      containsCalledSQL: "Содержит загруженный Sql",
      edit: "редактировать",
      delete: "удалить",
      languageSetting: "языковые настройки",
      tableFiledSetting: "Настройки полей таблицы",
      copySetting: "копировать",
      fetchWarinning: "Пожалуйста, заполните заявку на вызов!",
      cancel: "Отмена",
      confirm: "Конечно"
    },

    // 调取设置 ---- 调取字段设置
    FetchKeySet: {
      add: "Новый",
      fieldShowOrder: "Порядок отображения полей",
      databaseFieldName: "Имя поля базы данных",
      showName: "Отображаемое имя интерфейса",
      dataType: "тип данных",
      alignType: "Выравнивание",
      isSelect: "Запросить",
      queryOperator: "оператор запроса",
      isSortable: "Сортировать",
      isFetched: "Поиск",
      isLocked: "запирание",
      targetField: "целевое поле",
      isShow: "видимый",
      datePrecision: "точность даты",
    },

    // 首页设置
    homeSet: {
      homeSet: "Настройки главной страницы",
      roleNamePlaceHolder: "Пожалуйста, введите имя роли",
      warningSet: "Настройки уведомлений",
      reportSet: "Настройки отчета",
      earlyWarning: "предварительное оповещение",
      title: "заголовок",
      router: "маршрутизация",
      sql: "sql",
      languageSetting: "языковые настройки",
      save: "держать",
      select: "пожалуйста, выбери",
      inputPlaceHolder: "Пожалуйста, введите содержание",
      report: "Отчет",
      save: "держать",
      add: "Новый",
      edit: "редактировать",
      submitTip: "Данные для отправки не могут быть пустыми",
      saveTip: "Успешно сохранено",
      completeTip: "Пожалуйста, заполните данные и сохраните их перед установкой языка.",
      saveFailed: "Сохранить не удалось",
      qrSetting: "Настройки QR-кода",
      qrUpload: "Загрузка QR-кода приложения",
      qrEdit: "Редактирование информации QR-кода",
      qrPlaceholder: "Пожалуйста, введите информацию, которую вы хотите отредактировать",
      qrTips: "Пожалуйста, сначала загрузите фотографии",
      basicInfor: "основная информация",
      fontConfig: "Настройки шрифта",
      passWordConfig: "изменить пароль",
      languageConfig: "языковые настройки",
      fontSize: "Настройки размера страницы шрифта",
      fontFamily: "Настройка типа шрифта страницы",
      oldPassword: "Старый пароль",
      newPassword: "Новый пароль",
      languageSet: "языковые настройки",
      defaultFont: "по умолчанию",
      smallFont: "средний размер",
      largeFont: "большой",
      defultFont: "по умолчанию",
      song: "Династия Сун",
      kai: "обычный сценарий",
      yuan: "круглое тело",
      shijian: "Упрощенная поэзия",
      logoSetting: "Загрузка изображения логотипа",
      companySetting: "имя",
      loginBackgroundSetting: "Фоновое изображение для входа в систему",
    },

    // 报表权限设置
    ReportSet: {
      jimuReportPermissionSetting: "Настройки разрешений для отчетов о стандартных блоках",
      roleNamePlaceHolder: "Пожалуйста, введите имя роли",
      save: "держать",
      code: "серийный номер",
      reportName: "Название отчета",
      createTime: "время создания",
      warningTip: "Пожалуйста, выберите хотя бы один",
      saveTip: "Успешно сохранено",
      add: "Новый",
      edit: "редактировать",
    },

    // 字典设置
    DictSet: {
      searchButton: "поиск",
      resetButton: "перезагрузить",
      add: "Новый",
      edit: "редактировать",
      delete: "удалить",
      dictName: "Название словаря",
      isForbidden: "Запрещать",
      enabled: "нормальный",
      disAbled: "Запрещать",
      remark: "Примечание",
      dataItemSetting: "Настройки элемента данных",
      name: "имя",
      alias: "Псевдоним",
      sortOrder: "Заказ",
      desc: "описывать",
      dataPermission: "разрешение на данные",
    },

    // 菜单管理
    menuSet: {
      shangpinku: "Получить библиотеку продуктов",
      kucun: "Получить инвентарь",
      yushou: "Распределение авансовых поступлений",
      permisVies: "Просмотр разрешений",
      menuName: "Название меню",
      search: "поиск",
      reset: "перезагрузить",
      add: "Новый",
      edit: "редактировать",
      delete: "удалить",
      icon: "икона",
      type: "тип",
      orderNum: "Отобразить заказ",
      permsCode: "Идентификатор разрешения",
      remark: "Примечание",
      routingPath: "адрес маршрутизации",
      menuStatus: "Статус меню",
      addMenu: "Новое меню",
      menuType: "Тип меню",
      menuIcon: "Значок меню (монохромный)",
      menuIconColor: "Значок меню многоцветный",
      menuVisable: "Меню видно",
      menuOrder: "Порядок меню",
      parentCatalogue: "Родительский каталог",
      associatedModule: "Связанные модули",
      buttonName: "Название кнопки",
      language: "языковые настройки",
      action: "действовать",
      associateModule: "Связанные модули"
    },

    // 用户管理
    userAdmin: {
      deptPlace: "Пожалуйста, введите название отдела",
      userName: "имя пользователя",
      password: "пароль",
      loginAccount: "Вход в аккаунт",
      phoneNumber: "номер телефона",
      status: "состояние",
      createTime: "время создания",
      search: "поиск",
      reset: "перезагрузить",
      add: "Новый",
      passOn: "передача",
      passOnSuccess: "Перенос успешен",
      passOnPerson: "Целевое лицо заказа на трансфер",
      passOnMoudle: "Модуль заказа трансфера",

      deptName: "Название отдела",
      nickName: "Никнейм пользователя",
      englishName: "английское имя",
      phone: "номер телефона",
      email: "Электронная почта пользователя",
      roleName: "Роль",
      sex: "пол",
      postId: "Идентификатор позиции",
      avatar: "аватар",
      deptId: "Идентификатор отдела",
      passoword: "пароль",
      remark: "Примечание",
      dept: "отделение",
      edit: "редактировать",
      delete: "удалить",
      tip1: "Пожалуйста, выберите один для редактирования",
      loadingtip: "Загрузка, пожалуйста подождите...",
      tip2: "Пожалуйста, выберите один для удаления",
      tip3: "Подтвердите удаление",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      deleteSuccess: "успешно удалено!",
      editSuccess: "Успешно изменено",
      noEmpty: "Не может быть пустым",
      limitOfAuthority: "Разрешения",
      synchronousAssignmentGroup: "Группа синхронного назначения",
    },
    // 用户管理 --- 编辑用户
    editUserAdmin: {
      save: "держать",
      sync: "Синхронизировать",
      add: "Новый",
      userInfo: "Информация о пользователе",
      nickName: "Никнейм пользователя",
      englishName: "английское имя",
      password: "пароль",
      phoneNumber: "номер телефона",
      remark: "Примечание",
      loginAccount: "Вход в аккаунт",
      userEmail: "Электронная почта пользователя",
      postId: "Идентификатор позиции",
      dept: "отделение",
      roleName: "Роль",
      superiorLeaders: "Начальники",
      sex: "пол",
      status: "состояние",
      emailConfig: "Конфигурация электронной почты",
      dataPermission: "разрешение на данные",
      modelName: "имя модуля",
      groupName: "Имя группы",
      groupCode: "код группы",
      edit: "редактировать",
      delete: "удалить",
      loadingtip: "Загрузка, пожалуйста подождите...",
      saveSuccess: "Успешно сохранено",
      emailUserName: "Имя отправителя",
      emailPassword: "пароль от электронной почты",
      smtpHost: "SMTP-адрес",
      smtpPort: "SMTP-порт",
      signature: "знак",
      ownPhone: "Частный мобильный номер",
      qq: "QQ",
      weChat: "Вичат",
      platformName: "Название платформы",
      idcard: "идентификационный номер",
      isEmailManage: "Это администратор почтового ящика?",
      noPicture: "Пока нет фотографий"

    },



    // 在线用户
    userOnline: {
      userName: "имя пользователя",
      name: "китайское имя",
      search: "поиск",
      reset: "перезагрузить",
      kickOut: "выгнать",
      email: "Почта",
      phone: "телефон",
      sex: "пол",
      loginConputerIp: "IP-адрес компьютера для входа",
      loginTime: "Время входа",
      nowTime: "Продолжительность входа в систему",
      status: "состояние",
      remark: "Примечание",
      tip1: "Вы уверены, что хотите выгнать этого пользователя?",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      tip2: "Пожалуйста, выберите сначала, прежде чем пинать!",
      tip3: "Пользователь выгнан"
    },


    // 角色管理
    roleAdmin: {
      addRole: "Увеличение разрешений",
      cancleRole: "Отменить разрешение",
      tip1: "Вы уверены, что хотите удалить отмеченную роль?",
      menuAllot: "Назначение меню",
      roleName: "Имя роли",
      status: "состояние",
      search: "поиск",
      reset: "перезагрузить",
      add: "Новый",
      save: "держать",
      showSort: "Отобразить заказ",
      roleStatus: "статус персонажа",
      permissionChar: "символы разрешения",
      permissionScope: "Объем полномочий",
      dataPermission: "разрешение на данные",
      dataScope: "диапазон данных",
      remark: "Примечание",
      edit: "редактировать",
      delete: "удалить",
      tip2: "Не может быть пустым",
      tip3: "Пожалуйста, выберите перед удалением",
      tip4: "Пожалуйста, выберите меню для изменения заданий",
      tip5: "Успешно сохранено"
    },

    // 部门管理
    deptAdmin: {
      deptName: "Название отдела",
      status: "состояние",
      search: "поиск",
      reset: "перезагрузить",
      add: "Новый",
      deptCode: "Код отдела",
      departmentHead: "глава отделения",
      contactWay: "Контактная информация",
      showSort: "Отобразить заказ",
      createTime: "время создания",
      addDept: "Добавить новый отдел",
      superiorDepartment: "Высшая должность",
      edit: "редактировать",
      delete: "удалить",
      tip1: "Вы уверены, что хотите удалить выбранный отдел?",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      tip2: "Пожалуйста, выберите сначала перед удалением!",
      tip3: "успешно удалено"
    },

    // 部门管理
    deptAdmin: {
      deptName: "Название отдела",
      status: "состояние",
      search: "поиск",
      reset: "перезагрузить",
      remark: "Примечание",
      add: "Новый",
      deptCode: "Код отдела",
      departmentHead: "глава отделения",
      contactWay: "Контактная информация (номер мобильного телефона)",
      showSort: "Отобразить заказ",
      createTime: "время создания",
      addDept: "Добавить новый отдел",
      superiorDepartment: "Высшая должность",
      edit: "редактировать",
      delete: "удалить",
      tip1: "Вы уверены, что хотите удалить выбранный отдел?",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      tip2: "Пожалуйста, выберите сначала перед удалением!",
      tip3: "успешно удалено"
    },

    //打印管理
    printAdmin: {
      add: "Новый",
      modelNumber: "Серийный номер модуля",
      modelCode: "код модуля",
      modelId: "Идентификатор модуля",
      mainTableName: "Имя основной таблицы",
      modelName: "имя модуля",
      reportName: "Название отчета",
      idField: "поле идентификатора",
      remark: "Примечание",
      edit: "редактировать",
      delete: "удалить",
      tip1: "Вы уверены, что хотите удалить проверенный модуль?",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      tip2: "Пожалуйста, выберите перед удалением",
      tip3: "успешно удалено",
      classify: "Классификация",
      printer: "принтер",
      closeConfirm: "Вы сохранили перед закрытием?"
    },
    // 系统日志
    Systemlog: {
      tip: "намекать",
      requestParams: "Параметры запроса",
      returnParams: "Возвращаемые параметры",
      module: "модуль",
      status: "состояние",
      message: "информация",
      requestMethodName: "Имя метода запроса",
      requestIp: "Запросить IP",
      requestWay: "Метод запроса",
      bussinessType: "Вид бизнеса",
      cancel: "Отмена",
      confirm: "Конечно",
      search: "поиск",
      reset: "перезагрузить",
      add: "Новый",
      delete: "удалить",
      tip1: "Вы уверены, что хотите удалить отмеченные параметры?",
      tip2: "Пожалуйста, выберите перед удалением",
      tip3: "удалитьУспех"
    },

    // 模板类型
    termsTemplete: {
      add: "Новый",
      templeteName: "Имя Шаблона",
      templeteContent: "Содержимое шаблона",
      content: "Пожалуйста, введите содержание",
      templeteType: "тип шаблона",
      createBy: "основатель",
      createTime: "время создания",
      updateBy: "средство обновления",
      updateTime: "Время обновления",
      selectDate: "Выберите дату",
      cancel: "Отмена",
      confirm: "Конечно",
      search: "поиск",
      reset: "перезагрузить",
      delete: "удалить",
    },

    // 预警设置
    warning: {
      content: "Пожалуйста, введите содержание",
      select: "пожалуйста, выбери",
      warningTitle: "Название оповещения",
      isPage: "Пагинация",
      warningSql: "Оповещение SQL",
      CustomMenuName: "Имя пользовательского меню",
      remark: "Примечание",
      showOrder: "Отобразить заказ",
      isShow: "видимый",
      cancel: "закрытие",
      confirm: "Конечно",
      add: "Новый",
      edit: "редактировать",
      batchDeletes: "пакетное удаление",
      delete: "удалить",
      languageSet: "языковые настройки",
      warningFieldSet: "Настройки поля оповещения",
      tip1: "Пожалуйста, выберите данные, которые вы хотите удалить",
      tip2: "добавитьSuccess",
      warningName: "Название оповещения",
      emptyContent: "Нажмите на название отчета слева, чтобы выбрать отчет.",
    },

    // 预警设置---预警字段设置
    warnFieldSet: {
      databaseName: "Имя поля базы данных",
      search: "Находить",
      fieldShowOrder: "Порядок отображения полей",
      datebaseField: "Имя поля базы данных",
      showName: "Отображаемое имя интерфейса",
      dataType: "тип данных",
      select: "пожалуйста, выбери",
      alignType: "Выравнивание",
      isSelect: "Запросить",
      QueryOperator: "оператор запроса",
      isLock: "запирание",
      datePrecision: "точность даты",
      isShow: "видимый",
      cancel: "Отмена",
      confirm: "Конечно",
      add: "Новый",
      edit: "редактировать",
      languageSet: "языковые настройки",
      tip1: "Пожалуйста, выберите данные, которые вы хотите удалить",
      tip2: "успешно добавлено",
    },


    // 流程模型
    flowModel: {
      name: "имя",
      search: "поиск",
      reset: "перезагрузить",
      add: "Новый",
      flowKey: "ПроцессКей",
      version: "Версия",
      createTime: "время создания",
      updateTime: "Время обновления",
      metadata: "метаданные",
      edit: "редактировать",
      delete: "удалить",
      upload: "развертывать",
      download: "Экспорт",
      downLoad: "скачать",
      addFlowModel: "Добавить модель процесса",
      category: "Классификация",
      desc: "описывать",
    },

    // 流程定义
    flowDefinition: {
      operation: "действовать",
      moduleId: "идентификатор модуля",
      moduleName: "имя модуля",
      programmeId: "Идентификатор схемы",
      programmeName: "Имя схемы",
      processDefinitionKey: "Ключ экземпляра процесса",
      remark: "Примечание",
      tip4: "Вы уверены, что хотите удалить проверенный модуль?",
      tip5: "Пожалуйста, выберите перед удалением",
      tip6: "Успешно сохранено",
      save: "держать",
      active: "активация",
      hangUp: "вешать",
      transferModel: "Трансферная модель",
      delete: "удалить",
      upload: "развертывать",
      category: "Классификация",
      select: "пожалуйста, выбери",
      selectFile: "Выберите документ",
      clickUpload: "Нажмите, чтобы загрузить",
      cancel: "Отмена",
      confirm: "Конечно",
      flowChart: "блок-схема",
      flowKey: "ПроцессКей",
      flowName: "Имя процесса",
      search: "поиск",
      reset: "перезагрузить",
      flowId: "Идентификатор процесса",
      version: "Версия",
      flowDesc: "Описание процесса",
      uploadTime: "Время развертывания",
      flowDefinition: "определение процесса",
      deploymentProcessDefinition: "Определение процесса развертывания",
      flowDefinitionStatus: "статус определения процесса",
      edit: "редактировать",
      delete: "удалить",
      actived: "активирован",
      tip1: "Вы уверены, что удалите этот процесс?",
      tip: "намекать",
      confirm: "Конечно",
      cancel: "Отмена",
      tip2: "успешно удалено",
      tip3: "Пожалуйста, выберите категорию перед отправкой",
      add: "Новый",
      selectModule: "Сначала выберите имя модуля",
      selectProgramme: "Сначала выберите название плана"

    },

    // 审批方案
    programmeSet: {
      name: "имя",
      search: "поиск",
      reset: "перезагрузить",
      jointTrial: "Будет ли суд",
      add: "Новый",
      groups: "Группа одобрения",
      Appoint: "Назначить утверждающего",
      nodeSetTip: "Пожалуйста, выберите узел утверждения",
      nodeSet: "Настройки узла утверждения",
      programmeSetAdd: "Добавить новый план",
      programmeSetEdit: "Изменить план",
      programmeSetName: "Имя схемы",
      programmeSetCode: "Номер плана",
      approvalNode: "Узел утверждения",
      version: "Версия",
      createTime: "время создания",
      updateTime: "Время обновления",
      metadata: "метаданные",
      edit: "редактировать",
      delete: "удалить",
      upload: "развертывать",
      download: "Экспорт",
      addFlowModel: "добавить в",
      category: "Классификация",
      desc: "описывать",
    },


    // 审批
    Approval: {
      normal: "нормальный",
      remove: "удалить",
      auditor: "рецензент",
      backTo: "Возвратный узел",
      tipNode: "Если не выбрано, оно будет возвращено отправителю по умолчанию!",
      operation: "действовать",
      agree: "соглашаться",
      reject: "отказываться",
      history: "история",
      schedule: "расписание",
      view: "Проверять",
      confirm: "Конечно",
      cancel: "Отмена",
      approvalOption: "Комментарии по утверждению",
      approvalProcess: "Процесс утверждения",
      approvalHistory: "История одобрений",
      approveParam: "Параметры утверждения",
      scheduleChart: "график прогресса",
      initiator: "спонсор",
      moduleName: "имя модуля",
      search: "поиск",
      reset: "перезагрузить",
      refresh: "обновить",
      approvalAgree: "Одобренный",
      approvalReject: "Одобрение отклонено",
      approvalTime: "Время обработки",
      // assignee: "назначен на",
      approvalParam: "Параметры утверждения",
      taskName: "название миссии",
      assignee: "утверждающий",
      startTime: "Время начала",
      endTime: "Время окончания",
      durationTime: "кропотливый",
      refreshSuccess: "Обновить успешно",
      refressFail: "Обновить не удалось",
      tip1: "Пожалуйста, установите флажок, прежде чем согласиться!",
      tip2: "Пожалуйста, проверьте это, прежде чем приступить к операции отклонения!",
      viewInvoices: "Проверять",
      print: "Распечатать",
      approvalStatus: "Статус утверждения",
      tip44: "Пожалуйста, заполните комментарии к одобрению",
      initiationDate: "Дата запуска",
      fno: "Номер документа",
      save: "держать",
      status: "состояние",
      billStatus: "состояние",
    },

    // 订单进度管理
    Order: {
      orderProgressTable: "график заказов",
      progress: "расписание",
      operation: "действовать",
      viewDetail: "проверьте детали",
      orderProgressChart: "График выполнения заказа",
      contractNumber: "Номер контракта",
      contractDate: "дата контракта",
      customerName: "имя клиента",
      search: "поиск",
      reset: "перезагрузить",
      refresh: "обновить",
      deliveryDate: "Дата доставки",
      destinationPort: "Место назначения",
      shipmentPort: "порт отгрузки",
      createBy: "руководитель",
      progress: "расписание",
      addNode: "Добавить новый узел",
      confirmNode: "Подтвердить узел",
      linkName: "Имя ссылки",
      computeMode: "Расчет",
      basisNode: "По данным узла",
      Days: "требуемые дни",
      createDate: "Дата создания",
      chargePersion: "Ответственный",
      status: "состояние",
      edit: "редактировать",
      delete: "удалить",
      selectNode: "Выберите узел",
      confirm: "подтверждать",
      cancel: "Отмена",
      sendSuccess: "Успешно отправлено",
      addNodes: "Добавить библиотеку узлов",
      nodeName: "Имя узла",
      nodeEndCondition: "конечное условие узла",
      nodeEndConditionSql: "Условный оператор завершения узла",
      nodesName: "Имя библиотеки узлов",
      createByName: "Имя создателя",
      createTime: "время создания",
      tip1: "Вы уверены, что хотите удалить этот узел?",
      tip: "намекать",
      saveSuccess: "Успешно сохранено",
    },

    /* 左侧菜单 */
    Menu: {
      messageCenter: "Центр сообщений",
      nodeSettings: "Настройки узла",
      businessFlowTracking: "Отслеживание бизнес-потоков",
      SystemAdmin: "Управление системой",
      ListSet: "Список настроек",
      FetchSet: "Получить настройки",
      HomeSet: "Настройки главной страницы",
      home: "титульная страница",
      ReportPermissSet: "Настройки разрешений для отчетов",
      dataDict: "Словарь данных",
      category: "Классификационный словарь",
      menuAdmin: "Управление меню",
      userAdmin: "Управление пользователями",
      userOnline: "онлайн-пользователь",
      roleAdmin: "управление ролями",
      deptAdmin: "Управление отделом",
      printAdmin: "Управление печатью",
      systemLog: "Системный журнал",
      termsTemplete: "Шаблон условий",
      warningSet: "Настройки уведомлений",
      fieldSet: "Настройки поля",
      dataDictSet: "Настройки элемента словаря данных",
      fetchFieldSet: "Получить настройки поля",
      sheetSet: "настройки страницы листа",
      formSet: "Настройки формы",
      codeSet: "настройки кодирования",
      editUser: "Изменить пользователя",
      languageSet: "языковые настройки",
      versionView: "Просмотр версии",
      persionCenter: "Персональный центр",
      warnPermissSet: "Настройки разрешений для оповещений",
      warning: "предварительное оповещение",
      warningFieldSet: "Настройки поля оповещения",
      businessModel: "бизнес-моделирование",
      systemSet: "Настройки системы",
      logoSet: "Настройки логотипа",
      loginSet: "Настройки фона входа в систему",

      // 流程菜单
      flowMenu: "Меню процесса",
      flowDefinition: "определение процесса",
      flowConfig: "Конфигурация процесса",
      flowModel: "модель процесса",
      modelOnlineDesign: "Разработка процесса",
      addFlowConfig: "Добавить конфигурацию процесса",
      editFlowConfig: "Редактировать конфигурацию процесса",
      programmeSet: "Настройки решения",
      programmeSetAdd: "Добавить новый план",
      programmeSetEdit: "Изменить план",

      // 审批管理
      approvalAdmin: "Управление одобрениями",
      peddingApproval: "В ожидании",
      approval: "Одобренный",
      Initiated: "Инициировано",
      CCRecord: "запись CC",

      // 订单进度管理
      orderProgressAdmin: "Управление ходом выполнения заказа",
      orderProgressList: "Список хода выполнения заказа",
      orderNodes: "Заказать библиотеку узлов",
      orderProgressDetail: "Подробности о ходе заказа",
      statisticsReport: "Статистические отчеты",

      // 代码事件配置
      codeEventSet: "Конфигурация событий кода",

    },
    // 嵌套BI系统
    Bisystem: {
      BiName: "Д"
    },
    StatisticalManagement: {
      StatisticalManagement: "Статистическое управление",
    },
    // 邮件模块
    Emails: {
      edit: "редактировать",
      sign: "Настройки подписи",
      folders: "Настройки папки",
      eName: "Почтовая система",
      write: "написать электронное письмо",
      contact: "Адресная книга",
      inbox: "входящие",
      outbox: "Исходящие",
      drafts: "черновой ящик",
      trash: "мусорное ведро",
      folders: "папка",
      searchFolder: "Поиск папки",
      label: "почтовая этикетка",
      settings: "Настройки электронной почты",
      please: "Пожалуйста войдите",
      login: "Авторизоваться",
      loginsuccess: "Авторизация успешна",
      loginfailed: "Ошибка входа",
      selectAccount: "Выберите аккаунт",
      signContent: "Подпись контента",

      account: "счет",
      password: "пароль",
      notnull: "Учетная запись или пароль не могут быть пустыми.",
      cancel: "Отмена",
      go: "Конечно",
      register: "регистр",
      regSuccess: "Вы успешно зарегистрировались",
      khname: "Название компании",
      copy: "СС",
      secret: "СК",
      addcopy: "добавить копию",
      addsecret: "Добавить скрытую копию",
      delcopy: "Удалить CC",
      delsecret: "Удалить скрытую копию",
      addressee: "получатель",
      sendName: "Имя отправителя",

      copyName: "СС",
      linkTips: "Если контакта еще нет, перейдите в адресную книгу, чтобы добавить его, или введите его напрямую.",
      theme: "тема",
      upload: "Загрузить вложение",
      clearFiles: "очистить список",
      numsTips: "Количество выбранных вложений:",
      sizeTips: "Общий размер списка вложений:",
      readReceipt: "Прочитать квитанцию",
      Sender: "отправитель",
      pickSender: "Пожалуйста, выберите отправителя",
      send: "отправлять",
      save: "сохранить черновик",
      reset: "перезагрузить",
      addresseeEmpty: "Получатель не может быть пустым",
      themeEmpty: "Тема не может быть пустой",
      senderEmpty: "Отправитель не может быть пустым",
      senderNull: "Отправителя пока нет. Чтобы добавить его, перейдите в настройки электронной почты.",
      textEmpty: "Содержимое отправки не может быть пустым.",
      sendSuccess: "Успешно отправлено",
      sendFail: "Не удалось отправить",
      saveSuccess: "Черновик успешно сохранен.",
      saveFail: "Не удалось сохранить черновик.",
      refresh: "обновить",
      themeKeyword: "Ключевые слова темы",
      contentKeyword: "Ключевые слова контента",
      senderKeyword: "Выберите отправителя",
      getAll: "выбрать все",
      clearAll: "снять все",
      oneByone: "Массовое единичное значимое",
      generatedBy: "ИИ пишет письма",
      languageSet: "Язык перевода",
      transLate: "переводить",
      promotional: "Письмо о развитии",
      exampleContent: "Пример: Пожалуйста, создайте для меня письмо о развитии внешней торговли для клиентов в сфере розничной торговли. Меня зовут Чжан Сан, я из компании xxx. Наш основной продукт и услуга — программное обеспечение для внешней торговли, а целевой рынок — Юго-Восточная Азия.",
      addMails: "Вставить в письмо",

      readStatus: "статус чтения",
      all: "все",
      theLastDay: "последний день",
      lastThreeDays: "последние три дня",
      lastSevenDays: "последние семь дней",
      lastThirtyDays: "Последние 30 дней",
      lastNinetyDays: "Последние 90 дней",
      lastOneHundredEightyDays: "Последние 180 дней",
      unread: "не прочитано",
      read: "Прочитал",
      removed: "удалено",
      receive: "перенимать",
      delete: "удалить",
      emailNums: "Общее количество сообщений",
      emailUnread: "непрочитанное письмо",
      receiving: "Получение...",
      receiveFail: "Прием не удался",
      receiveSuccess: "Получено успешно",
      emailDetails: "Нажмите, чтобы просмотреть сведения об электронной почте",
      delTips: "Пожалуйста, выберите перед удалением",
      delSuccess: "успешно удалено",
      delFail: "не удалось удалить",
      contactsList: "Список контактов",
      contactsManage: "Управление контактами",
      categoryManage: "Управление категориями",
      categoryName: "название классификации",
      customerName: "имя клиента",
      name: "Имя",
      customerCode: "Клиентский код",
      email: "Почта",
      operation: "действовать",
      categoryParent: "Подзаголовки",
      phone: "телефон",
      reply: "отвечать",
      replyAll: "Ответить всем",
      date: "время",
      saving: "держать",
      pickCategory: "Выберите категорию",
      edit: "Исправлять",
      cateTips: "Пожалуйста, выберите один",
      chooseAnItem: "Можно выбрать только один вариант",
      writeCategoryName: "Пожалуйста, введите название категории",
      nameMailTips: "Пожалуйста, заполните свое имя и адрес электронной почты",
      recoverySuccess: "Восстановление успешно",
      recoveryFail: "Восстановление не удалось",
      newFolder: "Добавить новую папку",
      editFolder: "Редактировать папку",

      addFolderTips: "Пожалуйста, введите имя папки",
      folderName: "Имя папки",
      addSuccess: "успешно добавлено",
      addFail: "Не удалось добавить",
      emailSetting: "а",
      signSetting: "Настройки подписи",
      folderSetting: "Настройки папки",
      displayName: "отображаемое имя",
      senderName: "Имя отправителя",
      emailAddress: "Адрес электронной почты",
      required: "Пожалуйста заполните",
      addSetting: "Добавить новые настройки",
      requiredSelect: "пожалуйста, выбери",
      selectMoveWarning: "Пожалуйста, выберите сообщения для перемещения",
      moveSuccess: "Перенесено успешно",
      maveFailed: "Переместить не удалось",
      sendingServer: "отправляющий сервер",
      Authorization: "Пароль авторизации",
      sendserverType: "Тип сервера (получение)",
      serverAddress: "адрес сервера",
      sendserverAddress: "Адрес сервера (отправка)",
      isDefault: "Это по умолчанию",
      enable: "Включить ли",
      port: "порт",
      sendTime: "Отправить время",
      originalMail: "Исходное сообщение",
      receiveAll: "Списание средств со всех счетов",
      receiveMailPick: "Пожалуйста, выберите счет для списания средств",
      receiveMailTime: "Пожалуйста, выберите период времени, за который вы хотите взимать плату",
      receiveEmail: "Варианты оплаты",
      emailNums: "Количество сообщений",
      relatedModules: "Связанные модули",
      moduleName: "имя модуля",
      fno: "Документ №",
      selectReportFile: "Выберите вложение отчета",
      uploaded: "Загруженные вложения",
      uploadSuccess: "Приложение отчета успешно загружено",
      uploadFailed: "Не удалось загрузить вложение отчета.",
      selectModelName: "Пожалуйста, выберите имя модуля",
      selectPrintFormat: "Пожалуйста, выберите формат печати",
      readTime: "время чтения",
      readAddress: "область",
      readCount: "количество раз прочтения",
      readCount: "количество раз прочтения",
      markAllAsRead: "отметить все как прочитанное",
      markSelectedAsRead: "Отметьте галочкой как прочитанное",
      markAllAsReadTip: "Вы уверены, что хотите пометить все сообщения как прочитанные?",
      markSelectedAsReadtip: "Вы уверены, что хотите пометить выбранное письмо как прочитанное?",
      markedSuccess: "Отметить успешно",
      markedFail: "Маркировка не удалась",
      moveto: "Перейти к",
      emailForward: "Вперед",
      emailTemplate: "Шаблон электронного письма",
      addTemplate: "Добавить шаблон",
      EditTemplate: "Редактировать шаблон",

      restore: "Вы уверены, что хотите восстановить это сообщение?",
      emailSet: "Конфигурация электронной почты",
      noAccount: "Вы еще не настроили учетную запись электронной почты",
      goToSet: "Перейдите в настройки",
      singleForwarding: "Поддерживается только пересылка одного элемента",
      attachments: "приложение",
      download: "скачать",
      cancel: "закрытие",
      selectTheMailTemplate: "Выберите шаблон письма",
      name: "имя",
      defaultExpression: "Смайлик по умолчанию",
      receivingAccount: "Пожалуйста, выберите получающий счет",
      pleaseSelectAnAccount: "Пожалуйста, выберите аккаунт",
      pleaseEnterYourSignature: "Пожалуйста, введите содержание подписи",
      pleaseFillInTheName: "Пожалуйста, заполните имя",
      pleaseFillInTheContent: "Пожалуйста, заполните содержание",
      targetLanguage: "Пожалуйста, выберите целевой язык",
      english: "Английский",
      french: "Французский",
      spanish: "испанский",
      japanese: "Японский",
      korean: "Корейский",
      burmese: "бирманский",
      thai: "тайский",
      russian: "Русский",
      servicesProvidedByOpenai: "Обслуживается Openai",
    },
    Marketing: {
      explosibility: "Общая сумма, которую можно выдать",
      alreadySent: "Общая сумма выдано",
      remaining: "Остальное можно отправить",
      screening: "фильтр",
      reset: "перезагрузить",
      sendEmail: "отправить электронное письмо",
      pleaseEnterSendEmail: "Пожалуйста, введите, чтобы отправить электронное письмо",
      missionDataOverview: "Обзор данных миссии",
      marketingNumber: "Маркетинговый номер",
      sendingNumber: "Количество отправленных людей",
      deliveryNumber: "Количество обслуженных человек",
      unpackNumber: "Открытое количество человек",
      recoverNumber: "Количество ответов",
      taskName: "название миссии",
      add: "Новый",
      remove: "удалить",
      selected: "выбрано",
      PCS: "индивидуальный",
      underWay: "в ходе выполнения",
      complete: "Заканчивать",
      creationTime: "время создания",
      startSending: "Начать отправку",
      sendComplete: "Отправка завершена",
      lastUpdated: "последнее обновление",
      taskDeletion: "Пожалуйста, выберите задачу для удаления!",
      isDeleteCheck: "Удалить выбранные данные?",
      tip: "намекать",
      senderSettings: "Настройки доставки",
      contentEditing: "редактировать контент",
      sendSetting: "Отправить настройки",
      taskType: "Тип задачи",
      developmentLetter: "Письмо о развитии",
      companyIntroduction: "Профиль компании",
      productIntroduction: "Описание продукта",
      longTermConnection: "долгосрочный контакт",
      seasonsGreetings: "праздничные поздравления",
      other: "другой",
      senderNickname: "Ник отправителя",
      sendingAddress: "адрес отправки",
      sendingType: "Тип отправки",
      none: "никто",
      sendImmediately: "отправить немедленно",
      automatedMarketing: "Автоматизированный маркетинг",
      replyEmail: "Ответное письмо",
      correctEmail: "правильный адрес электронной почты",
      type: "тип",
      addressee: "получатель",
      replyStopSending: "Ответ прекратить отправку",
      readStoppedSending: "Читать прекратить отправку",
      pleaseEnter: "Пожалуйста входите",
      pleaseSelect: "пожалуйста, выбери",
      select: "выбирать",
      optional: "Необязательный",
      confirm: "подтверждать",
      cancel: "Отмена",
      lastStep: "Предыдущий",
      nextStep: "Следующий шаг",
      immediatelyEnable: "Активировать сейчас",
      emailSubject: "Тема письма",
      outgoingInterval: "интервал отправки",
      subjectName: "Название темы",
      theme: "тема",
      controls: "действовать",
      editor: "редактировать",
      successfulOperation: "Успешная операция",
      successfulReplication: "Скопировано успешно",
      operationFailure: "операция не удалась",
      confirmToDelete: "подтвердить удаление?",
      mailSubjectName: "Имя темы электронного письма",
      insertVariable: "вставить переменную",
      emailContent: "содержание электронного письма",
      uploadAttachment: "Загрузить вложение",
      commodityList: "Список продуктов",
      templateSelection: "Выбор шаблона",
      AILetterWriting: "буквы ИИ",
      AIWrite: "ИИ пишет письма",
      variable: "переменная",
      toggle: "выключатель",
      for: "для",
      language: "язык",
      english: "Английский",
      french: "Французский",
      spanish: "испанский",
      japanese: "Японский",
      korean: "Корейский",
      burmese: "бирманский",
      thai: "тайский",
      russian: "Русский",
      day: "небо",
      remark: "Примечание",
      isUnsubscribe: "Включить ли окончание отказа от подписки",
      companyName: "Название компании",
      languageUsed: "Язык, на котором выводятся электронные письма",
      productName: "наименование товара",
      toneOfMail: "Звук электронной почты",
      business: "бизнес",
      moreFormal: "более формально",
      politeness: "вежливый",
      formal: "формальный",
      festivalName: "название праздника",
      wordLimit: "Лимит слов",
      unlimitedWordCount: "Нет ограничения по словам",
      intraWord: "В словах",
      generalDescription: "Общее описание",
      send: "отправлять",
      insertIntoAMessage: "вставить в сообщение",
      copyContent: "Копировать контент",
      examples: "Пример: Пожалуйста, создайте для меня письмо о развитии внешней торговли для клиентов в сфере розничной торговли. Меня зовут Чжан Сан, я из компании xxx. Наш основной продукт и услуга — программное обеспечение для внешней торговли, а целевой рынок — Юго-Восточная Азия.",
      product: "товар",
      templateName: "Имя Шаблона",
      hostedDataOverview: "Обзор размещенных данных",
      mailUserGrouping: "Группа пользователей почты",
      addGroup: "Создать новую группу",
      deleteGroup: "Удалить группу",
      serialNumber: "серийный номер",
      userGroup: "группа пользователей",
      creationDate: "Дата создания",
      instructions: "иллюстрировать",
      name: "имя",
      mailbox: "Почта",
      groupName: "Имя группы",
      userMailbox: "Электронная почта пользователя",
      userGroupName: "Имя группы пользователей",
      refresh: "обновить",
      deletingUserGroup: "Пожалуйста, выберите группу пользователей, которую необходимо удалить!",
      isCheckDeleteUserGroup: "Вы уверены, что хотите удалить выбранную группу пользователей?",
      successfullyDeleted: "успешно удалено!",
      saveSuccessfully: "Успешно сохранено !",
      success: "успех",
      search: "поиск",
      personalTemplate: "Персональный шаблон",
      mailTemplateName: "Название шаблона электронной почты",
      privatelyOwned: "частный",
      publicity: "общественный",
      isPublicTemplate: "Делать ли шаблон общедоступным",
      templateTopic: "Тема шаблона",
      templateContent: "Содержимое шаблона",
      templateRemarks: "Примечания к шаблону",
      saveTemplate: "Сохранить шаблон",
      NOTranslationPicture: "Контент, содержащий изображения, не может быть переведен!",
      marketingTask: "Маркетинговые задачи",
      setbasicInformation: "Установите основную информацию",
      submitScheme: "Отправьте и подтвердите план",
      trusteeshipScheme: "Планы, требующие хостинга",
      view: "Проверять",
      edit: "Исправлять",
      addCustomScheme: "Создать новый индивидуальный план",
      outgoingMailbox: "Отправка электронной почты",
      replyEmail: "Ответ на электронное письмо",
      recipientEmailAddress: "Адрес электронной почты получателя",
      industry: "Промышленность",
      textile: "текстиль",
      homeFurnishings: "Дом",
      mailLanguage: "Язык электронной почты",
      confirmationMarketingLetter: "Пожалуйста, подтвердите маркетинговое письмо",
      tip1001: "Осуществляйте развитие клиентов с помощью нескольких раундов маркетинга, каждый из которых фокусируется на разных фокусах и автоматически отправляет письма в запланированное время получателям, которые не ответили.",
      startCircularMarketing: "Начать круговой маркетинг",
      change: "Измени это",
      addScheme: "Создать новый план",
      schemeName: "Имя схемы",
      templateLibrary: "Библиотека шаблонов",
      generateTemplate: "Создать шаблон",
      intervalTime: "Интервалы",
      triggerCondition: "Условия срабатывания",
      NoReplyYet: "Без ответа",
      unread: "не прочитано",
      translation: "Перевести на целевой язык",
      description: "описывать",
      save: "держать",
      isGenerateRemplate: "Создать шаблон?",
      addSuccessfully: "Добавлено успешно",
      generateNewScheme: "Будет ли создан новый план после включения измененного контента?",
      mailTemplate: "Шаблон электронного письма",
      addTemplate: "Добавить шаблон",
      updateTime: "Время обновления",
      personalTemplate: "Персональный шаблон",
      commonTemplate: "общедоступный шаблон",
    }
  }
