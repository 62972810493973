import language from "@/utils/checkLang"
export function align() {
	return [
		{ value: 1, label: language.formSet.leftJustify },
		{ value: 2, label:  language.formSet.center},
		{ value: 3, label: language.formSet.rightJustify },
	];
}
export function dataType() {
	return [
		{ value: 1, label: 'string' },
		{ value: 2, label: 'number' },
		{ value: 3, label: 'date' },
		{ value: 4, label: 'decimal' },
		{ value: 5, label: 'text' },
		{ value: 6, label: 'switch' },
	];
}
export function filterType() {
	
 
	return [
		{ value: 1, label: language.formSet.equal  },
		{ value: 2, label: language.formSet.less  },
		{ value: 3, label: language.formSet.lessOrequal  },
		{ value: 4, label: language.formSet.greater  },
		{ value: 5, label: language.formSet.greatorequal  },
		{ value: 6, label: language.formSet.like  },
		{ value: 7, label: language.formSet.left  },
		{ value: 8, label: language.formSet.right  },
		{ value: 9, label: language.formSet.between  },
	];
}
export function dataForm() {
	return [
		{ value: 1, label: language.formSet.dataBase },
		{ value: 2, label: language.formSet.express },
	];
}
export function datePrecisionType() {
	return [
		{ value: 1, label: language.formSet.year },
		{ value: 2, label: language.formSet.month },
		{ value: 3, label: language.formSet.day },
		{ value: 4, label: language.formSet.hour },
		{ value: 5, label: language.formSet.minute },
		{ value: 6, label: language.formSet.second },
	];
}
export function formType() {
	
	return [
		{ value: 1, label:language.formSet.input  }, 
		{ value: 2, label:language.formSet.DateRange  },
		{ value: 3, label:language.formSet.dateInput  },
		{ value: 4, label:language.formSet.selectOption  },
		{ value: 5, label:language.formSet.switch  },
		{ value: 6, label:language.formSet.textarea },
		{ value: 7, label:language.formSet.numberInput  },
		{ value: 8, label:language.formSet.richText  },
		{ value: 9, label:language.formSet.checkBox  },
		{ value: 10, label:language.formSet.people  },
		{ value: 11, label:language.formSet.citySelect  },
		{ value: 12, label:language.formSet.selectInput  },
		{ value: 13, label:language.formSet.hyperlink  },
	
	];
}
export function dataScope() {
	return [
		{ value: 1, label:language.formSet.permiAll },    
		{ value: 3, label:language.formSet.department  },
		{ value: 4, label:language.formSet.deptBelow },
		{ value: 5, label:language.formSet.selfOnly },   
	];
}
export function widthList() {
	return [
		{ value: 1, label: language.formSet.stretch },
		{ value: 2, label: language.formSet.tile },
		{ value: 3, label:  language.formSet.half  },
		{ value: 4, label: language.formSet.third },
	];
}
export function pageSizes() {
	return [
		{ value: 20, label: '20' },
		{ value: 50, label: '50' },
		{ value: 100, label: '100' },
		{ value: 200, label: '200' },
		// { value: 300, label: '300' },
		// { value: 400, label: '400' },
	];
}
